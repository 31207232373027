<div class="cardpadding" id="top" fxLayout="column" fxLayoutGap="10px">
    <mat-card fxLayout="column" fxLayoutAlign="start">
        <!--From-->
        <div fxLayout="row" fxLayoutAlign="start start" fxFlex class="without-underline">
            <p fxFlex="8">From:</p>
            <mat-form-field fxFlex="92" class="full-width">

                <mat-chip-list #chipList1 aria-label="Email selection">
                    <mat-chip class="emailchip" *ngFor="let email of emailfrom" [selectable]="selectable"
                        [removable]="removable" (removed)="removefrom(email)">
                        {{email}}
                        <!-- <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon> -->
                    </mat-chip>
                    <!-- <input [matChipInputFor]="chipList1" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addfrom($event)"> -->
                </mat-chip-list>

            </mat-form-field>
        </div>
        <!--To-->
        <div fxLayout="row" fxLayoutAlign="start start"  fxFlex  class="without-underline">
            <p fxFlex="8">To:</p>
            <mat-form-field fxFlex="92" class="full-width">

                <mat-chip-list #chipList2 aria-label="Email selection">
                    <mat-chip class="emailchip" *ngFor="let email of emailto" [selectable]="selectable2"
                        [removable]="removable2" (removed)="removeto(email)">
                        {{email}}
                        <mat-icon matChipRemove *ngIf="removable2">cancel</mat-icon>
                    </mat-chip>
                    <input [matChipInputFor]="chipList2" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur2" (matChipInputTokenEnd)="addto($event)">
                </mat-chip-list>

            </mat-form-field>
        </div>
        <!--CC-->
        <div fxLayout="row" fxLayoutAlign="start start"  fxFlex class="without-underline">
            <p fxFlex="8">CC:</p>
            <mat-form-field fxFlex="92" class="full-width">

                <mat-chip-list #chipList3 aria-label="Email selection">
                    <mat-chip class="emailchip" *ngFor="let email of emailcc" [selectable]="selectable3"
                        [removable]="removable3" (removed)="removecc(email)">
                        {{email}}
                        <mat-icon matChipRemove *ngIf="removable3">cancel</mat-icon>
                    </mat-chip>
                    <input [matChipInputFor]="chipList3" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur3" (matChipInputTokenEnd)="addcc($event)">
                </mat-chip-list>

            </mat-form-field>
        </div>

        <!--BCC-->
        <div fxLayout="row" fxLayoutAlign="start start"  fxFlex  class="without-underline pb-5">
            <p fxFlex="8">BCC:</p>
            <mat-form-field fxFlex="92" class="full-width">
                <mat-chip-list #chipList4 aria-label="Email selection">
                    <mat-chip class="emailchip" *ngFor="let email of emailbcc" [selectable]="selectable4"
                        [removable]="removable4" (removed)="removebcc(email)">
                        {{email}}

                        <mat-icon matChipRemove *ngIf="removable4">cancel</mat-icon>

                    </mat-chip>
                    <input [matChipInputFor]="chipList4" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur4" (matChipInputTokenEnd)="addbcc($event)">
                </mat-chip-list>
            </mat-form-field>
        </div>

        <!--Subject-->
        <div fxLayout="row" fxLayoutAlign="start start"  fxFlex  >
            <p fxFlex="8">Subject:</p>
            <form fxFlex="92" [formGroup]="subjectodmail">
                <mat-form-field appearance="outline" class="full-width1">
                    <textarea matInput [(ngModel)]="this.subject" formControlName="subject"></textarea>
                </mat-form-field>
            </form>
        </div>

        <!--Attachment-->
        <div fxLayout="row" fxLayoutAlign="start start"  fxFlex >
            <p fxFlex="8">Attachment:</p>
            <div fxFlex="92" fxLayout="column" fxLayoutGap="15px">
                <mat-form-field appearance="outline" class="full-width1">
                    <input matInput placeholder="Attach File" [(ngModel)]="uploadFileName" class="pb-5">
                    <button mat-raised-button (click)="fileImportInput.click()" color="primary">
                        Choose File
                    </button>
                    <input class="display-none" #fileImportInput id="file" type="file"
                        (change)="fileChangeEvent($event)">
                </mat-form-field>

                <div *ngIf="this.attachment != null" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
                  
                    <p>Uploaded Attachment:</p>
                    <div *ngIf="this.attachmenttype === '.docx' || this.attachmenttype === 'docx'">
                        <img src="assets/images/download.jpg" alt="" width="30"  (click)="downloadDocument(this.attachmentGetdocx)">
                  
                    </div>
                    <div *ngIf="this.attachmenttype === '.xlsx' || this.attachmenttype === 'xlsx'">
                        <img src="assets/images/excelicon.png" alt="" width="30"  (click)="downloadDocument(this.attachmentGetxlsx)">
                  
                    </div>
                    <div *ngIf="this.attachmenttype === '.pdf' || this.attachmenttype === 'pdf'">
                        <img src="assets/images/Adobe_Reader.png" alt="" width="30"  (click)="downloadDocument(this.attachmentGetpdf)">
                       
                    </div>
                    <!-- <button mat-raised-button color="primary"
                       >Preview</button> -->
                    <!-- {{this.attachmentGet }} -->
                </div>
            </div>
        </div>


    </mat-card>

    <mat-card>
        <div class="cardpadding" fxLayout="column" fxLayoutGap="20px">
            <div class="cardpadding">
                <div [innerHTML]="this.Content"></div>
                    <!-- <p>{{this.ViewEmailData.body}}</p> -->
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button mat-raised-button routerLink="/admin/email/Template">Back</button>
                <button mat-raised-button (click)="send()" color="primary">Send</button>
                <button mat-raised-button routerLink="/admin/email/edit-template">Edit
                    Template</button>
            </div>
        </div>
    </mat-card>
</div>