import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Leads } from 'src/app/shared/models/adminpanel/leads/leads';
import { LeadsService } from 'src/app/shared/services/adminpanel/leads/leads.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { DeleteLeadComponent } from '../../admin/leads/delete-lead/delete-lead.component';
import { SelectionModel } from '@angular/cdk/collections';
import { CONSTANT } from 'src/app/shared/utils/constant';
import { StatusremarksComponent } from './statusremarks/statusremarks.component';
import { EmployeeListService } from 'src/app/shared/services/adminpanel/employee-list.service';
import { Employee } from 'src/app/shared/models/adminpanel/employee';
import { StatusService } from 'src/app/shared/services/status.service';
@Component({
  selector: 'app-employee-leads',
  templateUrl: './employee-leads.component.html',
  styleUrls: ['./employee-leads.component.scss']
})
export class EmployeeLeadsComponent implements OnInit {
  public variables = ['Parth Shah', 'Tushar Panchal', 'Yash Shah', 'Jay Patel', 'Dhruv Boghani', 'pre'];
  // public filteredList1 = this.variables.slice();
  filteredList1
  public variables2 = CONSTANT.status;
  public filteredList2 = this.variables2.slice();
  toppings = new FormControl(this.variables2);
  isLoading;

  displayedColumns: string[] = ['checkbox', 'position', 'name', 'actions', 'company', 'email', 'phone', 'whatsappno', 'leadvalue', 'tags', 'source', 'status'];
  dataSource = new MatTableDataSource<Leads>([]);
  selection = new SelectionModel<Leads>(true, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  color: any;
  Leads: any;
  currentUser: any;
  value
  employee: any;
  filterSelectObj = [];
  filteredListEmployee: any;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private LeadServices: LeadsService,
    private EmployeeListService: EmployeeListService,
    private statusservice: StatusService,
    private matSnack: SnackbarService
  ) { }

  ngOnInit(): void {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.dataSource.paginator = this.paginator;
    this.getLeads();
    this.getEmployee()
    this.getListOfStatus()
    localStorage.removeItem('edit-lead');
    localStorage.removeItem('view-lead');
  }
  getEmployee() {
    this.EmployeeListService.getEmployee()
      .subscribe((data: Employee[]) => {
        this.employee = data;
        // this.variables = this.employee.find(x => x.name)
        this.filteredListEmployee = data
        // console.log("fgfd",this.filteredListEmployee)
        this.filteredList1 = this.filteredListEmployee.slice()
      });
  }
  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }




  viewLead(event) {
    localStorage.setItem('leadData', JSON.stringify(event))

    localStorage.setItem('view-lead', 'View-Lead')
    this.router.navigateByUrl('employee/leads/view-lead', { state: event });

  }

  editLead(event) {
    localStorage.setItem('leadData', JSON.stringify(event))
    localStorage.setItem('edit-lead', 'Edit-Lead')
    this.router.navigateByUrl('employee/leads/view-lead', { state: event });

  }

  //Add lead page route
  addLead() {
    this.router.navigateByUrl('employee/leads/add-lead');
  }

  //Import lead page route
  importLead() {
    this.router.navigateByUrl('employee/leads/import-lead');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.Leads.forEach(row =>
        this.selection.select(row)
      );
  }

  logSelection() {
    this.selection.selected.forEach(s => console.log(s.name));
  }

  someMethod(event) {
    console.log(event)
    const filterValue = event;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  someMethod1(event) {
   

    const filterValue = event.statusName;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    

  }

  /*
    Get Leads list api called
  */
  getLeads() {
    this.isLoading = true;
    this.LeadServices.getEmployeeLeads(`api/lead/LeadList?empId=${this.currentUser.userId}`)
      .subscribe((data: Leads[]) => {
        this.isLoading = false;
        this.Leads = data;
        this.dataSource.data = data
      });
  }

  //Change status of lead
  ChangeStatus(event, status) {
    // this.openremarksDialog(event.value, status.id)
    this.openremarksDialog(event.value, status.leadId)
    // this.LeadServices.ChangeLeadStatus(`leadstatus/${status.id}`, event.value)
    //   .subscribe((res) => {
    //     console.log(res)
    //     this.matSnack.showNotification("Status Updated", "")
    //   });
  }

  /*
    Delete Leads Popup
  */
  deleteLead(element) {
    const dialogRef = this.dialog.open(DeleteLeadComponent, {
      height: 'auto',
      width: '30%',
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getLeads()
      
    });

  }
  openremarksDialog(value,id) {
    
    const dialogRef = this.dialog.open(StatusremarksComponent, {
     height: 'auto',
     width: '30%',
     data: { id: id, value: value }
   });

   dialogRef.afterClosed().subscribe(result => {
     console.log('The dialog was closed');
     this.getLeads()
     this.getLeads()
     this.getLeads()
     
     // this.ChangeStatus(event, status)
   });
 }
 resetFilters() {
   
  this.value=''
  this.dataSource.filter = "";
}
getListOfStatus(){
  this.isLoading = true;
  this.statusservice.getListOfStatus().subscribe((data: []) => {
    if(data){
    
     
      this.filteredList2 = data
   
      this.variables2=data
      // console.log(this.filteredList2.statusName)
     // this.isLoading = false;
    } 
    
  
    });
}
}
