import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Leads } from 'src/app/shared/models/adminpanel/leads/leads';
import { RestmanagerService } from 'src/app/shared/utils/restmanagerService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FacebookCampaignService {

  private restmanagerService: RestmanagerService;
  _apiEndPoint: string = '';

  constructor(private http: HttpClient,
    restmanagerService: RestmanagerService) {
    this.restmanagerService = restmanagerService;
    this._apiEndPoint = environment.Url;
  }
  // 1
  getFaceBookCampaignData<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this.http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }
  AddFaceBookCampaignData(data) {
    return this.http.post<Leads>(`${environment.apiUrl}/api/Admin/AddLeadByJson`, {

      data
    })
      .pipe(map(res => {
        return res;
      }));
  }
  //2
  getCampaignFallingAccountVerification<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this.http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }
  private mapAndCatchError<TData>(response: Observable<TData>): Observable<TData> {

    return response.pipe(
      map((r: TData) => {

        return r;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(null);
      })
    );
  }



  AddCampaignByJson(data) {
    return this.http.post<Leads>(`${environment.apiUrl}/api/Admin/AddCampaignByJson`, {

      data
    })
      .pipe(map(res => {
        return res;
      }));
  }
  //3
  getAdsFallingCampaignVerification<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this.http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }
  AddAdsByJson(data) {
    return this.http.post<Leads>(`${environment.apiUrl}/api/Admin/AddAdsByJson`, {

      data
    })
      .pipe(map(res => {
        return res;
      }));
  }

  UpdateAds(AccountId: string, CampaignId: string, AdId: string, FormID: string) {
    return this.http.post<Leads>(`${environment.apiUrl}/api/admin/UpdateFormId`, {
      AccountId, CampaignId, AdId, FormID

    })
      .pipe(map(res => {
        return res;
      }));
  }


  //4
  AddFormsByJson(data) {
    return this.http.post<Leads>(`${environment.apiUrl}/api/Admin/AddFormsByJson`, {

      data
    })
      .pipe(map(res => {
        return res;
      }));
  }

  getFormInAdsVerification<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this.http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }



  // https://emullbxqz1.execute-api.us-east-2.amazonaws.com/Prod/api/Admin/GetFaceBookCampaignData?token=EAAKv35vADpUBAKvBjEAVKVSdM6smhZAEkOBO0RmMPJcyYhnZAhz7N0sei2v5OZAbKPcZCl4K30tZBTq1eYCPsbaU9oIz6CJjYFFeq8xr0iBZBj6xxHhAR1lkjR36IFiOAOKPJxzohpNLZAyMioEu9vEbrXNeSwwE25yhHxXN9Y8yQozUtIV5eO3nBhvWcZA1jp89PLHflhS2GvFOezgQBZCEwFBqz0eifOmTTbsMzZBoePbmlGzoeHoMic&accountId=165810965427652&campaignId=23849161631470135&adId=23849161631520135&formId=4678439582234381 
}