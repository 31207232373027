<div fxLayout="column" id="top" class="cardpadding">
  <mat-card fxLayout="column" fxLayoutGap="10px">
    <div class="color-primary backgroud-color-white  ">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <p class="mat-title">Products</p>
      </div>
    </div>
    <div>
      <mat-divider>
      </mat-divider>
    </div>
    <!-- Add product button -->
    <div fxLayout="row" fxLayoutAlign="start">
      <button mat-raised-button color="primary" routerLink="/admin/products/add-product">
        Add Product
      </button>
    </div>
    <mat-card *ngIf="isLoading" class="spinner" fxLayoutAlign="space-around center">
      <mat-progress-spinner mode="indeterminate" diameter="50" color="primary" mode="indeterminate">
      </mat-progress-spinner>
    </mat-card>
    <div *ngIf="!isLoading">
      <table mat-table [dataSource]="this.dataSource" class="mat-elevation-z8">
        <!-- {{this.dataSource}} -->
        <!-- Position Column -->
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
        </ng-container>

        <!-- Item Column -->
        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef> Item </th>
          <td mat-cell *matCellDef="let element">
            <div fxLayout="column" fxLayoutGap="5px">

              <p>{{element.item}}</p>
            </div>
          <td>
        </ng-container>

        <!-- actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element" class="action-button">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editproducts(element)">
                Edit
              </button>
              <button mat-menu-item (click)="deleteproducts(element)">
                Delete
              </button>
            </mat-menu>
          <td>
        </ng-container>
        <!-- assigned Column -->
        <ng-container matColumnDef="assigned">
          <th mat-header-cell *matHeaderCellDef> Assigned </th>
          <td mat-cell *matCellDef="let element">

            <mat-form-field appearance="outline" class="form-control-sm">
              <mat-select placeholder="Assigned" (selectionChange)="AssignedEmployee($event, element)"
                [(ngModel)]="element.assignedUserId">
                <mat-select-filter [array]="filteredListEmployee" [displayMember]="'name'"
                  (filteredReturn)="filteredList1 = $event">
                </mat-select-filter>

                <mat-option *ngFor="let item of filteredList1" [value]="item.userId">

                  {{item.firstName}} {{item.lastName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          <td>
        </ng-container>

        <!-- Rate Column -->
        <ng-container matColumnDef="rate">
          <th mat-header-cell *matHeaderCellDef>
            Rate
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.rate}}
          <td>
        </ng-container>

        <!-- Tax Column -->
        <ng-container matColumnDef="tax">
          <th mat-header-cell *matHeaderCellDef> Tax </th>
          <td mat-cell *matCellDef="let element">{{element.tax}}
          <td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 50]" #scheduledOrdersPaginator showFirstLastButtons></mat-paginator>

      <!-- <mat-card *ngIf="isLoading" class="spinner" fxLayoutAlign="space-around center" >
      <mat-progress-spinner 
        color="primary" 
        mode="indeterminate"
        diameter="50"
        >
      </mat-progress-spinner>
    </mat-card> -->
    </div>
  </mat-card>
</div>