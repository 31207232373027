<div fxLayout="column" id="top" class="cardpadding">
  <mat-card fxLayout="column" fxLayoutGap="10px">
    <div class="color-primary backgroud-color-white ">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <p class="mat-title">New lead</p>
        <button mat-raised-button color="primary" routerLink="/admin/Campaign">
          <mat-icon class="cursor-pointer">arrow_back</mat-icon> Back
        </button>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
          <form [formGroup]="myForm">
            <div fxLayout="column" fxLayoutGap="0px">
              <div fxFlex="32">
                <mat-form-field appearance="outline" class="full-width form-field-font">
                  <mat-label>Assigned</mat-label>
                  <mat-select placeholder="Assigned" (selectionChange)="AssignedEmployee($event)"
                    formControlName="assigned">
                    <mat-select-filter [array]="filteredListEmployee" [displayMember]="'name'"
                      (filteredReturn)="filteredList1 = $event">
                    </mat-select-filter>
                    <mat-option *ngFor="let item of filteredList1" [value]="item.userId">
                      {{ item.firstName }}
                      {{ item.lastName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <button mat-raised-button color="primary" (click)="submit()" [disabled]="this.myForm.invalid">
                Import
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Facebook Lead Table -->
    <div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
        </ng-container>

        <!-- "full_name" Column -->
        <ng-container matColumnDef="full_name">
          <th mat-header-cell *matHeaderCellDef>Full Name</th>
          <td mat-cell *matCellDef="let element">
            <p class="half-width" *ngFor="let subData of element.field_data">
              <span *ngIf="subData.name == 'full_name'">
                {{ subData.values }}
              </span>
            </p>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element">
            <p class="border" *ngFor="let subData of element.field_data">
              <span *ngIf="subData.name == 'email'">
                {{ subData.values }}
              </span>
            </p>
          </td>
        </ng-container>

        <!-- "phone_number" Column -->
        <ng-container matColumnDef="phone_number">
          <th mat-header-cell *matHeaderCellDef>Phone Number</th>
          <td mat-cell *matCellDef="let element">
            <p class="border" *ngFor="let subData of element.field_data">
              <span *ngIf="subData.name == 'phone_number'">
                {{ subData.values }}
              </span>
            </p>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 50]" #FacebookLeadTable showFirstLastButtons></mat-paginator>
    </div>
  </mat-card>
</div>