import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { StatusService } from 'src/app/shared/services/status.service';

@Component({
  selector: 'app-add-status',
  templateUrl: './add-status.component.html',
  styleUrls: ['./add-status.component.scss']
})
export class AddStatusComponent implements OnInit {

  local_data
  commentFC = new FormControl('', [
    Validators.required,
    Validators.maxLength(30)
  ]);
  constructor(
    public matSnack: SnackbarService,
    private statusService: StatusService,
    public dialogRef: MatDialogRef<AddStatusComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: AddStatusComponent
  ) {
    this.local_data = { ...data };
  }
  // Close dialog
  ngOnInit(): void {
    console.log(this.local_data)
  }
  onCommentChange() {
    console.log(this.commentFC.invalid);
  }
  get alf() { return this.commentFC.value; }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  AddStatus() {
    this.statusService.AddleadStatus(this.alf).subscribe((data: any[]) => {
      this.dialogRef.close({ data: data });
      this.matSnack.showNotification("Status Added", "")
    });
  }
}
