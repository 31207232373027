import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { DeleteProductComponent } from './delete-product/delete-product.component';
import { Products } from 'src/app/shared/models/adminpanel/products';
import { ProductsService } from 'src/app/shared/services/adminpanel/products.service';
import { EmployeeListService } from 'src/app/shared/services/adminpanel/employee-list.service';
import { Employee } from 'src/app/shared/models/adminpanel/employee';
import { LeadsService } from 'src/app/shared/services/adminpanel/leads/leads.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  isLoading;
  displayedColumns: string[] = ['number', 'item', 'actions', 'assigned', 'rate', 'tax'];
  dataSource = new MatTableDataSource<Products>([]);
  @ViewChild('scheduledOrdersPaginator') set paginator(pager: MatPaginator) {
    if (pager) this.dataSource.paginator = pager;
  }

  color: any;
  products: any;
  employee: any;
  filterSelectObj = [];
  filteredListEmployee: any;
  filteredList1;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private ProductService: ProductsService,
    private EmployeeListService: EmployeeListService,
    private matSnack: SnackbarService
  ) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.getProducts();
    this.getEmployee();
    localStorage.removeItem('edit-product');
    localStorage.removeItem('productData')
  }

  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  //Edit product page route
  editproducts(event) {
    localStorage.setItem('productData', JSON.stringify(event))
    localStorage.setItem('edit-product', 'Edit-product')
    this.router.navigateByUrl('admin/products/add-product', { state: event });

  }

  //Add product page route
  addproducts() {
    this.router.navigateByUrl('admin/products/add-product');
  }

  /*
    Get products list api called
  */
  getProducts() {
    this.isLoading = true;
    this.ProductService.getProducts()
      .subscribe((data: Products[]) => {
        this.isLoading = false;
        this.products = data;
        this.dataSource.data = data
      });
  }
  getEmployee() {
    this.EmployeeListService.getEmployee()
      .subscribe((data: Employee[]) => {
        this.employee = data;
        // this.variables = this.employee.find(x => x.name)
        this.filteredListEmployee = data
        this.filteredList1 = this.filteredListEmployee.slice()
      });
  }
  /*
    Delete product Popup
  */
  deleteproducts(element) {
    const dialogRef = this.dialog.open(DeleteProductComponent, {
      height: 'auto',
      width: '30%',
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getProducts()
      this.getProducts()
    });

  }
  //Assign Employee of lead
  AssignedEmployee(event, status) {
    console.log(event)
    console.log("status", status)
    this.ProductService.AssignedProduct(`api/lead/AssignProduct`, event.value, status.id)
      .subscribe((res) => {
        // console.log(event.value)
        // console.log(res)
        this.matSnack.showNotification("Assign Product Updated", "")
        this.getProducts()
      });
  }

}