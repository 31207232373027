<div id="top" class="cardpadding">
  <mat-card>
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutGap="15px">
        <!-- new lead and import lead button -->
        <button mat-raised-button color="primary" (click)="addLead()">New Leads</button>
        <button mat-raised-button color="primary" (click)="importLead()">Import Leads</button>
        <button mat-raised-button color="primary" (click)="importLeadAssigned()">Import Leads with Assigned</button>
      </div>
      <div>
        <mat-divider></mat-divider>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row">
          <p class="mat-h4">Filter By:</p>
        </div>
        <div class="leads-data" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between"
          fxLayoutAlign.lt-sm="start center" fxFlex>

          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
            <!-- <div>
              <mat-form-field appearance="outline" class="form-field-font">
                <mat-label>Assigned</mat-label>
                <mat-select placeholder="Assigned " (selectionChange)="someMethod($event.value)">
                  <mat-select-filter [array]="filteredListEmployee" (filteredReturn)="filteredListEmployee =$event">
                  </mat-select-filter>
                  <mat-option *ngFor="let item of filteredListEmployee" [value]="item.name">
                    {{item.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->

            <div>
              <mat-form-field appearance="outline" class="form-field-font">
                <mat-label>Status</mat-label>
                <mat-select [formControl]="toppings" (selectionChange)="someMethod1($event.value)">
                  <!-- {{this.filteredList2 |json}} -->
                  <mat-option *ngFor="let topping of this.filteredList2" [value]="topping">{{topping.statusName}}
                  </mat-option>
                </mat-select>
                <!-- <button mat-button *ngIf="toppings" matSuffix mat-icon-button aria-label="Clear"
                  (click)="resetFilters()">
                  <mat-icon>close</mat-icon>
                </button> -->
              </mat-form-field>
            </div>
          </div>
          <div align="end">
            <mat-form-field appearance="outline" class="form-field-font">
              <mat-label>Search</mat-label>
              <input matInput [(ngModel)]="value" (keyup)="applyFilter($event)">

              <mat-icon matPrefix>search</mat-icon>
              <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="resetFilters()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div>
        <mat-divider></mat-divider>
      </div>
      <div>
        <mat-divider></mat-divider>
      </div>

      <mat-card *ngIf="isLoading" class="spinner" fxLayoutAlign="space-around center">
        <mat-progress-spinner mode="indeterminate" diameter="50" color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </mat-card>

      <div *ngIf="!isLoading" class="lead-list">
        <table mat-table [dataSource]="this.dataSource" [trackBy]="trackBy" class="mat-elevation-z8 leads-data">
          <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox color="primary" class="example-margin" (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" fxHide></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)">
              </mat-checkbox>
            <td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> #</th>
            <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element">
              <p *ngIf="element.name == null ">-</p>
              <p *ngIf="element.name != '' ">{{element.name}}</p>
            <td>
          </ng-container>

          <!-- actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element" class="action-button">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="viewLead(element)">
                  View
                </button>
                <button mat-menu-item (click)="editLead(element)">
                  Edit
                </button>
                <button mat-menu-item (click)="deleteLead(element)">
                  Delete
                </button>
              </mat-menu>
            <td>
          </ng-container>
          <!-- company Column -->
          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef> Company </th>
            <td mat-cell *matCellDef="let element">
              <p *ngIf="element.CompanyName != 'null'">{{element.companyName}}</p>
              <p *ngIf="element.CompanyName == 'null' ">-</p>
            </td>
          </ng-container>

          <!-- email Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <a class="fa fa-envelope e-color email e-color-hover " href="mailto: {{element.email}}"
                target="_blank"></a>
            <td>
          </ng-container>

          <!-- phone Column -->
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef> Phone </th>
            <td mat-cell *matCellDef="let element">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <a href="tel:{{element.phone}}" class="phone l-color"><i class="fa fa-phone"></i></a>
                <a class="color-primary" href="tel:{{element.phone}}"></a>
                <!-- {{element.phone}} -->
              </div>
            <td>
          </ng-container>

          <!-- whatsappno Column -->
          <ng-container matColumnDef="whatsappno">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <a href="//api.whatsapp.com/send?phone=91{{element.whatsappnumber}}" class="whatsapp" id="whatsapp-btn"
                target="_blank"><i class="fa fa-whatsapp"></i></a>
            <td>
              <!-- api.whatsapp.com/send?phone=91{{element.whatsappnumber}}&text={{element | json}} -->
          </ng-container>

          <!-- lead value Column -->
          <ng-container matColumnDef="leadvalue">
            <th mat-header-cell *matHeaderCellDef> Value </th>
            <td mat-cell *matCellDef="let element"> {{element.leadValue}}
            <td>
          </ng-container>

          <!-- tags Column -->
          <ng-container matColumnDef="tags">
            <th mat-header-cell *matHeaderCellDef> Tags </th>
            <td mat-cell *matCellDef="let element">
              <!-- <p *ngIf="element.sources != 'null'">{{element.sources}}</p> -->
              <!-- <p *ngIf="element.tags === null">-</p> -->
              <mat-chip-list *ngIf="element.tags != null">
                <span *ngFor="let symbols of element.tags.split(',')">
                  <mat-chip class=" mat-chips">{{symbols}} </mat-chip>
                </span>
                <div>
                  <!-- {{(readMore) ? symbols : symbols | slice:0:50}} <span *ngIf="!readMore">...</span>
                  <a href="javascript:;" *ngIf="!readMore" (click)="readMore=true">[Read More]</a> -->
                </div>
              </mat-chip-list>
              <!-- {{element.tags}} -->
              <p *ngIf="element.tags == null">-</p>

              <!-- .split(',') -->
              <!-- {{element.tags}} -->

            <td>
          </ng-container>

          <!-- source Column -->
          <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef> Source</th>
            <td mat-cell *matCellDef="let element">
              <p *ngIf="element.sources != 'null'">{{element.sources}}</p>
              <p *ngIf="element.sources === null">-</p>
            <td>
          </ng-container>

          <!-- assigned Column -->
          <ng-container matColumnDef="assigned">
            <th mat-header-cell *matHeaderCellDef> Assigned </th>
            <td mat-cell *matCellDef="let element">

              <mat-form-field appearance="outline" class="form-control-sm">
                <mat-select placeholder="Assigned" (selectionChange)="AssignedEmployee($event, element)"
                  [(ngModel)]="element.assigneuserid">
                  <mat-select-filter [array]="filteredListEmployee" [displayMember]="'name'"
                    (filteredReturn)="filteredList1 = $event">
                  </mat-select-filter>

                  <mat-option *ngFor="let item of filteredList1" [value]="item.userId">
                    {{item.firstName}} {{item.lastName}}

                  </mat-option>
                </mat-select>
              </mat-form-field>
            <td>
          </ng-container>

          <!-- status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">

              <mat-form-field appearance="outline" class="form-control-sm">
                <mat-select placeholder="Status" [(ngModel)]="element.status"
                  (selectionChange)="ChangeStatus($event, element)" name="currentStatus">
                  <mat-option *ngFor="let s of variables2" [value]="s.statusName">
                    {{s.statusName}}

                  </mat-option>
                </mat-select>
              </mat-form-field>
            <td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (mouseover)="handleMouseOver(row)"
            (mouseleave)="handleMouseLeave(row)"></tr>
        </table>
        <mat-paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" #paginator showFirstLastButtons>
        </mat-paginator>

      </div>
    </div>
  </mat-card>



</div>