import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { BlankLayoutComponent } from 'src/app/layout/blank-layout/blank-layout.component';
import { RouterModule } from '@angular/router';
import { LayoutsModule } from 'src/app/layout/layout.module';
import { WebsiteHeaderComponent } from 'src/app/layout/website-header/website-header.component';


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(
    [
      {
        path: '',
        component: WebsiteHeaderComponent,
        children: [
          { path: 'home', component: HomeComponent, pathMatch: 'full'},
        ],
    },
    ]),
    LayoutsModule
  ],
  exports: [RouterModule],
})
export class WebsiteRoutingModule { }