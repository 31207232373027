<div id="top" class="cardpadding">
    <mat-card fxLayout="column" fxLayoutGap="10px">
        <div class="color-primary backgroud-color-white  ">
            <div fxLayout="row" fxLayoutAlign="space-between">

                <p class="mat-title">Quotation
                </p>
            </div>
        </div>
        <div>
            <mat-divider> </mat-divider>
        </div>
        <div class="">
            <div fxLayout="column" fxLayoutGap="10px">
                <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
                    <!-- new lead and import Quotation button -->
                    <button mat-raised-button color="primary" (click)="addQuotation()">New Quotation</button>

                    <!-- Search -->
                    <div>
                        <mat-form-field appearance="outline" class="searchText form-field-font">
                            <mat-label>Search</mat-label>
                            <input matInput [(ngModel)]="value" (keyup)="applyFilter($event)">
                            <!-- <span matPrefix>&nbsp;</span> -->
                            <mat-icon matPrefix>search</mat-icon>
                            <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="resetFilters()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>

                <mat-card *ngIf="isLoading" class="spinner" fxLayoutAlign="space-around center">
                    <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
                    </mat-progress-spinner>

                </mat-card>
                <div *ngIf="!isLoading" class="mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource_new">

                        <!-- Proposal Column -->
                        <ng-container matColumnDef="proposal">
                            <th mat-header-cell *matHeaderCellDef> # </th>
                            <td mat-cell *matCellDef="let element">QUA- {{element.quotationId}} </td>
                        </ng-container>

                        <!-- actions Column -->
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element" class="action-button">
                                <button mat-icon-button [matMenuTriggerFor]="menu"
                                    aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="viewQuotation(element)">
                                        View
                                    </button>
                                    <button mat-menu-item (click)="EditQuotation(element)">
                                        Edit
                                    </button>
                                    <button mat-menu-item (click)="DeleteQuotation(element)">
                                        Delete
                                    </button>
                                </mat-menu>
                            <td>
                        </ng-container>

                        <!-- Subject Column -->
                        <ng-container matColumnDef="subject">
                            <th mat-header-cell *matHeaderCellDef> Subject </th>
                            <td mat-cell *matCellDef="let element"> {{element.subject}} </td>
                        </ng-container>

                        <!-- To Column -->
                        <ng-container matColumnDef="to">
                            <th mat-header-cell *matHeaderCellDef> To </th>
                            <td mat-cell *matCellDef="let element"> {{element.toPerson}} </td>
                        </ng-container>

                        <!-- Total Column -->
                        <ng-container matColumnDef="total">
                            <th mat-header-cell *matHeaderCellDef> Total </th>
                            <td mat-cell *matCellDef="let element"> {{element.total | currency:'INR'}} </td>
                        </ng-container>

                        <!-- Date Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef> Date </th>
                            <!-- | date:'dd-MM-yyyy' -->
                            <td mat-cell *matCellDef="let element"> {{element.date |date:'dd-MM-yyyy'}} </td>
                        </ng-container>

                        <!-- Open Till Column -->
                        <ng-container matColumnDef="opentill">
                            <th mat-header-cell *matHeaderCellDef> Open Till </th>
                            <td mat-cell *matCellDef="let element"> {{element.openTill|date:'dd-MM-yyyy'}} </td>
                        </ng-container>

                        <!-- Date Created Column -->
                        <ng-container matColumnDef="datecreated">
                            <th mat-header-cell *matHeaderCellDef> Date Created </th>
                            <td mat-cell *matCellDef="let element"> {{element.createdAt| date:'dd-MM-yyyy'}} </td>
                        </ng-container>

                        <!-- Status Column -->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element">
                                <p *ngIf="element.Status==='Declined'" class="color-danger">{{element.status}}</p>
                                <p *ngIf="element.Status!='Declined'">{{element.status}}</p>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>




                    <mat-paginator [pageSizeOptions]="[10, 25, 50]" #scheduledOrdersPaginator showFirstLastButtons>
                    </mat-paginator>
                </div>

            </div>

        </div>
    </mat-card>
</div>