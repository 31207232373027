<div fxLayout="column" id="top" class="cardpadding">
  <mat-card fxLayout="column" fxLayoutGap="10px">
    <div class="color-primary backgroud-color-white  ">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <p class="mat-title">Import new lead
        </p>
        <button mat-raised-button color="primary" routerLink="/employee/leads">
          <mat-icon class="cursor-pointer">arrow_back</mat-icon> Back
        </button>
      </div>
    </div>

    <div fxLayout="column" fxLayoutGap="20px">
      <div>
        <button mat-raised-button color="primary" (click)="export()">Sample File Export</button>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
          <form [formGroup]="myForm">
            <div fxLayout="column" fxLayoutGap="20px">
              <div fxLayout="column" fxLayoutGap="20px">
                <p class="mat-h3 color-primary">Choose CSV/ Excel File:</p>
                <button mat-raised-button (click)="fileImportInput.click()" color="primary"> Choose CSV /
                  Excel File
                </button>
                <input class="display-none" formControlName="file" #fileImportInput id="file" type="file"
                  (change)="onFileChange1($event)"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
              </div>
              <button mat-raised-button color="primary" (click)="submit()"
                [disabled]="!this.csvFileData">Import</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </mat-card>
</div>