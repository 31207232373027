import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RestmanagerService } from '../utils/restmanagerService';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  _apiEndPoint: string = '';
  constructor(private http: HttpClient,
    restmanagerService: RestmanagerService) { 
      this._apiEndPoint = environment.Url;
    }

  
  //Get Employee leads-list api
  getNotification(apiURL) {
    return this.http.get<any[]>(`${environment.apiUrl}/${apiURL}`);
  }

  notificationRead(NotificationId: string){
    return this.http.post<any>(`${environment.apiUrl}/api/Notificaion/ReadNotification`, { NotificationId })
      .pipe(map(user => {

        return user;
      }));
  }
 
}
