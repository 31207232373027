import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { CONSTANT } from 'src/app/shared/utils/constant';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  hide = true;
  hidecon = true;
  forgotFormGroup: FormGroup;
  txtEmail: any;
  error: string;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    public snackbar: SnackbarService
  ) { }

  ngOnInit(): void {
    this.forgotFormGroup = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(CONSTANT.emailPattern)

      ])
    });
    this.txtEmail = this.forgotFormGroup.get('email');
  }

  //forgotpasswordform control
  get fpf() { return this.forgotFormGroup.controls; }

  //Send email id
  send() {
    if (this.forgotFormGroup.invalid) {

      return;
    }

    this.authenticationService.ForgotPassword(this.fpf.email.value)

      .pipe(first())
      .subscribe(
        data => {
         
          if (data.message=="This user is not registered.") {
            this.error = data.message;
          }
          // if (data.type === 'error') {
           
          // }
           else if (data.type === 'success') {
            localStorage.setItem('email-change', this.fpf.email.value)
            localStorage.setItem('email-change-token', data.token)
            this.router.navigate(['pages/mail-send']);
          }
        });
  }
}
