import { Component, OnInit } from '@angular/core'; import { FormControl, FormGroup, Validators } from '@angular/forms'; import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { DeleteQuotationComponent } from '../delete-quotation/delete-quotation.component';
import { MatDialog } from '@angular/material/dialog';
import { EditQuotationComponent } from '../edit-quotation/edit-quotation.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ProductsService } from 'src/app/shared/services/adminpanel/products.service';
import { Products } from 'src/app/shared/models/adminpanel/products';
import { AddQuotationService } from 'src/app/shared/services/adminpanel/add-quotation.service';
import { Quotation } from 'src/app/shared/models/adminpanel/quotation';
import { DeleteQuotationItemComponent } from '../delete-quotation-item/delete-quotation-item.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { QuotationItem } from 'src/app/shared/models/adminpanel/quotation_item';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { Router } from '@angular/router';
import * as jspdf from 'jspdf';

// import { jsPDF } from 'jspdf'

import * as _html2canvas from "html2canvas";

const html2canvas: any = _html2canvas;

interface Related {
  value: string;
  viewValue: string;
}
interface Status {
  value: string;
  viewValue: string;
}
interface DiscountType {
  value: string;
  viewValue: string;
}
interface Assigned {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-update-quotation',
  templateUrl: './update-quotation.component.html',
  styleUrls: ['./update-quotation.component.scss']
})
export class UpdateQuotationComponent implements OnInit {
  isLoading;
  showDiscount: boolean = false;
  discountNew: boolean = false;
  showSpinner;
  AddQuotationForm: FormGroup;
  AddQuotationDataForm: FormGroup;
  edit_quotation;
  add_quotation;
  selectable = true;
  removable = true;
  isChecked = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredtags: Observable<string[]>;
  tags: string[] = [];
  alltags: string[] = ['1BHK', 'Cosmetic', 'Gujarat', 'SEO', 'tag1'];
  selectQuantity: string;
  quantity: string[] = ['Qty', 'Hours', 'Qty/Hours'];
  displayedColumns: string[] = ['items', 'description', 'qty', 'rate', 'tax', 'amount', 'actions'];
  dataSource_new = new MatTableDataSource<quotation>([]);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  qty: any;
  rate: any;
  amount: any;
  arrayofData = []
  taxAmountCGST = []
  taxAmountSGST = []
  taxAmounteighteenIGST = [];
  taxAmounteighteenGST = [];
  taxCount2: any;
  related: Related[] = [
    { value: 'Lead', viewValue: 'Lead' },
    { value: 'Customer', viewValue: 'Customer' },
  ];
  discountTypes: DiscountType[] = [
    { value: 'No Discount', viewValue: 'No Discount' },
    { value: 'Before Tax', viewValue: 'Before Tax' },
    { value: 'After Tax', viewValue: 'After Tax' },
  ];
  status: Status[] = [
    { value: 'Draft', viewValue: 'Draft' },
    { value: 'Sent', viewValue: 'Sent' },
    { value: 'Open', viewValue: 'Open' },
    { value: 'Revised', viewValue: 'Revised' },
    { value: 'Declined', viewValue: 'Declined' },
    { value: 'Accepted', viewValue: 'Accepted' },
  ];
  tax: Status[] = [
    { value: '9.00% CGST', viewValue: '9.00% CGST' },
    { value: '9.00% SGST', viewValue: '9.00% SGST' },
    { value: '18.00% GST', viewValue: '18.00% GST' },
    { value: '18.00% IGST', viewValue: '18.00% IGST' },
  ];

  assigned: Assigned[] = [
    { value: 'test user', viewValue: 'test user' },
    { value: 'SOHIL @LIME', viewValue: 'SOHIL @LIME' },
    { value: 'Rahul Maharana', viewValue: 'Rahul Maharana' },
    { value: 'Nikita R', viewValue: 'Nikita R' },
    { value: 'Nidhi M', viewValue: 'Nidhi M' },
    { value: 'Jane Doe', viewValue: 'Jane Doe ' },
  ];
  dataSourcenew: any = [];
  dataSource2 = new MatTableDataSource<any>([]);
  savdata: any;
  discountAmount: any;
  adjustmentAmount: number;
  total: any = 0.00;
  subtotal: number;
  discount: string;
  adjustment: string;
  products: any;
  filteredListEmployee: any;
  filteredList1: any;
  ItemsData: any;
  rateData: any;
  taxData: any;
  itemData: any;
  quotationDataById;
  quotationSubject: string;
  quotationRelated: any;
  quotationDate: any;
  quotationOpenTill: any;
  quotationDiscount: any;
  quotationTags: any;
  quotationStatus: any;
  quotationAssigned: any;
  quotationTo: string;
  quotationAddress: string;
  quotationCity: string;
  quotationState: string;
  quotationCountry: string;
  quotationZipCode: string;
  quotationEmail: string;
  quotationPhone: string;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  ninepercentCGST: any;
  eighteenpercentCGST: number;
  totalTaxAmountCGST: number;
  totalTaxAmountSGST: number;
  totalTaxAmounteighteenIGST: number;
  totalTaxAmounteighteenGST: number;
  ninepercentSGST: number;
  eighteenpercentGST: number;
  eighteenpercentIGST: number;
  allTaxTotal: number;
  assignedChangeName: any;
  relatedChangeName: any;
  discountChange: any;
  statusValueChange: any;
  quotationId: string;
  textSelectedValued: any;
  itemsSelectedValued: any;
  amountItem: any;
  employee: Quotation[];
  amountCountData: any;
  adjustmentExist: any;
  discountExist: any;
  discountAmountExist: any;
  adjustmentAmountExist: any;


  constructor(public dialog: MatDialog,
    private ProductService: ProductsService,
    private addQuotationService: AddQuotationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private matSnack: SnackbarService,
  ) {
    this.filteredtags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => (tag ? this._filter(tag) : this.alltags.slice())),
    );

    this.amount = this.qty * this.rate
  }

  ngOnInit(): void {
    this.AddQuotationForm = new FormGroup({
      subject: new FormControl(''),
      related: new FormControl(''),
      date: new FormControl('', Validators.required),
      openTill: new FormControl('', Validators.required),
      comment: new FormControl(''),
      tagCtrl: new FormControl(''),
      statusForm: new FormControl(''),
      assigned: new FormControl('', Validators.required),
      discountType: new FormControl('', Validators.required),
      to: new FormControl(''),
      address: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl(''),
      zipCode: new FormControl(''),
      email: new FormControl(''),
      phone: new FormControl(''),
    });

    this.AddQuotationDataForm = new FormGroup({
      Items: new FormControl(''),
      Description: new FormControl(''),
      Qty: new FormControl(''),
      Rate: new FormControl(''),
      Tax: new FormControl(''),
      discountForm: new FormControl(''),
      adjustmentForm: new FormControl(''),
    })
    this.getProducts()
    var quotationId = localStorage.getItem('quotationId');
    this.add_quotation = localStorage.getItem('add-quotation');
    this.edit_quotation = localStorage.getItem('edit-quotation');
    // //console.log(quotationId,"quotationId =")

    this.getQuotationItem();
    this.getQuotationData();

  }

  // AddQuotationForm controls
  get aqf() { return this.AddQuotationForm.controls; }
  // AddQuotationDataForm controls
  get aqdf() { return this.AddQuotationDataForm.controls; }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }

    // Clear the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.alltags.filter(tag => tag.toLowerCase().includes(filterValue));
  }

  // Delete
  openDialog_delete(element) {
    // localStorage.setItem('edit-quotation', 'Edit-quotation')
    const dialogRef = this.dialog.open(DeleteQuotationItemComponent, {
      height: 'auto',
      width: '30%',
      data: element,
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.deleteRowData(result.data)
      this.dataSource2.data.splice(result, 1);
      if (this.dataSource2.data != null) {
        this.getQuotationItem();
        this.getQuotationItem();
      }
    });
  }

  openDialog_edit(element) {
    // //console.log(element)
    const dialogRef = this.dialog.open(EditQuotationComponent, {
      height: 'auto',
      width: 'auto',
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.updateRowData(result.data);

      // window.location.reload();
      this.getQuotationItem();
      this.getQuotationItem();
    });
  }

  //join symptomps function
  joinsymptomps(arrayValue) {
    var StringValue = arrayValue
    return Array.prototype.map.call(StringValue, function (item) {
      return item;
    }).join(", ");
  }

  taxCount(tax, amount) {
    //console.log(tax,"taxcount")
    //console.log(amount," amount for tax")
    for (let index = 0; index < tax.length; index++) {
      const element = tax[index];
      var subElement = element.split(",")
      //console.log(subElement,"subElement")
      if (subElement.length > 0) {
        for (let index2 = 0; index2 < subElement.length; index2++) {
          var newElement = subElement[index2]
          //console.log(newElement,"loop number")
          if (newElement === '9.00% CGST') {
            var taxamount = 9
            //console.log((parseInt(amount) * taxamount) / 100)
            this.ninepercentCGST = (parseInt(amount) * taxamount) / 100
            this.taxAmountCGST.push(this.ninepercentCGST)
          }
          if (newElement === '9.00% SGST') {
            var taxamount = 9
            //console.log((parseInt(amount) * taxamount) / 100)
            this.ninepercentSGST = (parseInt(amount) * taxamount) / 100
            this.taxAmountSGST.push(this.ninepercentSGST)
          }
          if (newElement === '18.00% GST') {
            var taxamount = 18
            //console.log((parseInt(amount) * taxamount) / 100)
            this.eighteenpercentGST = (parseInt(amount) * taxamount) / 100
            this.taxAmounteighteenGST.push(this.eighteenpercentGST)
          }
          if (newElement === '18.00% IGST') {
            var taxamount = 18
            //console.log((parseInt(amount) * taxamount) / 100)
            this.eighteenpercentIGST = (parseInt(amount) * taxamount) / 100
            this.taxAmounteighteenIGST.push(this.eighteenpercentIGST)
          }
        }

      }
      if (element === '9.00% CGST') {
        var taxamount = 9
        //console.log((parseInt(amount) * taxamount) / 100)
        this.ninepercentCGST = (parseInt(amount) * taxamount) / 100
        this.taxAmountCGST.push(this.ninepercentCGST)
      }
      if (element === '9.00% SGST') {
        var taxamount = 9
        //console.log((parseInt(amount) * taxamount) / 100)
        this.ninepercentSGST = (parseInt(amount) * taxamount) / 100
        this.taxAmountSGST.push(this.ninepercentSGST)
      }
      if (element === '18.00% GST') {
        var taxamount = 18
        //console.log((parseInt(amount) * taxamount) / 100)
        this.eighteenpercentGST = (parseInt(amount) * taxamount) / 100
        this.taxAmounteighteenGST.push(this.eighteenpercentGST)
      }
      if (element === '18.00% IGST') {
        var taxamount = 18
        //console.log((parseInt(amount) * taxamount) / 100)
        this.eighteenpercentIGST = (parseInt(amount) * taxamount) / 100
        this.taxAmounteighteenIGST.push(this.eighteenpercentIGST)
      }
    }
    //console.log(this.taxAmountCGST.reduce((a, b) => a + b, 0));
    //console.log(this.taxAmountSGST.reduce((a, b) => a + b, 0));
    //console.log(this.taxAmounteighteenIGST.reduce((a, b) => a + b, 0));
    //console.log(this.taxAmounteighteenGST.reduce((a, b) => a + b, 0));
    this.totalTaxAmountCGST = this.taxAmountCGST.reduce((a, b) => a + b, 0);
    this.totalTaxAmountSGST = this.taxAmountSGST.reduce((a, b) => a + b, 0);
    this.totalTaxAmounteighteenIGST = this.taxAmounteighteenIGST.reduce((a, b) => a + b, 0);
    this.totalTaxAmounteighteenGST = this.taxAmounteighteenGST.reduce((a, b) => a + b, 0);
    this.allTaxTotal = this.totalTaxAmountCGST + this.totalTaxAmountSGST + this.totalTaxAmounteighteenIGST + this.totalTaxAmounteighteenGST
    // console.log(this.allTaxTotal,"all tax total")
  }

  addQuotationData() {
    // var id = new Date();
    this.dataSourcenew.push({
      Items: this.itemsSelectedValued,
      Description: this.aqdf.Description.value,
      Qty: this.aqdf.Qty.value,
      Rate: this.aqdf.Rate.value,
      Tax: this.textSelectedValued,
      amount: this.aqdf.Rate.value * this.aqdf.Qty.value,
      taxCount2: this.taxCount(this.aqdf.Tax.value, this.aqdf.Rate.value * this.aqdf.Qty.value)
    });
    var quotationData = localStorage.getItem('quotationData')
    this.addQuotationService.AddQuotationItem(
      this.itemsSelectedValued,
      this.aqdf.Description.value,
      this.aqdf.Qty.value,
      this.aqdf.Rate.value,
      this.joinsymptomps(this.aqdf.Tax.value),
      quotationData)

      .pipe(first())
      .subscribe(
        data => {
          this.amountCountData = data
          this.amountItem = this.aqdf.Rate.value * this.aqdf.Qty.value,
            this.taxCount2 = this.taxCount(this.aqdf.Tax.value, this.aqdf.Rate.value * this.aqdf.Qty.value)
          //console.log(data,"add data")
          setTimeout(() => {
            this.getQuotationItem()
          }, 1000);
        },
        error => {
          //console.log(error.error)
        });
    this.AddQuotationDataForm.reset()
    this.getQuotationItem()
  }

  // Dropdown menus get option
  Assigned(event) {
    this.assignedChangeName = event.source.triggerValue;
  }
  Related(event) {
    this.relatedChangeName = event.source.triggerValue;
    // //console.log(this.relatedChangeName,"change name")

  }
  discountType(event) {
    this.discountChange = event.source.triggerValue;
  }
  statusValue(event) {
    this.statusValueChange = event.source.triggerValue;
  }
  textSelected(event) {
    this.textSelectedValued = event.source.triggerValue;
  }

  ItemsSelected(event) {
    this.itemsSelectedValued = event.source.triggerValue;
  }


  getQuotationItem() {
    this.isLoading = true;
    var quotationData = localStorage.getItem('quotationData')
    this.spinner.show();
    this.addQuotationService.getQuotationItem<QuotationItem>(`api/Quotation/Items?quotationid=${quotationData}`)
      .subscribe((data: QuotationItem[]) => {
        setTimeout(() => {
          this.isLoading = false;
          this.dataSource2.data = data
          this.getQuotationData()

          this.subtotal = this.dataSource2.data.map(x => parseInt(x.rate) * parseInt(x.qty)).reduce((a, b) => (a + b), 0)
          this.discountCount((this.discountAmountExist), this.subtotal)
          var amoutNew = this.dataSource2.data.map(x => parseInt(x.rate) * parseInt(x.qty))
          var discountTypenew = this.dataSource2.data.map(x => x.tax)
          this.taxCount(discountTypenew, amoutNew)
          //  //console.log(discountTypenew,"discount from get API")
          //  //console.log(this.discountType,"discountType get API")
          this.totalAmount(this.dataSource2)
        }, 1000);
        this.spinner.hide();

        // //console.log(this.dataSource2.data ,"data table")
      });
  }


  getTotalCost() {
    return this.dataSource2.data.map(t => t.rate).reduce((acc, value) => acc + value, 0);
  }
  totalAmount(data) {
    //console.log(data,"total AMount")
    this.subtotal = this.dataSource2.data.map(x => parseInt(x.rate) * parseInt(x.qty)).reduce((a, b) => (a + b), 0)
    // this.subtotal = this.dataSource2.data.map(function (a) {
    //   //console.log(a,"a data")
    //    return a["Rate"]; 
    //   }).reduce((partial_sum, a) => partial_sum + parseInt(a), 0)
    if (this.adjustmentAmount === undefined && this.discountAmount === undefined) {
      this.total = this.subtotal + this.allTaxTotal
    } else if (this.adjustmentAmount === undefined && this.discountAmount != undefined) {
      this.total = this.total + this.allTaxTotal
      this.discountCount(this.discount, this.subtotal)
      this.total = this.discountAmount + this.subtotal + this.allTaxTotal
    } else if (this.adjustmentAmount != undefined && this.discountAmount === undefined) {
      this.total = parseInt(this.total)
      this.total = this.adjustmentAmount + this.subtotal + this.allTaxTotal
      // this.adjustmentCount(this.adjustment)
    } else {
      this.discountCount(this.discount, this.subtotal)
      // this.adjustmentCount(this.adjustment)
      this.total = this.subtotal + this.discountAmount - this.adjustmentAmount + this.allTaxTotal
    }
  }
  save() {
    this.savdata = {
      subject: this.aqf.subject.value,
      date: this.aqf.date.value,
      opentill: this.aqf.openTill.value,
      comment: this.aqf.comment.value,
      to: this.aqf.to.value,
      address: this.aqf.address.value,
      city: this.aqf.city.value,
      state: this.aqf.state.value,
      country: this.aqf.country.value,
      zipCode: this.aqf.zipCode.value,
      email: this.aqf.email.value,
      phone: this.aqf.phone.value,
      total: this.total,
      arrayofQuotation: this.dataSource2
    }
    // if(this.add_quotation){
    var quotationData = localStorage.getItem('quotationData')
    this.addQuotationService.UpdateQuotation(`/api/Quotation/quotationUpdate`,
      quotationData,
      this.aqf.email.value,
      this.aqf.phone.value,
      this.aqf.subject.value,
      this.aqf.related.value, this.aqf.date.value, this.aqf.openTill.value, this.aqf.discountType.value,
      this.joinsymptomps(this.tags), this.aqf.statusForm.value, this.aqf.assigned.value,
      this.aqf.to.value,
      this.aqf.address.value, this.aqf.city.value, this.aqf.state.value,
      this.aqf.country.value, this.aqf.zipCode.value, this.total, this.aqdf.discountForm.value, this.aqdf.adjustmentForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigateByUrl('/admin/quotation')
          this.matSnack.showNotification("Quotation Updated", "")
          // //console.log(data)
        },
        error => {
          //console.log(error.error)
        });
    // }
  }

  applyFilter(event) {
    // //console.log((event.target as HTMLInputElement).value)
    this.discount = (event.target as HTMLInputElement).value;
    this.discountCount(this.discount, this.subtotal)
    this.discountNew = true
  }

  discountCount(discount, subtotal) {
    if (discount == null) {
      var discount2 = this.discountAmountExist;
      //console.log(discount2,"subtotal")
      this.discountAmount = ((discount2) * (this.subtotal)) / 100
      //console.log(this.discountAmount,"discount function")
      if (this.adjustmentAmount != undefined) {
        this.total = Number(this.adjustmentAmount) + this.discountAmount + this.subtotal

        // this.total = this.discountAmount + subtotal
        // //console.log(this.total)
      } else if (this.adjustmentAmount === undefined) {

        this.total = this.discountAmount + this.subtotal
        // //console.log(this.total)
      }
    }
    if (discount != null) {
      //console.log(discount,"subtotal")
      this.discountAmount = ((discount) * (this.subtotal)) / 100
      //console.log(this.discountAmount,"discount function")
      if (this.adjustmentAmount != undefined) {
        this.total = Number(this.adjustmentAmount) + this.discountAmount + this.subtotal
        // this.total = this.discountAmount + subtotal
        // console.log(this.total)
      } else if (this.adjustmentAmount === undefined) {

        this.total = this.discountAmount + this.subtotal
        // console.log(this.total)
      }
    }

  }

  applyFilter1(event) {
    // console.log((event.target as HTMLInputElement).value)
    this.adjustment = (event.target as HTMLInputElement).value;
    this.adjustmentCount(this.adjustment)
    this.showDiscount = true;
    // this.total = Number(this.adjustment) + this.discountAmount + this.subtotal
  }

  adjustmentCount(adjustment) {
    this.adjustmentAmount = Number(adjustment)
    // console.log(this.adjustmentAmount,"adjustmentamount from function")
    if (this.discountAmount != undefined) {
      this.total = Number(this.adjustmentAmount) + this.discountAmount + this.subtotal
      // this.total = this.discountAmount + this.subtotal
      // console.log(this.total)
    } else if (this.discountAmount === undefined) {

      this.total = this.adjustmentAmount + this.subtotal
      // console.log(this.total)
    }
  }

  /*
Get products list api called
*/
  getProducts() {
    this.ProductService.getProducts()
      .subscribe((data: Products[]) => {
        this.products = data;
        this.filteredListEmployee = data
        this.filteredList1 = this.filteredListEmployee.slice()
      });
  }

  // get data by id
  getQuotationData() {
    var quotationData = localStorage.getItem('quotationData')
    // console.log(quotationData,"quotationdata")
    this.addQuotationService
      .getQuotationById<Quotation>(`api/Quotation/quotation?quotationId=${quotationData}`)
      .subscribe((data: Quotation[]) => {
        // console.log(data)
        this.quotationDataById = data;
        this.quotationSubject = this.quotationDataById.subject;
        this.quotationRelated = this.quotationDataById.related;
        this.quotationDate = this.quotationDataById.date;
        this.quotationOpenTill = this.quotationDataById.openTill;
        this.quotationDiscount = this.quotationDataById.discountType;
        this.tags = this.quotationDataById.favoriteTags.split(',');
        this.quotationStatus = this.quotationDataById.status;
        this.quotationAssigned = this.quotationDataById.assigned;
        this.quotationTo = this.quotationDataById.toPerson;
        this.quotationAddress = this.quotationDataById.address;
        this.quotationCity = this.quotationDataById.city;
        this.quotationState = this.quotationDataById.state;
        this.quotationCountry = this.quotationDataById.country;
        this.quotationZipCode = this.quotationDataById.zipCode;
        this.quotationEmail = this.quotationDataById.email;
        this.quotationPhone = this.quotationDataById.phone;
        // this.total = this.quotationDataById.Total
        this.discountAmountExist = this.quotationDataById.discount
        this.adjustmentAmountExist = this.quotationDataById.adjustment
        var newAdjust = this.adjustmentAmountExist
        this.adjustmentCount(newAdjust)
      })
  }
  //Assign item from data
  AssignedItem(event) {
    // console.log(event.value)
    this.ItemsData = event.value
    this.itemData = this.ItemsData.item
    this.rateData = this.ItemsData.rate
    this.taxData = this.ItemsData.tax.split(',');
  }


  sSend() {

    var data = document.getElementById('contentToConvert');
    // html2canvas(data as any).then(canvas => {
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   const contentDataURL = canvas.toDataURL('image/png');
    //   let pdfData = new jspdf('p', 'mm', 'a4');
    //   var position = 0;
    //   pdfData.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
    //   pdfData.save(`MyPdf.pdf`);
    // });
    html2canvas(data, {
      logging: false,
      scale: 3
    }).then(function (canvas) {
      var pdf = new jspdf('h', 'mm', 'a4', true);//A4 paper, portrait
      var ctx = canvas.getContext('2d'),
        a4w = 195.9, a4h = 279.4,//A4 size,279.4 215.9mm x 279.4mm, 10 mm margin on each side, display area 190x277
        imgHeight = Math.floor(a4h * canvas.width / a4w),//Convert pixel height of one page image to A4 display scale

        renderedHeight = 0;
      console.log(imgHeight)
      var logo = document.getElementById("logo");//Icon placed in header
      while (renderedHeight < canvas.height) {
        var page = document.createElement("canvas");
        page.width = canvas.width;
        page.height = Math.min(imgHeight, canvas.height - renderedHeight);//Maybe less than one page
        page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);

        pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 20, a4w, Math.min(a4h, a4w * page.height / page.width));
        pdf.setFontSize(18)
        renderedHeight += imgHeight;
        if (renderedHeight < canvas.height) {
          pdf.addPage();//Add an empty page if there is more to follow

        }


      }

      pdf.save('Quotationview.pdf');
    });
  }

}

export interface quotation {
  items: string;
  longDescription: string;
  unit: string;
  rate: string;
  tax: string;
}

function taxCount2(itemData: any, value1: any, value2: any, value3: any, taxCount2: any, quotationId: string) {
  throw new Error('Function not implemented.');
}

