import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { FacebookCampaignService } from 'src/app/shared/services/adminpanel/facebook-campaign.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddfbinfoComponent } from './addfbinfo/addfbinfo.component';

@Component({
  selector: 'app-facebookinfo',
  templateUrl: './facebookinfo.component.html',
  styleUrls: ['./facebookinfo.component.scss']
})
export class FacebookinfoComponent implements OnInit {
  @ViewChild('stepper') private myStepper: MatStepper;
  isLinear = true;
  advList
  formCampaignGroup: FormGroup;
  formAddsGroup: FormGroup;
  FormGroup: FormGroup;
  formFacebookGroup: FormGroup;
  setadId
  setformId
  setcampaignId
  setaccountID
  ngOnit() { }
  createForm() {
    this.formCampaignGroup = this.fb.group({
      accountID: ['', Validators.compose([Validators.required, Validators.min(10)])]
    });

    this.formAddsGroup = this.fb.group({
      // addID: ['', Validators.compose([Validators.required, Validators.min(10)])],
      campaignId: ['', Validators.required]
    });
    this.FormGroup = this.fb.group({
      formId: ['', Validators.compose([Validators.required, Validators.min(10)])],
      addId: ['', Validators.required]
    });
    this.formFacebookGroup = this.fb.group({
      accountID: ['', Validators.compose([Validators.required, Validators.min(10)])],
      campaignId: ['', Validators.compose([Validators.required, Validators.min(10)])],
      adId: ['', Validators.compose([Validators.required, Validators.min(10)])],
      formId: ['', Validators.compose([Validators.required, Validators.min(10)])]
    });
  }
  verifyFormGroup: FormGroup;
  error: string;
  newaray
  educationList
  constructor(
    private fbCampaignService: FacebookCampaignService,
    private matSnack: SnackbarService,
    private http: HttpClient,
    private fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    // this.CampaignFallingAccountVerification()
    // this.get()
    this.verifyFormGroup = this.fb.group({
      accountID: new FormControl('', Validators.required),
      token: new FormControl('', [
        Validators.required

      ]),
    });
  }
  // 
  get first() { return this.formCampaignGroup.controls; }
  get second() { return this.formAddsGroup.controls; }
  get third() { return this.FormGroup.controls; }
  get forth() { return this.formFacebookGroup.controls; }

  // 1
  CampaignFallingAccountVerification() {
    this.spinnerService.show();
    if (this.formCampaignGroup.invalid) {

      return;
    }

    this.fbCampaignService.getCampaignFallingAccountVerification(`api/Admin/CampaignFallingAccountVarification?id=${this.first.accountID.value}`)
      .subscribe((data: any) => {
        this.spinnerService.hide();
        if (data) {
          if (data.statusCode == 200) {
            // console.log(data.data)
            this.spinnerService.hide();
            this.matSnack.showNotification("verified", "")

            // var accountId=this.alf.accountID.value
            this.goForward()
            this.newaray = data.data.data
            this.educationList = data.data.data
            this.newaray.map(i => i.accountId = this.first.accountID.value)
            // console.log(this.newaray)
            this.AddCampaignByJson(this.newaray)
          }

          if (data.message == 'Wrong Account') {
            this.matSnack.openSnackBar(data.message, 'X', 'red-snackbar')
          }
          // if(data.message =='Wrong Account'){
          //   this.matSnack.openSnackBar(data.message, 'X', 'red-snackbar')
          // }

        }


      },
        error => {
          console.log("error", error)
        });
    // err => {
    //   if (err.status == 500)
    //     console.log(err)
    // });

  }
  AddCampaignByJson(data) {
    this.fbCampaignService.AddCampaignByJson(
      data
    ).pipe(first())
      .subscribe(
        data => {
          console.log(data)


        },
        error => {
          console.log(error)
        });

  }


  // 2
  getAdsFallingCampaignVerification() {
    this.spinnerService.show();
    if (this.formCampaignGroup.invalid) {

      return;
    }

    this.fbCampaignService.getAdsFallingCampaignVerification(`api/Admin/AdsFallingCampaignVarification?id=${this.second.campaignId.value}`)
      .subscribe((data: any) => {
        if (data) {
          this.spinnerService.hide();
          if (data.statusCode == 200) {
            // console.log(data.data)
            this.spinnerService.hide();
            this.matSnack.showNotification("verified", "")

            // var accountId=this.alf.accountID.value
            this.goForward()
            this.newaray = data.data.data
            this.advList = data.data.data
            // "accountId":"165810965427652",
            // "campaignId":"23849161631470135"
            this.newaray.map(i => i.accountId = this.first.accountID.value)
            this.newaray.map(j => j.campaignId = this.second.campaignId.value)
            // this.newaray.map( k => k.adId =  this.second.addID.value)
            // console.log(this.newaray)
            // alert("1")
            this.AddAdsByJson(this.newaray)
          }
          if (data.message == 'Wrong Account') {
            this.matSnack.openSnackBar(data.message, 'X', 'red-snackbar')
          }
        }
        // this.matSnack.openSnackBar("campaignId Invalid", 'X', 'red-snackbar')
      },
        err => {
          if (err.status == 500)
            console.log(err)
        });

  }
  AddAdsByJson(data) {

    this.fbCampaignService.AddAdsByJson(
      data
    ).pipe(first())
      .subscribe(
        data => {
          console.log(data)

          // this.newmdg=data.message
          // this.matSnack.showNotification("DataAdded", "")
        },

        error => {
          console.log(error.error)
        });

  }



  // 3
  getFormInAdsVerification() {
    this.spinnerService.show();
    if (this.formCampaignGroup.invalid) {

      return;
    }

    this.fbCampaignService.getFormInAdsVerification(`api/Admin/FormInAdsVarification?id=${this.third.formId.value}`)
      .subscribe((data: any) => {
        if (data) {
          this.spinnerService.hide();
          if (data.statusCode == 200) {
            // console.log(data.data)
            this.spinnerService.hide();
            this.matSnack.showNotification("verified", "")

            // var accountId=this.alf.accountID.value
            this.goForward()

            this.newaray = data.data
            this.setadId = this.third.addId.value
            this.setformId = this.newaray.id
            this.setcampaignId = this.second.campaignId.value
            this.setaccountID = this.first.accountID.value
            this.AddFormsByJson(this.newaray.id, this.newaray.name)
          }
          if (data.message == 'Wrong ID') {
            this.matSnack.openSnackBar(data.message, 'X', 'red-snackbar')
          }
          // this.matSnack.openSnackBar("formId Invalid", 'X', 'red-snackbar')
        }
      },
        err => {
          if (err.status == 500)
            console.log(err)
        });
    // "{
    //     'id' :`${id}`,
    //     "name":  `${name}`,
    //     "accountId":`${this.first.accountID.value}`,
    //     "campaignId":`${this.second.campaignId.value}`,
    //     "adId":`${this.second.addID.value}`        
    // }
    // "
  }
  AddFormsByJson(id, name) {
    this.fbCampaignService.AddFormsByJson(

      [
        {
          "id": `${id}`,
          "name": `${name}`,
          "accountId": `${this.first.accountID.value}`,
          "campaignId": `${this.second.campaignId.value}`,
          "adId": `${this.third.addId.value}`
        }
      ]



    ).pipe(first())
      .subscribe(
        data => {
          console.log(data)

          // this.newmdg=data.message
          // this.matSnack.showNotification("DataAdded", "")
        },

        error => {
          console.log(error.error)
        });

  }


  // 4
  getFaceBookCampaignData() {

    this.spinnerService.show();
    if (this.formCampaignGroup.invalid) {

      return;
    }

    this.fbCampaignService.UpdateAds(this.forth.accountID.value,
      this.forth.campaignId.value,
      this.forth.adId.value,
      this.forth.formId.value,
    ).pipe(first())
      .subscribe(
        data => {
          console.log(data)
          this.fbCampaignService.getFaceBookCampaignData(`api/Admin/GetFaceBookCampaignData?accountId=${this.forth.accountID.value}&campaignId=${this.forth.campaignId.value}&adId=${this.forth.adId.value}&formId=${this.forth.formId.value}`)
            .subscribe((data: any) => {
              if (data) {


                this.spinnerService.hide();
                this.goForward()
                this.matSnack.showNotification("verified", "")
                if (data.message === 'wrong FormID') {
                  this.matSnack.showNotification("FormID invaild", "")
                }
                this.newaray = data.data
                console.log(this.newaray)
                this.newaray.map(i => i.accountId = this.first.accountID.value)
                this.newaray.map(j => j.campaignId = this.second.campaignId.value)
                this.newaray.map(k => k.adId = this.third.addId.value)
                this.newaray.map(l => l.formId = this.third.formId.value)

                // Facebookleads
                // localStorage.setItem("facebookdata",this.newaray)
                // this.router.navigate(["admin/Facebookleads"])
                this.router.navigate(['admin/Facebookleads'], {
                  state: this.newaray
                });
                // {
                //   frontEnd: JSON.stringify({ framwork: 'Angular', version: '9' }),
                //   site: 'edupala.com'
                // }
                //  this.AddFaceBookCampaignData(this.newaray)
                // this.showmodel(this.newaray)
                // if(data.message =='Wrong Account'){
                //   this.matSnack.openSnackBar(data.message, 'X', 'red-snackbar')
                // }
              }

              // this.matSnack.openSnackBar("Incorrect. Please try again.", 'X', 'red-snackbar')
            },
              err => {
                if (err.status == 500)
                  console.log(err)
              });
        },

        error => {
          console.log(error.error)
        });



  }

  showmodel(element) {
    const dialogRef = this.dialog.open(AddfbinfoComponent, {
      height: 'auto',
      width: '30%',
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.getLeads()
      // this.getLeads()
    });

  }

  AddFaceBookCampaignData(data) {
    this.fbCampaignService.AddFaceBookCampaignData(
      data
    ).pipe(first())
      .subscribe(
        data => {
          console.log(data)

          // this.newmdg=data.message
          // this.matSnack.showNotification("DataAdded", "")
        },

        error => {
          console.log(error.error)
        });

  }


  goBack() {
    this.myStepper.previous();
  }

  goForward() {
    this.myStepper.next();
  }
  gotoleads() {
    this.router.navigate(['admin/leads']);

  }
}