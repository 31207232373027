<div class="color-white backgroud-color-primary cardpadding">
    <div fxLayout="row" fxLayoutAlign="space-between">

        <p class="mat-title">Remarks
        </p>
        <mat-icon class="cursor-pointer" (click)="closeDialog()">close</mat-icon>
    </div>
</div>

<div class="cardpadding" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutAlign="center center">
        <p class="mat-h2">Are you sure want to {{local_data.value}} <span class="bold-text">
            </span>?</p>           
    </div>
    <!-- <mat-form-field appearance="outline" class="full-width form-field-font">
        <mat-label>Reason </mat-label>
        <input matInput  placeholder="Reason">
        <textarea matInput ></textarea>
    </mat-form-field> -->
    <mat-form-field appearance="outline" class="full-width form-field-font">
        <textarea matInput 
            placeholder="Please Enter Reason" 
            [formControl]="commentFC" 
            (change)="onCommentChange()">
        </textarea>
        <mat-error *ngIf="commentFC.hasError('required')">
            Comment is required.
        </mat-error>
        <mat-error *ngIf="commentFC.hasError('maxlength')">
            Max length is 30.
        </mat-error>        
      </mat-form-field> 
    <!-- Buttons -->
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <button mat-raised-button mat-dialog-close>No</button>
       
        <button mat-raised-button color="primary"  [disabled]="this.commentFC.invalid" (click)="updateStatus()" mat-dialog-close>Yes</button>

    </div>
</div>