import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { filter, first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CONSTANT } from 'src/app/shared/utils/constant';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  loginFormGroup: FormGroup;
  txtEmail: any;
  txtPassword: any;
  error: string;
  isLoading
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private spinnerService: NgxSpinnerService,
  ) {
    //     if (this.authenticationService.currentUserValue) {
    //      if(this.authenticationService.currentUserValue.role[0]=='Admin'){
    //       this.router.navigate([ '/admin/leads' ]);
    //       // console.log(this.authenticationService.currentUserValue)
    //     }
    //     if(this.authenticationService.currentUserValue.role[0]=='Employee'){
    //       this.router.navigate([ '/admin/leads' ]);
    //       // console.log(this.authenticationService.currentUserValue)
    //     }
    //  }
  }

  ngOnInit(): void {

    this.filter1()
    //   var Sound = (function () {
    //     var df = document.createDocumentFragment();
    //     return function Sound(src) {
    //         var snd = new Audio(src);
    //         df.appendChild(snd); // keep in fragment until finished playing
    //         snd.addEventListener('ended', function () {df.removeChild(snd);});
    //         snd.play();
    //         return snd;
    //     }
    // }());
    // // then do it

    // var snd = Sound("data:audio/mpeg;base64," + base64string);
    // snd.play();
    this.loginFormGroup = this.fb.group({
      password: new FormControl('', Validators.required),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(CONSTANT.emailPattern),
      ]),
    });
    this.txtEmail = this.loginFormGroup.get('email');
    this.txtPassword = this.loginFormGroup.get('password');
    localStorage.removeItem('email-change')
    localStorage.removeItem('email-change-token')

  }

  // Loginform controls
  get alf() { return this.loginFormGroup.controls; }

  //Login api call
  login() {
    this.spinnerService.show();
    if (this.loginFormGroup.invalid) {

      return;
    }

    this.authenticationService.login(this.alf.email.value, this.alf.password.value)
      .pipe(first())
      .subscribe(
        (data: any) => {

          if (data.message === "Invalid credentials") {
            //  console.log(error,"error")
            this.spinnerService.hide();
            this.error = data.message;
          }
          if (data.message === "No User Found") {
            //  console.log(error,"error")
            this.spinnerService.hide();
            this.error = data.message;
          }


          else {
            this.spinnerService.hide();
            localStorage.setItem('email-change', this.alf.email.value)
            var productright = data.isProductAccess
            localStorage.setItem('productright', productright)
            var quotationright = data.isQuotationAccess
            localStorage.setItem('quotationright', quotationright)
            // console.log(data.role)
            if (data.role[0] === 'Employee') {
              console.log(data.role[0])
              this.router.navigate(['employee/leads']);
            } else {
              this.router.navigate(['admin/leads']);
            }
          }

        },
        error => {
          this.spinnerService.hide();
          this.error = error.error.message;
        });
  }
  filter1() {
    console.log("filter1")
    let list: string[] = [];
    var newlist = {
      "data": [{
        "id": "3195474594067959",
        "field_data": [{
          "name": "email",
          "values": [
            "hardikdangarb@gmail.com"
          ]
        },
        {
          "name": "full_name",
          "values": [
            "Hardik Dangar"
          ]
        },
        {
          "name": "phone_number",
          "values": [
            "+919537607743"
          ]
        }
        ]
      },
      ]
    }
    newlist.data.forEach(element => {
      console.log(element)
      // list.push(element);
    });
  }

}
