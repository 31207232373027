import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goTemplate() {

    this.router.navigate(['admin/Template']);
  }
  goContactlist() {
    this.router.navigate(['admin/Contactlist']);
  }
  goEmailStatus() {
    this.router.navigate(['admin/email/EmailStatus']);
  }

}