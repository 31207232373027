<div class="color-white backgroud-color-primary cardpadding">
    <div fxLayout="row" fxLayoutAlign="space-between">

        <p class="mat-title">Edit Quotation
        </p>
        <mat-icon class="cursor-pointer" (click)="closeDialog()">close</mat-icon>
    </div>
</div>

<div class="cardpadding" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutAlign="center center">
        <form [formGroup]="editQuotationForm">
            <div fxLayout="column">
                <div fxLayout="row" fxLayoutGap="10px" fxFlex>
                    <!-- Items -->
                  
                    <mat-form-field appearance="outline" fxFlex="48">
                        <mat-label>Items</mat-label>
                       
                        <textarea matInput formControlName="Items" [(ngModel)]="this.local_data.items"></textarea>
                    </mat-form-field>
                    <!--  Description -->
                    <mat-form-field appearance="outline" fxFlex="48">
                        <mat-label>Description</mat-label>
                        <textarea matInput formControlName="Description"
                            [(ngModel)]="this.local_data.description"></textarea>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" fxFlex>
                    <!-- Unit -->
                    <mat-form-field appearance="outline" fxFlex="32">
                        <mat-label> Qty</mat-label>
                        <input matInput formControlName="Qty" type="number" [(ngModel)]="this.local_data.qty">
                    </mat-form-field>
                    <!-- Rate -->
                    <mat-form-field appearance="outline" fxFlex="32">
                        <mat-label> Rate</mat-label>
                        <input matInput formControlName="Rate" type="number" [(ngModel)]="this.local_data.rate">
                    </mat-form-field>
                    <!-- Tax -->
                    <mat-form-field appearance="outline" fxFlex="32">
                        <mat-label> Tax</mat-label>
                        <mat-select formControlName="Tax" [(ngModel)]="this.taxData"
                            [ngModelOptions]="{standalone: true}" multiple>
                            <mat-option [value]="option.value" *ngFor="let option of tax" required>{{option.viewValue }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>

    <!-- Buttons -->
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <button mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" (click)="EditQuotation()" mat-dialog-close>Save</button>
    </div>
</div>