<div fxLayout="column" id="top">
    <div class="color-primary backgroud-color-white cardpadding ">
        <div fxLayout="row" fxLayoutAlign="space-between">

            <p class="mat-title">Add New Quotation
            </p>
            <button mat-raised-button color="primary" routerLink="/admin/quotation">
                <mat-icon class="cursor-pointer">arrow_back</mat-icon> Back
            </button>
        </div>
    </div>
    <div class="cardpadding">
        <div fxLayout="column" fxLayoutGap="20px">
            <form [formGroup]="AddQuotationForm" fxLayout="column" fxLayoutGap="20px">
                <mat-card fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
                    <div fxLayout="column" fxFlex="50">
                        <!-- Subject -->
                        <mat-label> Subject</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput formControlName="subject" required>
                        </mat-form-field>
                        <!-- Related -->
                        <mat-label> Related</mat-label>
                        <mat-form-field appearance="outline" class="selectOptions form-field-font">
                            <mat-select (selectionChange)="Related($event)"
                            formControlName="related">
                                <mat-option [value]="option.value" *ngFor="let option of related" required>{{
                                    option.viewValue }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div fxLayout="row" fxLayoutGap="10px">
                            <!-- Date -->
                            <div fxLayout="column" fxFlex="50">
                                <mat-label> Date</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput [matDatepicker]="picker" formControlName="date" (focus)="picker.open()" required>
                                    <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <!-- Open Till -->
                            <div fxLayout="column" fxFlex="50">
                                <mat-label> Open Till</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput [matDatepicker]="picker1" formControlName="openTill" (focus)="picker1.open()">
                                    <mat-datepicker-toggle matSuffix  [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="10px">
                            <!-- Currency -->
                            <div fxLayout="column" fxFlex="50" fxHide>
                                <mat-form-field appearance="outline">
                                    <mat-label>Choose Currency</mat-label>
                                    <mat-select >
                                        <mat-option value="INR">INR</mat-option>
                                        <mat-option value="option2">USD</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <!-- Discount Type -->
                            <div fxLayout="column" fxFlex="50">
                                <mat-form-field appearance="outline">
                                    <mat-label>Discount Type</mat-label>
                                    <mat-select  (selectionChange)="discountType($event)"
                                    formControlName="discountType">
                                        <mat-option value="ND">No Discount</mat-option>
                                        <mat-option value="BT">Before Tax</mat-option>
                                        <mat-option value="BT">After Tax</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- Tags -->
                        <mat-label>Favorite tags</mat-label>
                        <div>
                            <mat-form-field class="example-chip-list" appearance="outline">
                                <mat-chip-list #chipList>
                                    <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable"
                                        (removed)="remove(tag)" class="tag-chips">
                                        {{tag}}
                                        <mat-icon matChipRemove *ngIf="removable" class="chip-clear-color">cancel
                                        </mat-icon>
                                    </mat-chip>
                                    <input placeholder="New tag..." #tagInput [formControl]="tagCtrl"
                                        [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        (matChipInputTokenEnd)="add($event)">
                                </mat-chip-list>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                    <mat-option *ngFor="let tag of filteredtags | async" [value]="tag">
                                        {{tag}}
                                        <mat-divider></mat-divider>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <!-- Comment -->
                        <!-- <mat-label>Allow Comments</mat-label>
                        <mat-slide-toggle formControlName="comment" [(ngModel)]="isChecked"></mat-slide-toggle> -->
                    </div>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div fxFlex="50" fxLayout="column">
                        <div fxLayout="row" fxLayoutGap="10px">
                            <!-- Status -->
                            <div fxLayout="column" fxFlex="50">
                                <mat-label> Status</mat-label>
                                <mat-form-field appearance="outline" class="selectOptions form-field-font">
                                    <mat-select (selectionChange)="statusValue($event)"
                                    formControlName="statusForm">
                                        <mat-option [value]="option.value" *ngFor="let option of status" required>{{
                                            option.viewValue }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <!-- Assigned -->
                            <div fxLayout="column" fxFlex="50">
                                <mat-label> Assigned</mat-label>
                                <mat-form-field appearance="outline" class="selectOptions form-field-font">
                                    <mat-select (selectionChange)="Assigned($event)"
                                    formControlName="assigned">
                                        <mat-option [value]="option.value" *ngFor="let option of assigned" required>{{
                                            option.viewValue }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- To -->
                        <mat-label> To</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput formControlName="to" required>
                        </mat-form-field>
                        <!-- Address -->
                        <mat-label> Address</mat-label>
                        <mat-form-field appearance="outline">
                            <textarea matInput formControlName="address"></textarea>
                        </mat-form-field>
                        <div fxLayout="row" fxLayoutGap="10px">
                            <!-- City -->
                            <div fxLayout="column" fxFlex="50">
                                <mat-label>City</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput formControlName="city">
                                </mat-form-field>
                            </div>
                            <!-- state -->
                            <div fxLayout="column" fxFlex="50">
                                <mat-label>State</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput formControlName="state">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="10px">
                            <!-- Country -->
                            <div fxLayout="column" fxFlex="50">
                                <mat-label>Country</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput formControlName="country">
                                </mat-form-field>
                            </div>
                            <!-- Zip Code -->
                            <div fxLayout="column" fxFlex="50">
                                <mat-label>Zip Code</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput formControlName="zipCode">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="10px">
                            <!-- Email -->
                            <div fxLayout="column" fxFlex="50">
                                <mat-label> Email</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput formControlName="email" required>
                                </mat-form-field>
                            </div>
                            <!--Phone -->
                            <div fxLayout="column" fxFlex="50">
                                <mat-label>Phone</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput formControlName="phone">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </form>
            <form [formGroup]="AddQuotationDataForm">
                <mat-card fxLayout="column" fxLayoutGap="20px">
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>
                            <!-- Description -->
                            <div fxLayout="column" fxFlex="48">
                                <mat-label>Items</mat-label>
                                <mat-form-field appearance="outline" class="form-control-sm">
                                    <mat-select formControlName="description" (selectionChange)="AssignedItem($event)">
                                        <mat-select-filter [array]="filteredListEmployee" [displayMember]="'item'"
                                            (filteredReturn)="filteredList1 =$event">
                                        </mat-select-filter>
                                        <mat-option *ngFor="let item of filteredList1" [value]="item">
                                            {{item.item}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxFlex="48">
                                <mat-label>Description</mat-label>
                                <!-- Long Description -->
                                <mat-form-field appearance="outline" class="tableFormField">
                                    <!-- <mat-label>Description</mat-label> -->
                                    <textarea matInput formControlName="longDescription"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>
                            <div fxLayout="column" fxFlex="48">
                                <mat-label>Qty (Unit)</mat-label>
                                <!-- quantity-->
                                <mat-form-field appearance="outline" class="tableFormField">
                                    <!-- <mat-label>Qty (Unit)</mat-label> -->
                                    <input matInput formControlName="unit" type="number">
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxFlex="48">
                                <mat-label>Rate</mat-label>
                                <!-- Rate -->

                                <mat-form-field appearance="outline" class="tableFormField">
                                    <input matInput formControlName="rate" type="number" [(ngModel)]="this.rateData">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>
                            <div fxLayout="column" fxFlex="48">
                                <mat-label>Tax</mat-label>
                                <!-- Tax -->
                                <mat-form-field appearance="outline" class="taxOptions form-field-font">
                                    <mat-select formControlName="tax" multiple [(ngModel)]="this.taxData">
                                        <mat-option [value]="option.value" *ngFor="let option of tax" required>{{
                                            option.viewValue }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="48">
                                <button mat-raised-button color="primary" (click)="addQuotationData()">
                                    ADD
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Table -->
                    <div class="mat-elevation-z8 quotation-list">
                        <table mat-table [dataSource]="dataSourcenew" #mytable>

                            <!-- Items Column -->
                            <ng-container matColumnDef="items">
                                <th mat-header-cell *matHeaderCellDef> Items </th>
                                <td mat-cell *matCellDef="let element"> {{element}} </td>
                            </ng-container>

                            <!-- Description Column -->
                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef> Description </th>
                                <td mat-cell *matCellDef="let element" class="ellips-line"> {{element.Description}}
                                </td>
                            </ng-container>

                            <!-- Qty Column -->
                            <ng-container matColumnDef="qty">
                                <th mat-header-cell *matHeaderCellDef> Qty </th>
                                <td mat-cell *matCellDef="let element"> {{element.Qty}} </td>
                            </ng-container>

                            <!-- Rate Column -->
                            <ng-container matColumnDef="rate">
                                <th mat-header-cell *matHeaderCellDef> Rate </th>
                                <td mat-cell *matCellDef="let element"> {{element.Rate| currency:'INR'}} </td>
                            </ng-container>

                            <!-- Tax Column -->
                            <ng-container matColumnDef="tax">
                                <th mat-header-cell *matHeaderCellDef> Tax </th>
                                <td mat-cell *matCellDef="let element"> {{element.Tax}} </td>
                            </ng-container>

                            <!-- Amount Column -->
                            <ng-container matColumnDef="amount">
                                <th mat-header-cell *matHeaderCellDef> Amount </th>
                                <td mat-cell *matCellDef="let element"> {{element.amount| currency:'INR'}} </td>
                            </ng-container>

                            <!-- actions Column -->
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element" class="action-button">
                                    <div fxLayout="row" fxLayoutGap="5px">

                                        <button mat-raised-button color="primary" (click)="openDialog_edit(element)">
                                            Edit
                                        </button>
                                        <button mat-raised-button color="warn" (click)="openDialog_delete(element)">
                                            Delete
                                        </button>
                                    </div>
                                <td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>

                    <!-- Summary -->
                    <div fxLayout="column" fxLayoutGap="10px" class="padding-summary">
                        <div>
                            <mat-divider></mat-divider>
                        </div>
                        <!-- Total -->
                        <div fxLayout="row" fxLayoutAlign="end end" class="padding-top" fxLayoutGap="10px" fxFlex>
                            <p> <b>Sub total:</b></p>
                            <div fxLayout="row" fxLayoutAlign="start" fxFlex="10">
                                <!-- <mat-icon class="summary-icon">currency_rupee</mat-icon> -->
                                <p *ngIf="this.subtotal === undefined">₹ 0.00</p>
                                <p *ngIf="this.subtotal != undefined">{{this.subtotal| currency:'INR'}}</p>
                            </div>
                        </div>

                        <!-- Discount -->
                        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" fxFlex>
                            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="30">
                                <p><b>Discount (%):</b> </p>
                                <div>
                                    <mat-form-field appearance="outline" class="">
                                        <input matInput type="number" (keyup)="applyFilter($event)" formControlName="discount" >
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="rate" fxHide>
                                        <mat-select>
                                            <mat-option value="percentage">%</mat-option>
                                            <mat-option value="FA">Fixed Amount</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start" fxFlex="10">
                                <!-- <p>-</p> -->
                                <!-- <mat-icon class="summary-icon">currency_rupee</mat-icon> -->
                                <p *ngIf="this.discountAmount === undefined">₹ 0.00</p>
                                <p *ngIf="this.discountAmount != undefined"> {{this.discountAmount| currency:'INR'}}</p>
                            </div>
                        </div>

                        <!-- Tax -->
                        <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10%"
                            *ngIf="this.totalTaxAmountCGST > 0" fxFlex>
                            <p><b>CGST(9.00%):</b> </p>
                            <div fxLayout="row" fxLayoutAlign="start" fxFlex="10">
                                <!-- <mat-icon class="summary-icon">currency_rupee</mat-icon> -->
                                <p> {{this.totalTaxAmountCGST| currency:'INR'}}</p>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10%"
                            *ngIf="this.totalTaxAmountSGST > 0" fxFlex>
                            <p><b>SGST(9.00%):</b> </p>
                            <div fxLayout="row" fxLayoutAlign="start" fxFlex="10">
                                <!-- <mat-icon class="summary-icon">currency_rupee</mat-icon> -->
                                <p>{{this.totalTaxAmountSGST| currency:'INR'}}</p>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10%"
                            *ngIf="this.totalTaxAmounteighteenGST > 0" fxFlex>
                            <p><b>GST(9.00%):</b> </p>
                            <div fxLayout="row" fxLayoutAlign="start" fxFlex="10">
                                <!-- <mat-icon class="summary-icon">currency_rupee</mat-icon> -->
                                <p>{{this.totalTaxAmounteighteenGST}}</p>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10%"
                            *ngIf="this.totalTaxAmounteighteenIGST > 0" fxFlex>
                            <p><b>IGST(18.00%):</b> </p>
                            <div fxLayout="row" fxLayoutAlign="start" fxFlex="10">
                                <!-- <mat-icon class="summary-icon">currency_rupee</mat-icon> -->
                                <p>{{this.totalTaxAmounteighteenIGST}}</p>
                            </div>
                        </div>
                        <!-- Adjustment -->
                        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" fxFlex>
                            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="30">
                                <p><b>Adjustment:</b> </p>
                                <div>
                                    <mat-form-field appearance="outline" class="">
                                        <input matInput type="number" formControlName="adjustment" (keyup)="applyFilter1($event)" >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start" fxFlex="10">
                                <p *ngIf="this.adjustmentAmount === undefined">₹ 0.00</p>
                                <p *ngIf="this.adjustmentAmount != undefined">₹ {{this.adjustmentAmount}}</p>
                            </div>
                        </div>
                        <div class="padding-top">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="padding-top">
                            <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px" fxFlex>
                                <p> <b>Total:</b></p>
                                <div fxLayout="row" fxLayoutAlign="start" fxFlex="10">
                                    <!-- <mat-icon class="summary-icon">currency_rupee</mat-icon> -->
                                    <p class="color-primary" *ngIf="this.total === undefined">₹ 0.00</p>
                                    <p class="color-primary" *ngIf="this.total != undefined">₹ {{this.total}}</p>
                                    <!-- <p>{{this.total}}</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </form>
            <div fxLayoutAlign="end center" class="padding-top" fxLayoutGap="10px">
                <!-- <button mat-raised-button color="primary">Save and Send</button> -->
                <!-- <button mat-raised-button color="primary" [matMenuTriggerFor]="menu" [disableRipple]="true">Save and Send</button>
             
                <mat-menu #menu="matMenu" xPosition="after">
                    <button mat-menu-item (click)="sSend()">Download</button>
                    <button mat-menu-item> 
                         <a class="fa fa-envelope e-color email e-color-hover " href="mailto: {{}}"
                        target="_blank"></a>
                    </button>
                   
                  
                </mat-menu> -->
                <button mat-raised-button color="primary" (click)="save()">Save</button>
            </div>
        </div>
    </div>
</div>