<div fxLayout="column" id="top" class="cardpadding">
    <mat-card fxLayout="column" fxLayoutGap="10px">
        <div class="color-primary backgroud-color-white  ">
            <div fxLayout="row" fxLayoutAlign="space-between">

                <p class="mat-title">Status
                </p>

            </div>
        </div>
        <div>
            <mat-divider>
            </mat-divider>
        </div>
        <div fxLayout="row" fxLayoutAlign="start">
            <button mat-raised-button color="primary" (click)="addstatus()">
                <mat-icon class="cursor-pointer">group_add</mat-icon> Add Status
            </button>
        </div>
        <div *ngIf="isLoading">
            <mat-card class="spinner" fxLayoutAlign="space-around center">
                <mat-progress-spinner mode="indeterminate" diameter="50" color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </mat-card>
        </div>
        <div *ngIf="!isLoading" class="employee-list ">
            <table mat-table [dataSource]="this.dataSource" class="mat-elevation-z8">

                <!-- Position Column -->
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef> #</th>
                    <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
                </ng-container>

                <!-- Image Column -->


                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="column" fxLayoutGap="5px">
                            <p>{{element.statusName}} </p>

                        </div>

                    <td>
                </ng-container>
                <!-- actions Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element" class="action-button">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">

                            <button mat-menu-item (click)="editstatus(element)">
                                Edit
                            </button>
                            <button mat-menu-item (click)="deletestatus(element)">
                                Delete
                            </button>
                        </mat-menu>
                    <td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (mouseover)="handleMouseOver(row)"
                    (mouseleave)="handleMouseLeave(row)"></tr>
            </table>
            <!-- <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator> -->
            <mat-paginator [pageSizeOptions]="[10, 25, 50]" #scheduledOrdersPaginator showFirstLastButtons>
            </mat-paginator>
        </div>
    </mat-card>
</div>