import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { Quotation } from 'src/app/shared/models/adminpanel/quotation';
import { AddQuotationService } from 'src/app/shared/services/adminpanel/add-quotation.service';
import { quotation } from '../quotation.component';
interface Tax {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-edit-quotation',
  templateUrl: './edit-quotation.component.html',
  styleUrls: ['./edit-quotation.component.scss']
})
export class EditQuotationComponent implements OnInit {
  editQuotationForm: FormGroup;
  tax: Tax[] = [
    { value: '9.00% CGST', viewValue: '9.00% CGST' },
    { value: '9.00% SGST', viewValue: '9.00% SGST' },
    { value: '18.00% GST', viewValue: '18.00% GST' },
    { value: '18.00% IGST', viewValue: '18.00% IGST' },
  ];
  local_data: any;
  action: any;
  taxData: any;
  QuotationDataById: Quotation[];

  constructor(public dialogRef: MatDialogRef<EditQuotationComponent>,
    private quotationService: AddQuotationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: EditQuotationComponent,) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  ngOnInit(): void {

    this.editQuotationForm = new FormGroup({
      Items: new FormControl(''),
      Description: new FormControl(''),
      Qty: new FormControl(''),
      Rate: new FormControl(''),
      Tax: new FormControl(''),

    })
    this.taxData = this.local_data.tax.split(',');
    this.quotationById()
    // console.log(this.taxData)
  }

  // AddQuotationDataForm controls
  get aqdf() { return this.editQuotationForm.controls; }

  //Close dialog
  closeDialog() {
    this.dialogRef.close({ data: 'Cancel' });
  }

  //join symptomps function
  joinsymptomps(arrayValue) {
    var StringValue = arrayValue
    return Array.prototype.map.call(StringValue, function (item) {
      return item;
    }).join(",");
  }

  //Edit quotation api call
  EditQuotation() {
    var quotationId=this.local_data.Quotationid
    console.log(this.local_data.Quotationid)
    // if(quotationId == null){
    //   // console.log("quotation id  null");
    //   var data = {
    //     id: this.local_data.itemId,
    //     Items: this.aqdf.Items.value,
    //     Description: this.aqdf.Description.value,
    //     Qty: this.aqdf.Qty.value,
    //     Rate: this.aqdf.Rate.value,
    //     Tax: this.joinsymptomps(this.aqdf.Tax.value),
    //     amount: this.aqdf.Rate.value * this.aqdf.Qty.value
    //   }
    // }
    // if(quotationId != null){
      var id = this.local_data.id
      this.local_data.Quotationid
      var amount=this.aqdf.Rate.value * this.aqdf.Qty.value
      // console.log("quotation id not null")
      this.quotationService.UpdateQuotationItem(`api/Quotation/updateItem`,
      
      this.local_data.itemId,
        this.aqdf.Items.value,
        this.aqdf.Description.value,
        this.aqdf.Qty.value,
        this.aqdf.Rate.value,
        this.joinsymptomps(this.aqdf.Tax.value),
        // quotationId,
        // amount
      )
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data)
          // this.router.navigateByUrl('/admin/leads')
          this.dialogRef.close({ data: data });
        },
        error => {
          console.log(error.error)
        });
    // }
    // this.dialogRef.close({ data: data });
  }

  quotationById(){
    var quotationId=this.local_data.Quotationid
    if(quotationId != null){
      this.quotationService.getQuotationById<any>(`api/Quotation/quotation?quotationId=${quotationId}`)
      .subscribe((data) => {
      this.QuotationDataById = data;
      // console.log(data,"items")
      // var quotationItemId =this.QuotationDataById.Id;
    })
    }
  }
}
