import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Leads } from 'src/app/shared/models/adminpanel/leads/leads';
import { RestmanagerService } from 'src/app/shared/utils/restmanagerService';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  private restmanagerService: RestmanagerService;
  _apiEndPoint: string = '';

  constructor(private http: HttpClient,
    restmanagerService: RestmanagerService) {
    this.restmanagerService = restmanagerService;
    this._apiEndPoint = environment.Url;
  }

  //Get leads-list api
  getLeads() {
    return this.http.get<Leads[]>(`${environment.apiUrl}/api/lead/LeadList`);
  }
  //Get leads-list api
  getLeadswithpagination(apiURL) {
    return this.http.get<Leads[]>(`${environment.apiUrl}/${apiURL}`);
  }
  getalluser() {
    return this.http.get<Leads[]>(`${environment.apiUrl}/api/auth/User-List`);
  }

  //Get Employee leads-list api
  getEmployeeLeads(apiURL) {
    return this.http.get<Leads[]>(`${environment.apiUrl}/${apiURL}`);
  }

  //Get lead data by id api
  getLeaddatabyid<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this.http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }

  //Get lead data by id api
  getLeaddatabykeyword<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this.http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }

  private mapAndCatchError<TData>(response: Observable<TData>): Observable<TData> {

    return response.pipe(
      map((r: TData) => {

        return r;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(null);
      })
    );
  }

  //Add Lead api
  AddLead(name: string, CompanyName: string, email: string, Phone: string, whatsappnumber: string,
    leadvalue: string, tags: string, assigneuserid: string, assigned: string, status: string, address: string, city: string,
    state: string, country: string, zip: string, language: string, description: string, date_contact: string,
    sources: string, position: string, website: string, service_category: string, aadharcard_no: string) {

    return this.http.post<Leads>(`${environment.apiUrl}/api/lead/AddLead`, {
      name, CompanyName, email, Phone,
      whatsappnumber, leadvalue, tags, assigneuserid, assigned, status, address,
      city, state, country, zip, language, description, date_contact,
      sources, position, website, service_category, aadharcard_no
    })
      .pipe(map(res => {
        return res;
      }));
  }

  //Update Lead api
  UpdateLead(apiURL: string, leadId: string, name: string, CompanyName: string,
    email: string, Phone: string, whatsappnumber: string, leadvalue: string,
    tags: string, assigneuserid: string, assigned: string, status: string, address: string, city: string, state: string,
    country: string, zip: string, language: string, description: string, date_contact: string,
    sources: string, position: string, website: string, service_category: string, aadharcard_no: string) {

    return this.http.post<Leads>(`${environment.apiUrl}/${apiURL}`, {
      leadId, name, CompanyName, email, Phone,
      whatsappnumber, leadvalue, tags, assigneuserid, assigned, status, address,
      city, state, country, zip, language, description, date_contact,
      sources, position,
      website, service_category,
      aadharcard_no
    })
      .pipe(map(res => {
        return res;
      }));
  }

  //Delete Lead  api
  DeleteLead<T>(apiURL: string, leadId: any): Observable<T[]> {

    return this.mapAndCatchError(
      this.http.post<T[]>(`${this._apiEndPoint}/${apiURL}`, {
        leadId
      })
    );
  }

  //change Lead status  api
  ChangeLeadStatus<T>(apiURL: string, leadId: any, status: any, remark: any): Observable<T[]> {

    return this.mapAndCatchError(
      this.http.post<T[]>(`${this._apiEndPoint}/${apiURL}`, {
        leadId,
        status,
        remark
      })
    );
  }

  //Assign Employee status  api
  AssignedEmployee<T>(apiURL: string, LeadId: any, UserId: any): Observable<T[]> {

    return this.mapAndCatchError(
      this.http.post<T[]>(`${this._apiEndPoint}/${apiURL}`, {
        LeadId,
        UserId
      })
    );
  }

  ImportLead(apiURL, formdata) {

    return this.http.post<Leads>(`${environment.apiUrl}/${apiURL}`,
      formdata
    )
      .pipe(map(res => {
        return res;
      }));
  }

  ImportLeadsByOutSource(userId: string, items) {
    return this.http.post<Leads>(`${environment.apiUrl}/api/lead/ImportLeadsByOutSource`, {
      userId, items
    })
      .pipe(map(res => {
        return res;
      }));
  }
}
