import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CONSTANT } from 'src/app/shared/utils/constant';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
  isLoading;
  dashBoardShowContent = (CONSTANT.dashBoardShowContent)
  draft = (CONSTANT.draft)
  notSent = (CONSTANT.notSent)
  sent = (CONSTANT.sent)
  unPaid = (CONSTANT.unPaid)
  open = (CONSTANT.open)
  partiallyPaid = (CONSTANT.partiallyPaid)
  expired = (CONSTANT.expired)
  revised = (CONSTANT.revised)
  overDue = (CONSTANT.overDue)
  decline = (CONSTANT.decline)
  paid = (CONSTANT.paid)
  accepted = (CONSTANT.accepted)
  show_INR: boolean = true
  show_USD: boolean = false

  selectedVal: string;
  selectedVal1: string;
  selectedVal2: string;
  AdminRevenuethisMonth: any;
  AdminRevenuethisWeek: any;
  AdminRevenuetoday: any;
  comparevalue: any;
  revenuecomparevalue: any;
  patientcomparevalue: any;
  compareday: string;
  revenuecompareday: string;
  patientcompareday: string;
  selectedsurgery;
  ActivityView;
  doctorcount;
  Consultationscount;
  DoctorsRevenuecount;
  alertcount;
  PatientCount;
  AdminRevenuecount;
  awayDoctors;
  consultationCancelled;
  doctorNotResponding;
  patientInQueue;
  patientInSession;
  averageWaitTime;
  currentWaitTime;
  patientAwaitingSessions;
  DoctorsRevenuethisMonth;
  DoctorsRevenuethisWeek;
  DoctorsRevenuetoday;
  PatientCountthisMonth;
  PatientCountthisWeek;
  PatientCounttoday;
  ConsultationsthisMonth;
  ConsultationsthisWeek;
  Consultationstoday;
  ConsultationscompareYesterday;
  ConsultationscompareLastmonth;
  ConsultationscompareLastweek;
  doctorcompareYesterday;
  RevenuecompareYesterday;
  RevenuecompareLastweek;
  RevenuecompareLastmonth;
  PatientcompareLastmonth;
  PatientcompareYesterday;
  PatientcompareLastweek;
  newsample
  speciality = new FormControl('all', []);
  AllSpeciality: any;
  arrayCurrencyChange: any;
  constructor() { }

  ngOnInit(): void {
  }

  //Comparison change value
  onValChange(event) {
    if (event.source.value === this.Consultationstoday) {
      // this.comparevalue = this.ConsultationscompareYesterday
      this.compareday = "Yesterday"
    }
    if (event.source.value === this.ConsultationsthisWeek) {
      // this.comparevalue = this.ConsultationscompareLastweek
      this.compareday = "Last Week"
    }
    if (event.source.value === this.ConsultationsthisMonth) {
      // this.comparevalue = this.ConsultationscompareLastmonth
      this.compareday = "Last Month"
    }
  }

  onValChangerevenue(event) {
    if (event.source.value === this.AdminRevenuetoday) {
      // this.revenuecomparevalue = this.RevenuecompareYesterday
      this.revenuecompareday = "Yesterday"
    }
    if (event.source.value === this.AdminRevenuethisWeek) {
      // this.revenuecomparevalue = this.RevenuecompareLastweek
      this.revenuecompareday = "Last Week"
    }
    if (event.source.value === this.AdminRevenuethisMonth) {
      // this.revenuecomparevalue = this.RevenuecompareLastmonth
      this.revenuecompareday = "Last Month"
    }
  }

  onValChangepatient(event) {
    if (event.source.value === this.PatientCounttoday) {
      // this.patientcomparevalue = this.PatientcompareYesterday
      this.patientcompareday = "Yesterday"
    }
    if (event.source.value === this.PatientCountthisWeek) {
      // this.patientcomparevalue = this.PatientcompareLastweek
      this.patientcompareday = "Last Week"
    }
    if (event.source.value === this.PatientCountthisMonth) {
      // this.patientcomparevalue = this.PatientcompareLastmonth
      this.patientcompareday = "Last Month"
    }
  }

  currencyChange(e) {
    this.arrayCurrencyChange = e.value

    console.log(this.arrayCurrencyChange)
    if (this.arrayCurrencyChange === 'INR') {
      this.show_INR = true
      this.show_USD = false
    } else {
      this.show_USD = true
      this.show_INR = false
    }
  }
}
