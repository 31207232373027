import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RemindersService } from 'src/app/shared/services/adminpanel/leads/reminders.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-delete-reminder',
  templateUrl: './delete-reminder.component.html',
  styleUrls: ['./delete-reminder.component.scss']
})
export class DeleteReminderComponent implements OnInit {
  local_data: any;
  action: any;

  constructor(
    public dialogRef: MatDialogRef<DeleteReminderComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DeleteReminderComponent,
    public matSnack: SnackbarService,
    private LeadReminderServices: RemindersService
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  ngOnInit(): void {
  }

  // Close dialog
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  //Delete reminder
  reject() {
    console.log(this.local_data)
    this.LeadReminderServices.DeleteReminder(`api/lead/DeleteLeadReminder`,`${this.local_data.leadReminderId}`)
      .subscribe((res) => {
        this.dialogRef.close({ data: res });
        this.matSnack.showNotification1("Reminder Deleted", "")
        // getReminderDataByid()
      });
  }

    //Get Reminder data by id
    getReminderDataByid(id) {
     
      this.LeadReminderServices
        .getReminderdatabyid<any>(`api/lead/ListOfLeadReminders?leadId=${id}`)
        .subscribe((data: any[]) => {
         
          if (data || data != null) {
  
          
          }
        });
    }
}
