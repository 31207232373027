import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LeadsComponent } from './leads/leads.component';
import { LayoutsModule } from 'src/app/layout/layout.module';
import { CommonLayoutComponent } from 'src/app/layout/common-layout/common-layout.component';
import { BlankLayoutComponent } from 'src/app/layout/blank-layout/blank-layout.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ViewLeadComponent } from './leads/view-lead/view-lead.component';
import { AddLeadComponent } from './leads/add-lead/add-lead.component';
import { ImportLeadComponent } from './leads/import-lead/import-lead.component';
import { AuthGuard } from 'src/app/shared/helpers/auth.guard';
import { EmployeeLeadsComponent } from '../employee/employee-leads/employee-leads.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { ViewEmployeeComponent } from './employee-list/view-employee/view-employee.component';
import { AddEmployeeComponent } from './employee-list/add-employee/add-employee.component';
import { QuotationComponent } from './quotation/quotation.component';
import { AddQuotationComponent } from './quotation/add-quotation/add-quotation.component';
import { ProductsComponent } from './products/products.component';
import { AddProductComponent } from './products/add-product/add-product.component';
import { UpdateQuotationComponent } from './quotation/update-quotation/update-quotation.component';
import { SmsComponent } from './sms/sms.component';
import { EmailComponent } from './email/email.component';
import { TemplategalaryComponent } from './templategalary/templategalary.component';
import { ContactlistComponent } from './contactlist/contactlist.component';
import { StatusComponent } from './status/status.component';
import { ImportLeadsAssignComponent } from './leads/import-leads-assign/import-leads-assign.component';
import { FacebookinfoComponent } from './facebookinfo/facebookinfo.component';
import { EditinfoComponent } from './facebookinfo/editinfo/editinfo.component'
import { EditTemplateComponent } from './templategalary/edit-template/edit-template.component';
import { ViewTemplateComponent } from './templategalary/view-template/view-template.component';
import { EmailstatusComponent } from './email/emailstatus/emailstatus.component';
import { AddDraftComponent } from './templategalary/add-draft/add-draft.component';




@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: CommonLayoutComponent,
        children: [
          { path: 'dashboard', component: DashboardComponent, pathMatch: 'full', canActivate: [AuthGuard] },
          {
            path: 'leads',
            children: [
              { path: '', component: LeadsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'view-lead', component: ViewLeadComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'add-lead', component: AddLeadComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'import-lead', component: ImportLeadComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'import-lead-assign', component: ImportLeadsAssignComponent, pathMatch: 'full', canActivate: [AuthGuard] },
            ]
          },

          {
            path: 'quotation',
            children: [
              { path: '', component: QuotationComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'add-quotation', component: AddQuotationComponent, pathMatch: 'full' },
              { path: 'edit-quotation', component: UpdateQuotationComponent, pathMatch: 'full' },
            ]
          },
          {
            path: 'employee',
            children: [
              { path: '', component: EmployeeListComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'add-employee', component: AddEmployeeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'view-employee', component: ViewEmployeeComponent, pathMatch: 'full', canActivate: [AuthGuard] },

            ]
          },
          {
            path: 'products',
            children: [
              { path: '', component: ProductsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'add-product', component: AddProductComponent, pathMatch: 'full', canActivate: [AuthGuard] },
            ]
          },
          {
            path: 'status',
            children: [
              { path: '', component: StatusComponent, pathMatch: 'full', canActivate: [AuthGuard] }

            ]
          },
          {
            path: 'sms',
            children: [
              { path: '', component: SmsComponent, pathMatch: 'full', canActivate: [AuthGuard] }

            ]
          },
          {
            path: 'email',
            children: [
              { path: '', component: EmailComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'Template', component: TemplategalaryComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'add-template', component: AddDraftComponent, pathMatch: 'full', canActivate: [AuthGuard] },
               { path: 'edit-template', component: EditTemplateComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'view-template', component: ViewTemplateComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'emailstatus', component: EmailstatusComponent, pathMatch: 'full', canActivate: [AuthGuard] },

            ]
          },
          // {
          //   path: 'Template',
          //   children: [
          //     { path: '', component: TemplategalaryComponent, pathMatch: 'full', canActivate: [AuthGuard] },
          //     { path: 'edit-template', component: EditTemplateComponent, pathMatch: 'full', canActivate: [AuthGuard] },
          //     { path: 'view-template', component: ViewTemplateComponent, pathMatch: 'full', canActivate: [AuthGuard] },

          //   ]
          // },
          {
            path: 'Contactlist',
            children: [
              { path: '', component: ContactlistComponent, pathMatch: 'full', canActivate: [AuthGuard] }

            ]
          },
          {
            path: 'Campaign',
            children: [
              { path: '', component: FacebookinfoComponent, pathMatch: 'full', canActivate: [AuthGuard] }

            ]
          },
          {
            path: 'Facebookleads',
            children: [
              { path: '', component: EditinfoComponent, pathMatch: 'full', canActivate: [AuthGuard] }

            ]
          }
        ],
      },
      {
        path: '',
        component: BlankLayoutComponent,
        children: [
          { path: 'login', component: LoginComponent, pathMatch: 'full' },
        ],
      },
    ]),
    LayoutsModule
  ],
  exports: [RouterModule],
})
export class AdminRoutingModule { }
