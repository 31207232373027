import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient,
    private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  //Login
  login(email: string, password: string) {

    return this.http.post<User>(`${environment.apiUrl}/api/Auth/Login`, { email, password })
      .pipe(map(user => {

        user.authdata = user.token
        localStorage.setItem('currentUser', JSON.stringify(user));

        this.currentUserSubject.next(user);
        return user;
      }));
  }

  //Logout
  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/admin/login']);
  }

  //Forgot password
  
  ForgotPassword(email: string) {

    return this.http.post<any>(`${environment.apiUrl}/api/auth/Forgot-Password`, { email })
      .pipe(map(user => {

        return user;
      }));
  }

  //Change password
  ChangePassword(email: string,token: string, password: string) {

    return this.http.post<any>(`${environment.apiUrl}/api/auth/Reset-Password`, { email,token, password })
      .pipe(map(user => {

        return user;
      }));
  }
}
