
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { StatusService } from 'src/app/shared/services/status.service';
@Component({
  selector: 'app-edit-status',
  templateUrl: './edit-status.component.html',
  styleUrls: ['./edit-status.component.scss']
})
export class EditStatusComponent implements OnInit {
  local_data
  statusName
  commentFC = new FormControl('', [
    Validators.required,
    Validators.maxLength(30)
  ]);
  constructor(
    public matSnack: SnackbarService,
    private statusService: StatusService,
    public dialogRef: MatDialogRef<EditStatusComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: EditStatusComponent
  ) {
    this.local_data = { ...data };
  }
  // Close dialog
  ngOnInit(): void {
    console.log(this.local_data)
    this.statusName = this.local_data.statusName
  }

  onCommentChange() {
    console.log(this.commentFC.invalid);
  }
  get alf() { return this.commentFC.value; }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  updateStatus() {
    this.statusService.UpdateleadStatus(`${this.local_data.statusId}`, this.alf).subscribe((data: any[]) => {
      this.dialogRef.close({ data: data });
      this.matSnack.showNotification("Status Updated", "")
    });
  }
}
