import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { EmployeeRoutingModule } from './employee-routing.module';
import { EmployeeDashboardComponent } from './employee-dashboard/employee-dashboard.component';
import { EmployeeLeadsComponent } from './employee-leads/employee-leads.component';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDatetimeModule, MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatSelectFilterModule } from 'mat-select-filter';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { MaterialModule } from 'src/app/modules/material/material.module';
import { AddLeadComponent } from './employee-leads/add-lead/add-lead.component';
import { ImportLeadComponent } from './employee-leads/import-lead/import-lead.component';
import { ViewLeadComponent } from './employee-leads/view-lead/view-lead.component';
import { StatusremarksComponent } from './employee-leads/statusremarks/statusremarks.component';
// import { NgxSpinnerModule } from 'ngx-spinner';
// import { AdminRoutingModule } from '../admin/admin-routing.module';



@NgModule({
  declarations: [
    EmployeeDashboardComponent,
    EmployeeLeadsComponent,
    AddLeadComponent,
    ImportLeadComponent,
    ViewLeadComponent,
    StatusremarksComponent,

  ],
  imports: [
    CommonModule,
    EmployeeRoutingModule,
    HttpClientModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    // AdminRoutingModule,
    MatSelectFilterModule,
    NgxCsvParserModule,
    MatNativeDatetimeModule,
    MatDatetimepickerModule,
    // NgxSpinnerModule
  
  ],
  providers: [DatePipe],
})
export class EmployeeModule { }
