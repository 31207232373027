<div fxLayout="column" id="top" class="cardpadding">
  <mat-card fxLayout="column" fxLayoutGap="10px">
    <div class="color-primary backgroud-color-white ">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <p class="mat-title" *ngIf="this.Editproduct != 'Edit-product'">Add new Product</p>
        <p class="mat-title" *ngIf="this.Editproduct === 'Edit-product'">Product</p>
        <button mat-raised-button color="primary" routerLink="/admin/products">
          <mat-icon class="cursor-pointer">arrow_back</mat-icon> Back
        </button>
      </div>
    </div>
    <div>
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            Product
          </ng-template>
          <form [formGroup]="AddproductForm">
            <div fxLayout="column">
              <div fxLayoutAlign="start ">

                <div fxLayout="column" fxLayout.lt-sm="column" fxLayoutAlign="start" fxLayoutAlign.lt-sm="start" fxFlex>
                  <!-- Item -->
                  <div>
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>Item</mat-label>
                      <textarea matInput formControlName="item" [(ngModel)]="this.productItem"></textarea>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="space-between " fxFlex>
                    <!-- Rate -->
                    <div fxFlex="49">
                      <mat-form-field appearance="outline" class="full-width form-field-font">
                        <mat-label>Rate</mat-label>
                        <input matInput formControlName="rate" placeholder="" [(ngModel)]="this.productRate">
                      </mat-form-field>
                    </div>

                    <!-- Tax -->
                    <div fxFlex="49">
                      <mat-form-field appearance="outline" class="full-width form-field-font">
                        <mat-label>Tax</mat-label>
                        <!-- {{this.productTax}} -->
                        <mat-select formControlName="tax" [(ngModel)]="this.productTax" multiple>
                          <mat-option [value]="option.value" *ngFor="let option of tax">
                            {{ option.viewValue }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Button -->
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button mat-raised-button>Cancel</button>
                <button mat-raised-button color="primary" (click)="addproduct()"
                  [disabled]="AddproductForm.invalid">Save</button>
              </div>
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-card>
</div>