<div fxLayout="column" id="top" class="cardpadding">
  <mat-card fxLayout="column" fxLayoutGap="10px">
    <div class="color-primary backgroud-color-white  ">
      <div fxLayout="row" fxLayoutAlign="space-between">

        <p class="mat-title">Add new lead
        </p>
        <button mat-raised-button color="primary" routerLink="/admin/leads">
          <mat-icon class="cursor-pointer">arrow_back</mat-icon> Back
        </button>
      </div>
    </div>
    <div>
      <mat-divider>
      </mat-divider>
    </div>
    <div>
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            Profile
          </ng-template>
          <form [formGroup]="AddLeadForm">
            <div fxLayout="column" fxLayoutGap="20px">

              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" fxFlex>
                <!-- Status -->
                <div fxFlex="32">

                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status">
                      <mat-option *ngFor="let topping of filteredList2" [value]="topping">{{topping.statusName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!-- Sources -->
                <div fxFlex="32">
                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>Sources</mat-label>
                    <mat-select placeholder="Sources " formControlName="sources">
                      <mat-select-filter [array]="variables3" (filteredReturn)="filteredList3 =$event">
                      </mat-select-filter>
                      <mat-option *ngFor="let item of filteredList3" [value]="item">
                        {{item}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!-- Assigned -->
                <div fxFlex="32" fxHide>
                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>Assigned</mat-label>
                    <mat-select placeholder="Assigned " (selectionChange)="AssignedEmployee($event)"
                      formControlName="assigned">
                      <mat-select-filter [array]="filteredListEmployee" [displayMember]="'name'"
                        (filteredReturn)="filteredList1 =$event">
                      </mat-select-filter>
                      <mat-option *ngFor="let item of filteredList1" [value]="item.userid">
                        {{item.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div>
                <mat-divider></mat-divider>
              </div>
              <!-- Tags -->
              <div fxLayout="column">
                <mat-form-field class="example-chip-list">

                  <mat-chip-list #chipList aria-label="tag selection">
                    <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable"
                      (removed)="remove(tag)" class='tag-chips'>
                      {{tag}}
                      <mat-icon matChipRemove *ngIf="removable" class="chip-clear-color">cancel
                      </mat-icon>
                    </mat-chip>
                    <input placeholder="Tag" #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
                      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (matChipInputTokenEnd)="add($event)">
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let tag of filteredtags | async" [value]="tag">
                      {{tag}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div>
                <mat-divider></mat-divider>
              </div>
              <div fxLayout="column">
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                  fxLayoutAlign.lt-sm="start" fxFlex>
                  <!-- Name -->
                  <div fxFlex="48" fxFlex.lt-sm="100">
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>Name</mat-label>
                      <input matInput formControlName="name" placeholder="">
                    </mat-form-field>
                  </div>
                  <!-- Address -->
                  <div fxFlex="48" fxFlex.lt-sm="100">
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>Address</mat-label>
                      <input matInput formControlName="address" placeholder="">
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                  fxLayoutAlign.lt-sm="start" fxFlex>
                  <!-- Position -->
                  <div fxFlex="48" fxFlex.lt-sm="100" fxHide>
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>Position</mat-label>
                      <input matInput formControlName="position" placeholder="">
                    </mat-form-field>
                  </div>
                  <!-- Email -->
                  <div fxFlex="48" fxFlex.lt-sm="100">
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>Email Address</mat-label>
                      <input matInput formControlName="email" placeholder="">
                    </mat-form-field>
                  </div>
                  <!-- City -->
                  <div fxFlex="48" fxFlex.lt-sm="100">
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>City</mat-label>
                      <input matInput formControlName="city" placeholder="">
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                  fxLayoutAlign.lt-sm="start" fxFlex>
                  <!-- Website -->
                  <div fxFlex="48" fxFlex.lt-sm="100">
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>Website</mat-label>
                      <input matInput formControlName="website" placeholder="">
                    </mat-form-field>
                  </div>
                  <!-- State -->
                  <div fxFlex="48" fxFlex.lt-sm="100">
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>State</mat-label>
                      <input matInput formControlName="state" placeholder="">
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                  fxLayoutAlign.lt-sm="start" fxFlex>
                  <!-- Phone -->
                  <div fxFlex="48" fxFlex.lt-sm="100">
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>Phone</mat-label>
                      <input matInput formControlName="phone" placeholder="">
                    </mat-form-field>
                  </div>
                  <!-- Country -->
                  <div fxFlex="48" fxFlex.lt-sm="100">
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>Country</mat-label>
                      <input matInput formControlName="country" placeholder="">
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                  fxLayoutAlign.lt-sm="start" fxFlex>
                  <!-- Whatsapp -->
                  <div fxFlex="48" fxFlex.lt-sm="100">
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>Whatsapp Number</mat-label>
                      <input matInput formControlName="whatsappnumber" placeholder="">
                    </mat-form-field>
                  </div>
                  <!-- Zip -->
                  <div fxFlex="48" fxFlex.lt-sm="100">
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>Zip Code</mat-label>
                      <input matInput formControlName="zip" placeholder="">
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                  fxLayoutAlign.lt-sm="start" fxFlex>
                  <!-- Lead value -->
                  <div fxFlex="48" fxFlex.lt-sm="100">
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>Lead Value</mat-label>
                      <input matInput formControlName="leadvalue" placeholder="">
                      <span matSuffix>₹&nbsp;</span>
                    </mat-form-field>
                  </div>
                  <!-- Language -->
                  <div fxFlex="48" fxFlex.lt-sm="100">
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>Default Language</mat-label>
                      <mat-select [(ngModel)]="selectedValue" formControlName="language" name="language">
                        <mat-option *ngFor="let language of allLanguages" [value]="language">
                          {{language}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>
                </div>
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                  fxLayoutAlign.lt-sm="start" fxFlex>
                  <!-- Company -->
                  <div fxFlex="48" fxFlex.lt-sm="100">
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>Comapany</mat-label>
                      <input matInput formControlName="company" placeholder="">
                    </mat-form-field>
                  </div>
                </div>
                <!-- Description -->
                <div>
                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>Description</mat-label>
                    <textarea matInput formControlName="description"></textarea>
                  </mat-form-field>
                </div>
                <!-- Contacted -->
                <div>
                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>Contacted</mat-label>
                    <input matInput formControlName="contacted" [matDatetimepicker]="dts" required [max]="maxDate"
                      autocomplete="false">
                    <mat-datetimepicker-toggle [for]="dts" matSuffix></mat-datetimepicker-toggle>
                    <mat-datetimepicker touchUi #dts type="datetime" twelvehour="true" timeInterval="1">
                    </mat-datetimepicker>
                  </mat-form-field>
                </div>

                <div fxHide>
                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>Service Categories</mat-label>
                    <mat-select [(ngModel)]="selectedValue1" formControlName="servicecategory" name="category">
                      <mat-option *ngFor="let category of allCategories" [value]="category">
                        {{category}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxFlex fxHide>
                  <div fxFlex="48" fxFlex.lt-sm="100">
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>Aadhar Card Number</mat-label>
                      <input formControlName="aadharcard" matInput placeholder="">
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!-- Buttons -->
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button mat-raised-button routerLink="/admin/leads">Cancel</button>
                <button mat-raised-button color="primary" (click)="addLead()">Save</button>
              </div>
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-card>
</div>