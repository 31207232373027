import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonLayoutComponent } from 'src/app/layout/common-layout/common-layout.component';
import { LayoutsModule } from 'src/app/layout/layout.module';
import { AuthGuard } from 'src/app/shared/helpers/auth.guard';
import { AddQuotationComponent } from '../admin/quotation/add-quotation/add-quotation.component';
import { QuotationComponent } from '../admin/quotation/quotation.component';
import { UpdateQuotationComponent } from '../admin/quotation/update-quotation/update-quotation.component';
import { EmployeeDashboardComponent } from './employee-dashboard/employee-dashboard.component';
import { AddLeadComponent } from './employee-leads/add-lead/add-lead.component';
import { EmployeeLeadsComponent } from './employee-leads/employee-leads.component';
import { ImportLeadComponent } from './employee-leads/import-lead/import-lead.component';
import { ViewLeadComponent } from './employee-leads/view-lead/view-lead.component';



@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: CommonLayoutComponent,
        children: [
          { path: 'dashboard', component: EmployeeDashboardComponent, pathMatch: 'full', canActivate: [AuthGuard] },
          {
            path: 'leads',
            children: [
              { path: '', component: EmployeeLeadsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'add-lead', component: AddLeadComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'import-lead', component: ImportLeadComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'view-lead', component: ViewLeadComponent, pathMatch: 'full', canActivate: [AuthGuard] },
            ]
          },
          {
            path: 'quotation',
            children: [
              { path: '', component: QuotationComponent, pathMatch: 'full', canActivate: [AuthGuard] },
              { path: 'add-quotation', component: AddQuotationComponent, pathMatch: 'full' },
              { path: 'edit-quotation', component: UpdateQuotationComponent, pathMatch: 'full' },
            ]
          },
        ],
      },
    ]),
    LayoutsModule
  ],
  exports: [RouterModule],
})
export class EmployeeRoutingModule { }
