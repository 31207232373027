import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeeListService } from 'src/app/shared/services/adminpanel/employee-list.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-delete-employee',
  templateUrl: './delete-employee.component.html',
  styleUrls: ['./delete-employee.component.scss']
})
export class DeleteEmployeeComponent implements OnInit {

  local_data: any;
  action: any;

  constructor(
    public dialogRef: MatDialogRef<DeleteEmployeeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DeleteEmployeeComponent,
    public matSnack: SnackbarService,
    private EmployeeListService: EmployeeListService
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  ngOnInit(): void {
  }

  // Close dialog
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  //Delete Employee api call
  reject() {
    // console.log(this.local_data.userId)
    this.EmployeeListService.DeleteEmployee(`api/Auth/DeleteEmployee`, `${this.local_data.userId}`)
      .subscribe((res) => {
        this.dialogRef.close({ data: res });
        this.matSnack.showNotification1("Employee Deleted", "")
      });
  }

}
