import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { AddQuotationService } from 'src/app/shared/services/adminpanel/add-quotation.service';
import { DeleteQuotationComponent } from './delete-quotation/delete-quotation.component';
export interface quotation {
  proposal: string;
  subject: string;
  to: string;
  total: string;
  date: string;
  opentill: string;
  datecreated: string;
  status: string;
}
interface Number {
  value: string;
  viewValue: string;
}

const quotation_data: quotation[] = [
  { proposal: 'QUA-000001', subject: 'Digital Marketing Proposal', to: 'Amar Kurund', total: '₹59,000.00', date: '05-10-2021', opentill: '12-05-2058', datecreated: '05-10-2021 10:34 PM', status: 'DECLINED' },
];
@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit {
  isLoading;
  displayedColumns: string[] = ['proposal', 'actions', 'subject', 'to', 'total', 'date', 'opentill', 'datecreated', 'status'];
  dataSource_new = new MatTableDataSource<quotation>(quotation_data);
  value
  @ViewChild('scheduledOrdersPaginator') set paginator(pager: MatPaginator) {
    if (pager) this.dataSource_new.paginator = pager;
  }
  numbers: Number[] = [
    { value: 'option1', viewValue: '10' },
    { value: 'option2', viewValue: '15' },
    { value: 'option3', viewValue: '25' },
  ];
  selectedNumber = this.numbers[2].value;

  constructor(
    private router: Router, private addQuotationService: AddQuotationService,
    public dialog: MatDialog,
  ) { }
  ngOnInit() {
    this.dataSource_new.paginator = this.paginator;
    localStorage.removeItem('quotationData')
    this.getQuotationList()
  }

  // Search Filter
  applyFilter(event: Event) {
    // const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource_new.filter = filterValue.trim().toLowerCase();
  }
  getQuotationList() {
    this.isLoading = true;
    this.addQuotationService.getQuotation()
      .subscribe((data) => {
        this.isLoading = false;
        console.log(data)
        // this.employee = data;
        this.dataSource_new.data = data
      });
  }
  // add quotation
  addQuotation() {
    this.router.navigateByUrl('admin/quotation/add-quotation');
  }

  //Edit Quotation
  EditQuotation(element) {
    localStorage.setItem('quotationData', element.quotationId)
    console.log(element)
    this.router.navigateByUrl('admin/quotation/edit-quotation');
  }

  //Delete Quotation
  DeleteQuotation(element) {
    console.log(element)
    const dialogRef = this.dialog.open(DeleteQuotationComponent, {
      height: 'auto',
      width: '30%',
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getQuotationList()

    });
  }

  //Edit Quotation
  viewQuotation(element) {
    // console.log(element)
    localStorage.setItem('quotationData', element.quotationId)

    this.router.navigateByUrl('pages/quotation');
  }
  resetFilters() {

    this.value = ''
    this.dataSource_new.filter = "";
  }
}
