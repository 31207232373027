// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // prod
  // apiUrl: 'https://e2ykjrud28.execute-api.ap-south-1.amazonaws.com/Prod',
  // Url: 'https://e2ykjrud28.execute-api.ap-south-1.amazonaws.com/Prod'

  // dev
  // apiUrl: 'http://13.126.51.87',
  // Url: 'http://13.126.51.87'
  apiUrl: 'https://opticscrm.azurewebsites.net',
  Url: 'https://opticscrm.azurewebsites.net'


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
