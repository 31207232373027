<div fxLayout="column" id="top" class="cardpadding">
    <mat-card fxLayout="column" fxLayoutGap="10px">
        <div class="color-primary backgroud-color-white  ">
            <div fxLayout="row" fxLayoutAlign="space-between">

                <p class="mat-title">Email Template
                </p>

            </div>
        </div>
        <div>
            <mat-divider>
            </mat-divider>
        </div>
        <div class="cardpadding template">
            <form [formGroup]="contentFormGroup">
                <!-- <ckeditor [editor]="Editor" formControlName="content" [(ngModel)]="this.htmlContent"
                    (ready)="onReady($event)" [config]="optionsNews" width='100%' height='500px'
                    style="font-family:'Lato', 'Lato'">
                </ckeditor>-->
                <ckeditor [editor]="Editor" formControlName="content" (ready)="onReady($event)" [config]="optionsNews" [(ngModel)]="this.body"
                    width='100%' height='500px' style="font-family:'Lato', 'Lato'">
                </ckeditor>

            </form>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
            <button mat-raised-button routerLink="/admin/email/Template">Back</button>
            <button mat-raised-button (click)="submit()" color="primary">Submit</button>
            <button mat-raised-button routerLink="/admin/email/view-template">View
                Template</button>
        </div>
    </mat-card>
</div>