import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddQuotationService } from 'src/app/shared/services/adminpanel/add-quotation.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { EditQuotationComponent } from '../edit-quotation/edit-quotation.component';

@Component({
  selector: 'app-delete-quotation',
  templateUrl: './delete-quotation.component.html',
  styleUrls: ['./delete-quotation.component.scss']
})
export class DeleteQuotationComponent implements OnInit {
  local_data: any;
  action: any;

  constructor(public dialogRef: MatDialogRef<DeleteQuotationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DeleteQuotationComponent,
    private quotationService:AddQuotationService,public matSnack: SnackbarService,) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  ngOnInit(): void {
  }

  //Close dialog
  closeDialog() {
    console.log(this.local_data.id)
    this.dialogRef.close({ data: 'Close' });

  }

  //Delete Quotation api
  reject() {
    // this.dialogRef.close({ data: this.local_data });

   
    this.quotationService.DeleteQuotationItem(`api/Quotation/quotationDelete`,`${this.local_data.quotationId}`)
    .subscribe((res) => {
      this.dialogRef.close({ data: res });
      this.matSnack.showNotification1("Quotation Deleted", "")
    });
  }
}
