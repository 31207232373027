import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map, first } from 'rxjs/operators';
import { DeleteNotesComponent } from 'src/app/components/admin/leads/view-lead/notes/delete-notes/delete-notes.component';
import { NotesComponent } from 'src/app/components/admin/leads/view-lead/notes/notes.component';
import { DeleteReminderComponent } from 'src/app/components/admin/leads/view-lead/set-reminder/delete-reminder/delete-reminder.component';
import { SetReminderComponent } from 'src/app/components/admin/leads/view-lead/set-reminder/set-reminder.component';
import { Employee } from 'src/app/shared/models/adminpanel/employee';
import { Leads } from 'src/app/shared/models/adminpanel/leads/leads';
import { Notes } from 'src/app/shared/models/adminpanel/leads/notes';
import { Reminders } from 'src/app/shared/models/adminpanel/leads/reminders';
import { LeadsService } from 'src/app/shared/services/adminpanel/leads/leads.service';
import { NotesService } from 'src/app/shared/services/adminpanel/leads/notes.service';
import { RemindersService } from 'src/app/shared/services/adminpanel/leads/reminders.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SelectionModel } from '@angular/cdk/collections';
import { EmployeeListService } from 'src/app/shared/services/adminpanel/employee-list.service';
import { CONSTANT } from 'src/app/shared/utils/constant';
@Component({
  selector: 'app-view-lead',
  templateUrl: './view-lead.component.html',
  styleUrls: ['./view-lead.component.scss']
})
export class ViewLeadComponent implements OnInit {

  local_data: any;
  action: any;
  shows = [
    { value: 'I got in touch with this lead' },
    { value: 'I have not contacted this lead' }
  ];
  touch: boolean = false;
  displayedColumns: string[] = ['description', 'actions', 'date', 'remind', 'isnotify'];
  displayedColumns1: string[] = ['note', 'actions', 'date'];
  dataSource = new MatTableDataSource<Reminders>([]);
  dataSource1 = new MatTableDataSource<Notes>([]);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator1: MatPaginator;
  show: boolean = false;
  public variables = ['Parth Shah', 'Tushar Panchal', 'Yash Shah', 'Jay Patel', 'Dhruv Boghani', 'pre'];
  filteredList1;
  viewLead: string;
  editLead: string;
  isLoading;

  public variables2 = CONSTANT.status;
  public filteredList2 = this.variables2.slice();
  public variables3 = ['Demo', 'Facebook', 'calling'];
  public filteredList3 = this.variables3.slice();
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredtags: Observable<string[]>;
  tags: string[] = [];
  alltags: string[] = ['Demo', 'SEO', 'CRM', 'CRM demo', 'Test', '12'];
  allLanguages: string[] = (CONSTANT.languages);
  allCategories: string[] = ['Portfolio Management', 'Financial Planning'];
  selectedValue: any;
  selectedValue1: any;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  selection = new SelectionModel<Reminders>(true, []);
  showEditnotes: boolean = false;
  leadData: any;
  tagsLead: any;
  LeadName: any;
  UpdateLeadForm: FormGroup;
  setReminderGroup: FormGroup;
  addNotesGroup: FormGroup;
  updateNotesGroup: FormGroup;
  reminderData: any;
  sendMail: any;
  contact_date: any;
  notesData: any;
  editnotesdata: any;
  LeadEmail: any;
  LeadPhone: any;
  LeadWhatsapp: any;
  LeadValue: any;
  LeadCompany: any;
  LeadAddress: any;
  LeadCity: any;
  LeadState: any;
  LeadCountry: any;
  LeadZip: any;
  LeadDescription: any;
  LeadStatus: any;
  LeadLanguage: any;
  LeadAssigned: any;
  LeadTages: any;
  LeadCreated: any;
  LeadContacted: any;
  LeadPosition: any;
  LeadWebsite: any;
  LeadSorces: any;
  LeadServiceCategory: any;
  LeadAadharcard: any;
  minDate = new Date();
  maxDate = new Date();
  selectedRadio: any;
  employee: Employee[];
  filteredListEmployee: any;
  LeadAssignedId: any;
  assignedChangeName: any;
  assignedChangeUserId: any;

  constructor(
    public dialog: MatDialog,
    private LeadService: LeadsService,
    private LeadReminderServices: RemindersService,
    private LeadNotesServices: NotesService,
    private matSnack: SnackbarService,
    private router: Router,
    private datePipe: DatePipe,
    private EmployeeListService: EmployeeListService
  ) {
    this.filteredtags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => tag ? this._filter(tag) : this.alltags.slice()));
  }

  ngOnInit(): void {
    this.local_data = JSON.parse(localStorage.getItem('leadData'));

    this.UpdateLeadForm = new FormGroup({
      name: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      position: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      website: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      zip: new FormControl('', Validators.required),
      whatsappnumber: new FormControl('', Validators.required),
      leadvalue: new FormControl('', Validators.required),
      company: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      contacted: new FormControl('', Validators.required),
      aadharcard: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
      sources: new FormControl('', Validators.required),
      assigned: new FormControl('', Validators.required),
      language: new FormControl('', Validators.required),
      servicecategory: new FormControl('', Validators.required),
      tagCtrl: new FormControl(''),
    })

    this.setReminderGroup = new FormGroup({
      datenotify: new FormControl('', Validators.required),
      sendto: new FormControl('',Validators.required ),
      // Validators.required
      description: new FormControl('', Validators.required),
      sendmail: new FormControl(''),

    })

    this.addNotesGroup = new FormGroup({
      notes: new FormControl(''),
      dataofnotes: new FormControl(''),
      options: new FormControl('')
    })

    this.selectedRadio = this.shows[1].value;
    this.dataSource.paginator = this.paginator;
    this.dataSource1.paginator = this.paginator1;
    this.getLeadDataByid(this.local_data.leadId)
    this.getReminderDataByid(this.local_data.leadId)
    this.getNotesDataByid(this.local_data.leadId)
    this.getEmployee()
    this.editLead = localStorage.getItem('edit-lead');
    this.viewLead = localStorage.getItem('view-lead');
  }


  //setreminderform controls
  get srlf() { return this.setReminderGroup.controls; }

  //addnotesform controls
  get anlf() { return this.addNotesGroup.controls; }

  //updateleadform controls
  get alf() { return this.UpdateLeadForm.controls; }

  //Change option for notes contacted date add
  changeOption(event) {
    // console.log(event.value)
    if (event.value === 'I got in touch with this lead') {
      this.touch = true;
    } else {
      this.touch = false;
    }
  }


  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  //Show reminder section
  SetReminder() {
    this.show = true
  }

  //Hide reminder section
  closeSetRemind() {
    this.show = false
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.alltags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }


  handleMouseOver(row) {
    const position = row;
    this.dataSource.data.map((data: any) => {
      if (data === position) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const position = row;
    this.dataSource.data.map((data: any) => {
      if (data === position) {
        data.show = false;
      }
    });
  }

  handleMouseOver1(row) {
    const position = row;
    this.dataSource1.data.map((data: any) => {
      if (data === position) {
        data.show = true;
      }
    });
  }


  handleMouseLeave1(row) {
    const position = row;
    this.dataSource1.data.map((data: any) => {
      if (data === position) {
        data.show = false;
      }
    });
  }

  //Edit reminder popup
  editReminder(obj) {
    const dialogRef = this.dialog.open(SetReminderComponent, {
      height: 'auto',
      width: '60%',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getReminderDataByid(result.data.leadId)
    });
  }

  //Edit notes Popup
  editnotes(obj) {
    const dialogRef = this.dialog.open(NotesComponent, {
      height: 'auto',
      width: '40%',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getNotesDataByid(this.local_data.leadId)
    });
  }

  //Get lead data by id
  getLeadDataByid(id) {
    this.isLoading= true;
    this.LeadService
      .getLeaddatabyid<Leads>(`api/lead/ViewLead?leadId=${id}`)
      .subscribe((data: Leads[]) => {
        this.isLoading= false;
        this.leadData = data;
        this.tags = this.leadData.tags.split(',')
        this.LeadName = this.leadData.name;
        this.LeadEmail = this.leadData.email;
        this.LeadPhone = this.leadData.phone;
        this.LeadWhatsapp = this.leadData.whatsappnumber;
        this.LeadValue = this.leadData.leadvalue;
        this.LeadCompany = this.leadData.CompanyName;
        this.LeadAddress = this.leadData.address;
        this.LeadCity = this.leadData.city;
        this.LeadState = this.leadData.state;
        this.LeadCountry = this.leadData.country;
        this.LeadZip = this.leadData.zip;
        this.LeadDescription = this.leadData.description;
        this.LeadStatus = this.leadData.Status;
        this.LeadLanguage = this.leadData.language;
        this.LeadAssigned = this.leadData.assigned;
        this.LeadAssignedId = this.leadData.assigneuserid;
        this.LeadCreated = this.leadData.created_at;
        this.LeadContacted = this.leadData.date_contact;
        this.contact_date = new Date(this.leadData.date_contact);
        this.LeadPosition = this.leadData.position;
        this.LeadWebsite = this.leadData.website;
        this.LeadSorces = this.leadData.sources;
        this.LeadServiceCategory = this.leadData.service_category;
        this.LeadAadharcard = this.leadData.aadharcard_no;
      });
  }

  /*
   Get employee list api called
 */
  getEmployee() {
    this.EmployeeListService.getEmployee()
      .subscribe((data: Employee[]) => {
        this.employee = data;
        // this.variables = this.employee.find(x => x.name)
        this.filteredListEmployee = data
        this.filteredList1 = this.filteredListEmployee.slice()
      });
  }
  

  //join symptomps function
  joinsymptomps(arrayValue) {

    var StringValue = arrayValue
    return Array.prototype.map.call(StringValue, function (item) {
      return item;
    }).join(",");

  }

  AssignedEmployee(event) {
    this.assignedChangeName = event.source.triggerValue;
    this.assignedChangeUserId = event.value;
  }

  //Update lead data by id
  updateLead() {
    var assignedName = this.assignedChangeName ? this.assignedChangeName : this.LeadAssigned
    var assigneduserId = this.assignedChangeUserId ? this.assignedChangeUserId : this.LeadAssignedId
    this.LeadService.UpdateLead(
      `api/lead/UpdateLead`,
     
      this.leadData.leadId,
      this.alf.name.value,
      this.alf.company.value,
      this.alf.email.value,
      this.alf.phone.value,
      this.alf.whatsappnumber.value,
      this.alf.leadvalue.value,
      this.joinsymptomps(this.tags),
      this.LeadAssignedId,
      this.LeadAssigned,
      this.alf.status.value,
      this.alf.address.value,
      this.alf.city.value,
      this.alf.state.value,
      this.alf.country.value,
      this.alf.zip.value,
      this.alf.language.value,
      this.alf.description.value,
      this.alf.contacted.value,
      this.alf.sources.value,
      '',
      this.alf.website.value,
      '',
      '',

    )
      .pipe(first())
      .subscribe(
        data => {
          console.log(data)
          this.router.navigateByUrl('/employee/leads')
          this.matSnack.showNotification("Lead Updated", "")
        },

        error => {
          console.log(error.error)
        });

  }

  //Get Reminder data by id
  getReminderDataByid(id) {
    this.LeadReminderServices
      .getReminderdatabyid<Reminders>(`api/lead/ListOfLeadReminders?leadId=${id}`)
      .subscribe((data: Reminders[]) => {
        if (data || data != null) {

          this.reminderData = data

          this.dataSource.data = data
        }
      });
  }

  //Get Notes data by id
  getNotesDataByid(id) {
    this.LeadNotesServices
      .getNotesdatabyid<Notes>(`notes/${id}`)
      .subscribe((data: Notes[]) => {
        if (data || data != null) {
          this.notesData = data
          this.dataSource1.data = data
        }
      });
  }

  showOptions(event) {
    console.log(event.value)
    this.sendMail = event.value
  }

  //Add reminder
  AddLeadReminder() {

    this.LeadReminderServices.AddLeadReminder(
      this.leadData.leadId,
      null,
      this.srlf.datenotify.value,
      this.srlf.sendto.value,
      this.srlf.description.value,
      this.srlf.sendmail.value,
    )
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          this.closeSetRemind();
          this.getReminderDataByid(this.local_data.leadId);
          this.matSnack.showNotification("Reminder Added", "");
          this.setReminderGroup.reset();
        },

        error => {
          console.log(error.error)
        });
  }

  //Add Notes
  addNotes() {
    this.LeadNotesServices.AddLeadNotes(
      this.leadData.leadId,
      this.anlf.notes.value,
      this.anlf.dataofnotes.value
    )
      .pipe(first())
      .subscribe(
        data => {
          this.matSnack.showNotification("Notes Added", "")
          this.getNotesDataByid(this.local_data.leadId)
          this.addNotesGroup.reset();
          this.touch = false
          this.selectedRadio = this.shows[1].value;
        },

        error => {
          console.log(error.error)
        });
  }


  //Close notes section
  closeNotes() {
    this.showEditnotes = false
  }

  //Delete reminder
  deleteReminder(element) {
    const dialogRef = this.dialog.open(DeleteReminderComponent, {
      height: 'auto',
      width: '30%',
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getReminderDataByid(this.local_data.leadId)
    });
  }

  //Delete notes
  deleteNotes(element) {
    const dialogRef = this.dialog.open(DeleteNotesComponent, {
      height: 'auto',
      width: '30%',
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getNotesDataByid(this.local_data.leadId)
    });
  }

}
