<div fxLayout="column" id="top" class="cardpadding">
  <mat-card fxLayout="column" fxLayoutGap="20px">
    <div class="color-primary backgroud-color-white  ">
      <!-- <div fxLayout="row" fxLayoutAlign="space-between">
        <p class="mat-title">Contact list</p>
      </div> -->
      <div fxLayout="row" fxLayoutAlign="space-between">

        <p class="mat-title">Contact list
        </p>
        <button mat-raised-button color="primary" routerLink="/admin/email">
          <mat-icon class="cursor-pointer">arrow_back</mat-icon> Back
        </button>
      </div>
    </div>

    <div class="employee-list ">
      <table mat-table [dataSource]="this.dataSource" class="mat-elevation-z8">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox color="primary" (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> #</th>
          <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
        </ng-container>

        <!-- Image Column -->
        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.userImage === 'assets/images/user-placeholder.jpg' || element.userImage === null">
              <img src="assets/images/user-placeholder.jpg" alt="" class="profile-image">
            </div>
            <div *ngIf="element.userImage != 'assets/images/user-placeholder.jpg' && element.userImage != null">
              <img [src]="'data:image/png;base64,' + element.userImage" alt="" class="profile-image">
            </div>
          <td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element">
            <div fxLayout="column" fxLayoutGap="5px">
              <p>{{element.firstName}} {{element.lastName}}</p>

            </div>

          <td>
        </ng-container>


        <!-- email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>
            Email
            <!-- <a class="fa fa-envelope e-color email e-color-hover "></a> -->
          </th>
          <td mat-cell *matCellDef="let element">
            <!-- {{element.email}} -->
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <a class="fa fa-envelope e-color email e-color-hover " href="mailto: {{element.email}}"
                target="_blank"></a>

              <!-- <a class="color-primary" href="mailto: {{element.email}}">{{element.email}}</a> -->
            </div>
          <td>
        </ng-container>

        <!-- phone Column -->
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef> Phone </th>
          <td mat-cell *matCellDef="let element">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <a href="tel:{{element.phone}}" class="phone l-color"><i class="fa fa-phone"></i></a>
              <!-- <a class="color-primary" href="tel:{{element.phone}}">{{element.phone}}</a> -->
            </div>
          <td>
        </ng-container>

        <!-- whatsappno Column -->
        <ng-container matColumnDef="whatsappno">
          <th mat-header-cell *matHeaderCellDef>
            <!-- <a class="whatsapp" id="whatsapp-btn"><i  class="fa fa-whatsapp"></i></a>  -->
          </th>
          <td mat-cell *matCellDef="let element">
            <!-- {{element.whatsappnumber}} -->
            <a href="//api.whatsapp.com/send?phone=91{{element.phone}}&text={{element | json}}" class="whatsapp"
              id="whatsapp-btn" target="_blank"><i class="fa fa-whatsapp"></i></a>
          <td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (mouseover)="handleMouseOver(row)"
          (mouseleave)="handleMouseLeave(row)"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
      <mat-card *ngIf="isLoading" class="spinner" fxLayoutAlign="space-around center">
        <mat-progress-spinner mode="indeterminate" diameter="50" color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </mat-card>
    </div>
  </mat-card>
</div>