import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { CONSTANT } from 'src/app/shared/utils/constant';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  hide = true;
  hidecon = true;
  changeFormGroup: FormGroup;
  txtEmail: any;
  txtPassword: any;
  error: string;
  password: any;
  c_password: any;
  passwordrequired = (CONSTANT.passwordrequired)
  passwordinvalid = (CONSTANT.passwordinvalid)
  cpasswordrequired = (CONSTANT.cpasswordrequired)
  cpasswordinvalid = (CONSTANT.cpasswordinvalid)
  email: string;
  emailNew;
  tokenNew;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    public snackbar: SnackbarService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.changeFormGroup = this.fb.group({
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(CONSTANT.passwordPattern)

      ]),
      c_password: new FormControl('', [
        Validators.required,
        Validators.pattern(CONSTANT.passwordPattern),
        this.checkPasswords()
      ]),
    });
    this.password = this.changeFormGroup.get('password');
    this.c_password = this.changeFormGroup.get('c_password');
    this.email = localStorage.getItem('email-change')
    this.emailNew = this.route.snapshot.queryParamMap.get('email');
    // console.log(this.emailNew ,"firstParam")
    this.tokenNew = this.route.snapshot.queryParamMap.get('token');
    // console.log( this.tokenNew,"secondParam")

  }

  //Check both password
  checkPasswords(): ValidatorFn {
    //see that the argument is a FormControl
    return (control: FormControl): ValidationErrors => {
      //the formGroup is control.parent
      const group = control.parent as FormGroup;
      //but we must sure that is defined
      if (!group) return null;

      let pass = group.get("password").value;
      let confirmPass = group.get("c_password").value;

      return confirmPass ? pass === confirmPass ? null : { mismatch: true } : null;
    };
  }

  //changepasswordform control
  get cpf() { return this.changeFormGroup.controls; }

  //Change password api call
  changePassword() {
    if (this.changeFormGroup.invalid) {
      return;
    }

    this.authenticationService.ChangePassword(this.emailNew,this.tokenNew,this.cpf.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate(['admin/login']);
          this.snackbar.showNotificationcenter('Password Change Successfully', '')
        });
  }
}
