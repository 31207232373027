import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Employee } from '../../models/adminpanel/employee';

@Injectable({
  providedIn: 'root'
})
export class AddEmployeeService {

  constructor(private http: HttpClient) { }


  //Add Employee api
  AddEmployee(email: string, firstName: string, lastName: string,
    phone: string, faceBook: string,
    linkdIn: string, skype: string, defaultlanguage: string, password: string,
    isQuotationAccess: boolean, isProductAccess: boolean, employee: boolean,
    admin: boolean, userImage) {

    return this.http.post<Employee>(`${environment.apiUrl}/api/auth/RegisterUser`, {
      firstName,
      lastName,
      email,
      phone,
      faceBook,
      linkdIn,
      skype,
      defaultlanguage,
      isQuotationAccess,
      isProductAccess,
      password,
      employee,
      admin,
      userImage
    })
      .pipe(map(res => {
        return res;
      }));
  }

  //Update Employee api
  UpdateEmployee(apiURL: string, userId: string, email: string, firstName: string, lastName: string,
    phone: string, faceBook: string,
    linkdIn: string, skype: string, defaultlanguage: string, password: string,
    isQuotationAccess: boolean, isProductAccess: boolean, employee: boolean,
    admin: boolean, userImage) {

    return this.http.post<Employee>(`${environment.apiUrl}/${apiURL}`, {
      userId,
      firstName,
      lastName,
      email,
      phone,
      faceBook,
      linkdIn,
      skype,
      defaultlanguage,
      isQuotationAccess,
      isProductAccess,
      password,
      employee,
      admin,
      userImage
    })
      .pipe(map(res => {
        return res;
      }));
  }
}
