import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { Employee } from 'src/app/shared/models/adminpanel/employee';
import { EmployeeListService } from 'src/app/shared/services/adminpanel/employee-list.service';
import { RemindersService } from 'src/app/shared/services/adminpanel/leads/reminders.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-set-reminder',
  templateUrl: './set-reminder.component.html',
  styleUrls: ['./set-reminder.component.scss']
})
export class SetReminderComponent implements OnInit {
  public variables = ['Parth Shah', 'Tushar Panchal', 'Yash Shah', 'Jay Patel', 'Dhruv Boghani', 'test user'];
  filteredList1;
  local_data: any;
  action: any;
  group: FormGroup
  reminderdata: any;
  reminderdate: any;
  reminderreminderto: any;
  reminderdescription: any;
  remindersendmail: any;
  employee: any[];

  constructor(
    private LeadReminderServices: RemindersService,
    public dialogRef: MatDialogRef<SetReminderComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: SetReminderComponent,
    private matSnack: SnackbarService,
    private EmployeeListService: EmployeeListService
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  ngOnInit(): void {
    this.reminderdate = new Date(this.local_data.reminderDateTime);
    this.reminderreminderto = this.local_data.reminderTo;
    this.reminderdescription = this.local_data.description;
    if (this.local_data.send_mail === '1') {
      this.remindersendmail = true
    } else {
      this.remindersendmail = false
    }
    this.group = new FormGroup({
      datenotify: new FormControl('', Validators.required),
      sendto: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      sendmail: new FormControl(''),

    })
    this.getEmployee()
  }


  //updateReminderform controls
  get srlf() { return this.group.controls; }

  // Close dialog
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  currentPeriodClicked(event) {
    console.log(event.value)
  }


  //Update reminder
  UpdateLeadReminder() {

    this.LeadReminderServices.UpdateLeadReminder(
      `api/lead/AddLeadReminder`,
      this.local_data.leadId,
      this.local_data.leadReminderId,
      this.srlf.datenotify.value,
      this.srlf.sendto.value,
      this.srlf.description.value,
      this.srlf.sendmail.value,
    )
      .pipe(first())
      .subscribe(
        data => {
          console.log(data)
          this.dialogRef.close({ data: this.local_data.leadId });
          this.matSnack.showNotification("Reminder Updated", "")
          this.getReminderDataByid(this.local_data.leadId)
        },

        error => {
          console.log(error.error)
        });
  }


  /*
 Get employee list api called
*/
  getEmployee() {
    this.EmployeeListService.getEmployee()
      .subscribe((data: Employee[]) => {
        this.employee = data;
        // this.variables = this.employee.find(x => x.name)
        this.filteredList1 = this.employee.map(function (a) { 
          return a["firstName"];
         })
      });
  }
    //Get Reminder data by id
    getReminderDataByid(id) {
     
      this.LeadReminderServices
        .getReminderdatabyid<any>(`api/lead/ListOfLeadReminders?leadId=${id}`)
        .subscribe((data: any[]) => {
         
          if (data || data != null) {
  
          
          }
        });
    }
}
