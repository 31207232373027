import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { StatusService } from 'src/app/shared/services/status.service';
import { AddStatusComponent } from './add-status/add-status.component';

import { DeleteStatusComponent } from './delete-status/delete-status.component';
import { EditStatusComponent } from './edit-status/edit-status.component';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  displayedColumns: string[] = ['position', 'actions',  'name'];
  dataSource = new MatTableDataSource<any>([]);
  isLoading
  @ViewChild('scheduledOrdersPaginator') set paginator(pager:MatPaginator) {
    if (pager) this.dataSource.paginator = pager;
  }
  constructor(
    private router: Router,
    public dialog: MatDialog,
   private statusservice: StatusService
  ) {
  
    
   }

  ngOnInit(): void {
    // this.dataSource.paginator = this.paginator;
    this.getListOfStatus()
  }

  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  getListOfStatus(){
    this.isLoading = true;
    this.statusservice.getListOfStatus().subscribe((data: []) => {
        console.log(data)
        this.dataSource.data = data
        this.isLoading = false;
       
      });
    
  }
  handleMouseOver(row) {
    const position = row;
    this.dataSource.data.map((data: any) => {
      if (data === position) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const position = row;
    this.dataSource.data.map((data: any) => {
      if (data === position) {
        data.show = false;
      }
    });
  }

  editstatus(element) {
    const dialogRef = this.dialog.open(EditStatusComponent,{
      height: 'auto',
      width: '30%',
      data: element
    });
    // dialogRef.afterClosed().subscribe(() => {
      
    // }
    dialogRef.afterClosed().subscribe(() => {
     
      this.getListOfStatus()
      this.getListOfStatus()
      this.getListOfStatus()
      
    
   
    });


  }

  //Add lead page route
  addstatus() {
    const dialogRef = this.dialog.open(AddStatusComponent,{
      height: 'auto',
      width: '30%'
      
    });
    // dialogRef.afterClosed().subscribe(() => {
      
    // }
    dialogRef.afterClosed().subscribe(() => {
   
      this.getListOfStatus()
      this.getListOfStatus()
      this.getListOfStatus()
      
  
     
    
   
    });

  }


  deletestatus(element) {
    const dialogRef = this.dialog.open(DeleteStatusComponent,{
      height: 'auto',
      width: '30%',
      data: element
    });
    // dialogRef.afterClosed().subscribe(() => {
      
    // }
    dialogRef.afterClosed().subscribe(() => {
     
      this.getListOfStatus()
      this.getListOfStatus()
      this.getListOfStatus()
      this.getListOfStatus()
     
    
   
    });

  }


}
