<div class="color-white backgroud-color-primary cardpadding">
    <div fxLayout="row" fxLayoutAlign="space-between">

        <p class="mat-title">Notes
        </p>
        <mat-icon class="cursor-pointer" (click)="closeDialog()">close</mat-icon>
    </div>
</div>

<div class="cardpadding datetimepicker" fxLayout="column" fxLayoutGap="10px">
    <form [formGroup]="updateNotesGroup">
        <!-- Add notes -->
        <div fxLayout="column">
            <p class=" color-primary">Note</p>
            <mat-form-field appearance="outline" class="full-width">
                <textarea formControlName="updatenotes" [(ngModel)]="this.notesData" matInput></textarea>
            </mat-form-field>
        </div>
    </form>

    <!-- Buttons -->
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <button mat-raised-button (click)="closeDialog()">Cancel</button>
        <button mat-raised-button color="primary" (click)="UpdateNotes()">Save</button>
    </div>
</div>