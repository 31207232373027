import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LeadsService } from 'src/app/shared/services/adminpanel/leads/leads.service';
import { Leads } from 'src/app/shared/models/adminpanel/leads/leads';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { DeleteLeadComponent } from './delete-lead/delete-lead.component';
import { Employee } from 'src/app/shared/models/adminpanel/employee';
import { EmployeeListService } from 'src/app/shared/services/adminpanel/employee-list.service';
import { MatOptionSelectionChange } from '@angular/material/core';
import { CONSTANT } from 'src/app/shared/utils/constant';
import { StatusremarksComponent } from './statusremarks/statusremarks.component';
import { StatusService } from 'src/app/shared/services/status.service';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})

export class LeadsComponent implements OnInit {
  public variables = ['Parth Shah', 'Tushar Panchal', 'Yash Shah', 'Jay Patel', 'Dhruv Boghani', 'pre'];
  // public filteredList1 = this.variables.slice();
  searchText: string = "";
  filteredList1
  isLoading;
  statusdata
  public variables2 = CONSTANT.status;
  // = this.variables2.slice();
  public filteredList2
  toppings = new FormControl(this.variables2);
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  pageIndex = 1;
  pageSizeOptions: number[] = [5, 10, 25];
  displayedColumns: string[] = ['checkbox', 'position', 'name', 'actions', 'company', 'email', 'phone', 'whatsappno', 'leadvalue', 'tags', 'source', 'assigned', 'status'];
  dataSource = new MatTableDataSource<Leads>([]);
  selection = new SelectionModel<Leads>(true, [])
  value;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // @ViewChild('scheduledOrdersPaginator') set paginator(pager: MatPaginator) {
  //   if (pager) this.dataSource.paginator = pager;
  // }
  // ;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  // readMore = false;
  // longText = `This is long paragraph text contain sevaral words continued. An example for implementing dyanmically limit long text`;
  color: any;
  Leads: any;
  employee: any;
  filterSelectObj = [];
  filteredListEmployee: any;
  shownew = false
  currentUser: any;
  filterValueSearch: any;
  filterValueStatus: any;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private LeadServices: LeadsService,
    private EmployeeListService: EmployeeListService,
    private matSnack: SnackbarService,
    private statusservice: StatusService,
    private changeDetectorRefs: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // this.dataSource.data = this.dataList
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.getLeads();
    this.getEmployee();
    this.getListOfStatus()
    localStorage.removeItem('edit-lead');
    localStorage.removeItem('view-lead');
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.changeDetectorRefs.detectChanges();
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    console.log(this.paginator)
  }

  getListOfStatus() {
    this.isLoading = true;
    this.statusservice.getListOfStatus().subscribe((data: any) => {
      if (data) {


        this.filteredList2 = data

        this.variables2 = data
        // console.log(this.filteredList2.statusName)
        this.isLoading = false;
      }


    });

  }
  handleMouseOver(row) {
    const position = row;
    this.dataSource.data.map((data: any) => {
      if (data === position) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const position = row;
    this.dataSource.data.map((data: any) => {
      if (data === position) {
        data.show = false;
      }
    });
  }

  viewLead(event) {
    localStorage.setItem('leadData', JSON.stringify(event))
    localStorage.setItem('view-lead', 'View-Lead')
    this.router.navigateByUrl('admin/leads/view-lead', { state: event });
  }

  editLead(event) {
    localStorage.setItem('leadData', JSON.stringify(event))
    localStorage.setItem('edit-lead', 'Edit-Lead')
    this.router.navigateByUrl('admin/leads/view-lead', { state: event });
  }

  //Add lead page route
  addLead() {
    this.router.navigateByUrl('admin/leads/add-lead');
  }

  //Import lead page route
  importLead() {
    this.router.navigateByUrl('admin/leads/import-lead');
  }

  importLeadAssigned() {
    this.router.navigateByUrl('admin/leads/import-lead-assign');
  }

  applyFilter(event: Event) {
    console.log((event.target as HTMLInputElement).value)
    this.filterValueSearch = event
    var filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    this.LeadServices.getLeaddatabyid<any>(`api/lead/FilterByKeyword?keyWord=${filterValue}&pageno=${this.currentPage}&pagesize=${this.pageSize}`)
      .subscribe((data: any) => {
        // this.isLoading = false;
        this.Leads = data.result.leadList;
        this.dataSource.data = data.result.leadList
        console.log(data.result.leadList)
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = data.result.count;
        // this.dataSource.paginator=data
      });
    if ((event.target as HTMLInputElement).value === '') {
      this.getLeads()
    }
  }
  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    console.log(this.filterValueSearch)
    console.log(this.filterValueStatus)
    if(this.filterValueSearch != undefined){

      this.applyFilter(this.filterValueSearch);
    }
    if(this.filterValueStatus != undefined){

      this.someMethod1(this.filterValueStatus)
    }
    if(this.filterValueSearch == undefined &&this.filterValueStatus == undefined){
    this.getLeads()}
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.Leads.forEach(row =>
        this.selection.select(row)
      );
  }

  logSelection() {
    this.selection.selected.forEach(s => console.log(s.name));
  }

  someMethod(event) {
    console.log(event.statusName)
    const filterValue = event;
    // this.dataSource.filter = filterValue.trim().toLowerCase();

    console.log(this.dataSource)
  }
  someMethod1(event) {

    console.log("event", event.statusName)
    this.filterValueStatus = event
    var filterValue = event.statusName;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    // this.isLoading = true;
    this.LeadServices.getLeaddatabyid<any>(`api/lead/FilterByStatus?status=${filterValue}&pageno=${this.currentPage}&pagesize=${this.pageSize}`)
      .subscribe((data: any) => {
        // this.isLoading = false;
        console.log(data)
        this.Leads = data.result.leadList;
        this.dataSource.data = data.result.leadList
        console.log(data.result.leadList)
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = data.result.count;
        // this.dataSource.paginator=data
      });
    console.log(event)
  }
  openremarksDialog(value, id) {

    const dialogRef = this.dialog.open(StatusremarksComponent, {
      height: 'auto',
      width: '30%',
      data: { id: id, value: value }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getLeads()
      this.getLeads()
      this.getLeads()
      // this.ChangeStatus(event, status)
    });
  }

  /*
    Get Leads list api called
  */
  getLeads() {
    this.isLoading = true;
    this.LeadServices.getLeadswithpagination(`api/lead/LeadList?pageno=${this.currentPage}&pagesize=${this.pageSize}`)
      .subscribe((data: any) => {
        this.isLoading = false;
        this.Leads = data;
        this.dataSource.data = data

        setTimeout(() => {
          console.log(this.paginator)
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = data.count;
        });

        // this.dataSource.paginator=data
      });
  }


  //Change status of lead
  ChangeStatus(event, status) {
    console.log(".subscribe((res", status)
    this.openremarksDialog(event.value, status.leadId)
    // this.LeadServices.ChangeLeadStatus(`leadstatus/${status.id}`, event.value)
    //   .subscribe((res) => {
    //     console.log(res)
    //     this.matSnack.showNotification("Status Updated", "")
    //   });
  }

  /*
    Delete Leads Popup
  */
  deleteLead(element) {
    const dialogRef = this.dialog.open(DeleteLeadComponent, {
      height: 'auto',
      width: '30%',
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getLeads()
      this.getLeads()
    });

  }


  /*
   Get employee list api called
 */
  getEmployee() {
    this.EmployeeListService.getEmployee()
      .subscribe((data: Employee[]) => {
        this.employee = data;
        // this.variables = this.employee.find(x => x.name)
        this.filteredListEmployee = data
        // console.log("fgfd",this.filteredListEmployee)
        this.filteredList1 = this.filteredListEmployee.slice()
      });
  }



  //Assign Employee of lead
  AssignedEmployee(event, status) {



    this.LeadServices.AssignedEmployee(`api/lead/AssignUser`, status.leadId, event.value)
      .subscribe((res) => {
        // console.log(event.value)
        console.log(res)
        this.matSnack.showNotification("Assign Employee Updated", "")
      });
  }

  resetFilters() {

    this.value = ""
    this.dataSource.filter = "";
  }
  trackBy = (_index: number, item: any) => {


    return item.leadId;
  }
  isListExpanded(list) {
    return this.shownList === list;
  };
  shownList = null;
  toggleList(list) {
    if (this.isListExpanded(list)) {
      this.shownList = null;
    } else {
      this.shownList = list;
    }
  };
}
