import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MailstatusService } from 'src/app/shared/services/adminpanel/mailstatus.service';
import { DeleteDraftComponent } from './delete-draft/delete-draft.component';


@Component({
  selector: 'app-templategalary',
  templateUrl: './templategalary.component.html',
  styleUrls: ['./templategalary.component.scss']
})
export class TemplategalaryComponent {
  [x: string]: any;
  title = 'Templates';
  titleSelected;
  gridColumns = 4;
  changeText: boolean;
  currentPage: any;
  pageSize: any;
  dataSource: any;
  paginator: any;
  isLoading: boolean;
  mailstatus: any
  ListOfEmailData: any
  constructor(
    private MailstatusService: MailstatusService,

    public dialog: MatDialog,
  ) {
    this.changeText = false;
  }
  toggleGridColumns() {
    // this.gridColumns = this.gridColumns === 3 ? 4 : 3;
    this.gridColumns === 4;
  }
  select(title) {
    console.log(title)
    this.titleSelected = title;
  }
  page = 0;
  size = 8;
  data = [];
  data2 = [
    { id: 1, url: 'https://images.freeimages.com/images/large-previews/996/easter-1399885.jpg' },
    { id: 2, url: 'https://images.freeimages.com/images/large-previews/0b3/burning-tree-1377053.jpg' },
    { id: 3, url: 'https://images.freeimages.com/images/large-previews/346/cemetery-1404186.jpg' },
    { id: 4, url: 'https://images.freeimages.com/images/large-previews/310/resting-peacefully-1574880.jpg' },
    { id: 5, url: 'https://images.freeimages.com/images/large-previews/996/easter-1399885.jpg' },
    { id: 6, url: 'https://images.freeimages.com/images/large-previews/996/easter-1399885.jpg' },
    { id: 7, url: 'https://images.freeimages.com/images/large-previews/0b3/burning-tree-1377053.jpg' },
    { id: 8, url: 'https://images.freeimages.com/images/large-previews/346/cemetery-1404186.jpg' },
    { id: 9, url: 'https://images.freeimages.com/images/large-previews/346/cemetery-1404186.jpg' },
    { id: 2, url: 'https://images.freeimages.com/images/large-previews/310/resting-peacefully-1574880.jpg' },
    { id: 3, url: 'https://images.freeimages.com/images/large-previews/346/cemetery-1404186.jpg' },
    { id: 4, url: 'https://images.freeimages.com/images/large-previews/310/resting-peacefully-1574880.jpg' },
    { id: 5, url: 'https://images.freeimages.com/images/large-previews/0b3/burning-tree-1377053.jpg' },
    { id: 6, url: 'https://images.freeimages.com/images/large-previews/996/easter-1399885.jpg' },
    { id: 7, url: 'https://images.freeimages.com/images/large-previews/0b3/burning-tree-1377053.jpg' },
    { id: 8, url: 'https://images.freeimages.com/images/large-previews/346/cemetery-1404186.jpg' }
  ];
  ngOnInit() {
    this.getData({ pageIndex: this.page, pageSize: this.size });
    // this.getListOfemailDraft();
    this.ListEmail()
  }

  getData(obj) {
    let index = 0,
      startingIndex = obj.pageIndex * obj.pageSize,
      endingIndex = startingIndex + obj.pageSize;

    this.data = this.data2.filter(() => {
      index++;
      return (index > startingIndex && index <= endingIndex) ? true : false;
    });
  }


  //  list of email draft
  ListEmail() {
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    this.MailstatusService.getEmaildatabyid<any>(`api/EmailDraft/ListOfEmailDraft`)
      .subscribe((data: any) => {
        console.log(data)
        this.ListOfEmailData = data.data.emailDraft
      });
  }


  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    // this.applyFilter($event);
  }


  getListOfemailDraft() {
    this.isLoading = true;
    this.MailstatusService.getListOfemailDraft()
      .subscribe((data: any) => {
        this.isLoading = false;
        this.mailstatus = data;
        this.dataSource = data.data
        console.log(data)
      });
  }
  editTemplate(image) {
    console.log(image)
    localStorage.setItem('emailDraftId', image.id)
  }
  previewTemplate(image) {
    console.log(image)
    localStorage.setItem('emailDraftId', image.id)
  }
  deleteTemplate(image){
    console.log(image)
    const dialogRef = this.dialog.open(DeleteDraftComponent, {
      height: 'auto',
      width: '30%',
      data: image
    });
    dialogRef.afterClosed().subscribe(result => {
      
      this.ListEmail()
    });
  }
}