<div class="color-white backgroud-color-primary cardpadding">
  <div fxLayout="row" fxLayoutAlign="space-between">
    <p class="mat-title">Delete Product</p>
    <mat-icon class="cursor-pointer" (click)="closeDialog()">close</mat-icon>
  </div>
</div>

<div class="cardpadding" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
  <div fxLayout="column" fxLayoutAlign="center center">
    <p class="mat-h2">Are you sure want to Delete ?</p>
  </div>

  <!-- Buttons -->
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
    <button mat-raised-button mat-dialog-close>No</button>
    <button mat-raised-button color="primary" (click)="Delete()" mat-dialog-close>Yes</button>
  </div>
</div>