import { Component, OnInit, ViewChild } from '@angular/core';

import * as XLSX from 'xlsx';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { LeadsService } from 'src/app/shared/services/adminpanel/leads/leads.service';
import { first } from 'rxjs/operators';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

type AOA = any[][];

@Component({
  selector: 'app-import-lead',
  templateUrl: './import-lead.component.html',
  styleUrls: ['./import-lead.component.scss']
})

export class ImportLeadComponent {

  @ViewChild('fileImportInput', { static: false }) fileImportInput: any;
  csvFile: any;
  csvFileData: any;
  data;
  myForm = new FormGroup({
    name: new FormControl('',),
    file: new FormControl('',),
    fileSource: new FormControl('',)
  });

  constructor(
    private matSnack: SnackbarService,
    private http: HttpClient,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private LeadServices: LeadsService,
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  get f() {
    return this.myForm.controls;
  }

  //File change function
  onFileChange(event) {

    if (event.target.files.length > 0) {
      this.csvFileData = event.target.files[0]
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }

  //Upload excel file api call
  submit() {
    this.spinnerService.show();
    this.LeadServices.ImportLeadsByOutSource('',
      this.data
    ).pipe(first())
      .subscribe(
        data => {
          this.router.navigateByUrl('/admin/leads');
          this.matSnack.showNotification('New Lead Added', '')
          this.spinnerService.hide();
        },
        error => {
          console.log(error.error)
        });

    // this.spinnerService.show();
    // const formData = new FormData();
    // formData.append('ExcelFile', this.myForm.get('fileSource').value);

    // this.http.post('https://e2ykjrud28.execute-api.ap-south-1.amazonaws.com/Prod/api/lead/ImportLead', formData)
    //   .subscribe(res => {
    //     this.router.navigateByUrl('/admin/leads');
    //     this.matSnack.showNotification('New Lead Added', '')
    //     this.spinnerService.hide();
    //   })
  }
  onFileChange1(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    this.csvFileData = target.files[0]
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;

      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      wb.SheetNames.forEach(sheet => {
        let rowObject = XLSX.utils.sheet_to_json(
          wb.Sheets[sheet]
        );
        this.data = rowObject;


      });



    };

    reader.readAsBinaryString(target.files[0]);

  }
  //Export sample file
  export() {
    window.open('/assets/images/sample_import_file.csv', '_blank');
  }
}
