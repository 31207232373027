<div class="cardpadding width-80" align="end" id="top">
    <!-- Button -->
    <button mat-raised-button color="primary" (click)="download()">Download</button>
    <!-- <button mat-raised-button color="accent">Decline</button> -->
</div>
<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">


    <mat-card class="width-60" id="contentToConvert" #contentToConvert>
        <p class="color-primary mat-headline">CRM</p>
        <div fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div fxLayout="column">
                    <div fxLayout="row" fxLayoutAlign="space-between start">
                        <!-- Quotation Number -->
                        <p class="mat-h3">#QUA-{{this.quotationid}}</p>

                    </div>
                    <p>{{this.quotationSubject}}
                    </p>
                </div>
                <div>
                    <div fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="space-between">
                            <p class="summary mat-h4">Status:</p>
                            <p class="summary mat-h4">{{this.quotationStatus}}</p>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between">
                            <p class="summary mat-h4">Date:</p>
                            <p class="summary mat-h4">{{this.quotationDate | date:'dd-MM-yyyy'}}</p>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between">
                            <p class="summary mat-h4">Open Till:</p>
                            <p class="summary mat-h4"> {{this.quotationOpenTill| date:'dd-MM-yyyy'}} </p>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">

                <div>
                    <p class="margin-remove"><b>VirgoSix</b></p>
                    <p class="margin-remove mat-h4">Surat Gujarat</p>
                    <p class="margin-remove mat-h4">395009</p>
                </div>
                <div>
                    <!-- Proposal Information -->
                    <div>
                        <p class="margin-remove"><b>{{this.quotationTo }}</b></p>
                        <p class="margin-remove mat-h4">{{this.quotationAddress}}</p>
                        <p class="margin-remove mat-h4">{{ this.quotationCity }} {{ this.quotationState }}</p>
                        <p class="margin-remove mat-h4">{{this.quotationCountry}} {{this.quotationZipCode}}</p>
                        <p class="margin-remove color-primary mat-h4">{{this.quotationPhone}}</p>
                        <p class="margin-remove color-primary mat-h4">{{this.quotationEmail}}</p>
                    </div>

                </div>

            </div>
            <div class="mat-elevation-z8 quotation-list">
                <table mat-table [dataSource]="dataSource2">
                    <!-- Position Column -->
                    <ng-container matColumnDef="number">
                        <th mat-header-cell *matHeaderCellDef> #</th>
                        <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
                    </ng-container>
                    <!-- Items Column -->
                    <ng-container matColumnDef="items">
                        <th mat-header-cell *matHeaderCellDef> Items </th>
                        <td mat-cell *matCellDef="let element"> {{element.items}} </td>
                    </ng-container>

                    <!-- Qty Column -->
                    <ng-container matColumnDef="qty">
                        <th mat-header-cell *matHeaderCellDef> Qty </th>
                        <td mat-cell *matCellDef="let element"> {{element.qty}} </td>
                    </ng-container>

                    <!-- Rate Column -->
                    <ng-container matColumnDef="rate">
                        <th mat-header-cell *matHeaderCellDef> Rate </th>
                        <td mat-cell *matCellDef="let element"> {{element.rate| currency:'INR'}} </td>
                    </ng-container>

                    <!-- Tax Column -->
                    <ng-container matColumnDef="tax">
                        <th mat-header-cell *matHeaderCellDef> Tax </th>
                        <td mat-cell *matCellDef="let element"> {{element.tax}} </td>
                    </ng-container>

                    <!-- Amount Column -->
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef> Amount </th>
                        <td mat-cell *matCellDef="let element"> {{element.qty * element.rate | currency:'INR'}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>

            <!-- Summary -->
            <div fxLayout="column" class="padding-summary">
                <!-- sub Total -->
                <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px" fxFlex>
                    <p class="mat-h4" fxLayoutGap="10px" fxFlex="10"> <b>Sub total:</b></p>
                    <div fxLayout="row" fxLayoutAlign="center center" fxFlex="10">
                        <p  class="mat-h4"> {{this.subtotal| currency:'INR'}}</p>                    
                    </div>
                </div>
                
                <!-- Discount -->
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" fxFlex>
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="15">
                        <p><b>Discount (%):</b> </p>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start" fxFlex="10">
                        <!-- <p>-</p> -->
                        <!-- <mat-icon class="summary-icon">currency_rupee</mat-icon> -->
                        <!-- <p *ngIf="this.discountAmount === undefined">₹ 0.00</p> -->
                        <p > {{this.discountAmount| currency:'INR' }}</p>
                    </div>
                </div>
                <!-- Taxs -->
                <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px"
                    *ngIf="this.totalTaxAmountCGST > 0" fxFlex="15">
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="15">
                        <p><b>CGST(9.00%):</b></p>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start" fxFlex="10">
                        <!-- <mat-icon class="summary-icon">currency_rupee</mat-icon> -->
                        <p> {{this.totalTaxAmountCGST| currency:'INR'}}</p>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10PX"
                    *ngIf="this.totalTaxAmountSGST > 0"  fxFlex="10">
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="15">
                        <p><b>SGST(9.00%):</b> </p>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start" fxFlex="10">
                        <!-- <mat-icon class="summary-icon">currency_rupee</mat-icon> -->
                        <p>{{this.totalTaxAmountSGST| currency:'INR'}}</p>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px"
                    *ngIf="this.totalTaxAmounteighteenGST > 0" fxFlex>
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="15">
                        <p><b>GST(9.00%):</b> </p>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start" fxFlex="10">
                        <!-- <mat-icon class="summary-icon">currency_rupee</mat-icon> -->
                        <p>{{this.totalTaxAmounteighteenGST| currency:'INR'}}</p>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px"
                    *ngIf="this.totalTaxAmounteighteenIGST > 0" fxFlex>
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="15">
                        <p><b>IGST(18.00%):</b> </p>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start" fxFlex="10">
                        <!-- <mat-icon class="summary-icon">currency_rupee</mat-icon> -->
                        <p> {{this.totalTaxAmounteighteenIGST| currency:'INR'}}</p>
                    </div>
                </div>
                <!-- Adjustment -->
                <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px" fxFlex>
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="15">
                        <p><b>Adjustment:</b> </p>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start" fxFlex="10">
                        <!-- <p *ngIf="this.adjustmentAmount === undefined">₹ 0.00</p> -->
                        <p > {{this.adjustmentAmount| currency:'INR'}}</p>
                    </div>
                </div>
                <div>
                    <mat-divider></mat-divider>
                </div>

                <div>
                    <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
                        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="10">
                            <p class="mat-h4"> <b>Total:</b></p>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="10">
                         
                            <p class="mat-h4">{{this.total| currency:'INR'}} </p>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayoutAlign="center center" class="padding-top" fxHide>
                <p><b>With words: Fifty Nine Thousand</b></p>
            </div>
        </div>
    </mat-card>
</div>