import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Employee } from 'src/app/shared/models/adminpanel/employee';
import { Leads } from 'src/app/shared/models/adminpanel/leads/leads';
import { EmployeeListService } from 'src/app/shared/services/adminpanel/employee-list.service';
import { LeadsService } from 'src/app/shared/services/adminpanel/leads/leads.service';

@Component({
  selector: 'app-view-employee',
  templateUrl: './view-employee.component.html',
  styleUrls: ['./view-employee.component.scss']
})
export class ViewEmployeeComponent implements OnInit {
  isLoading = true;
  EmployeeDatabyid: any;
  EmployeeFirstName: any;
  EmployeeLastName: any;
  EmployeeEmail: any;
  EmployeePhone: any;
  EmployeeFacebook: any;
  EmployeeLinkedin: any;
  EmployeeSkype: any;
  EmployeeLanguage: any;
  EmployeeImage: any;
  EmployeeFullName: any;
  empoloyeeData: any;
  Leads: any;
  displayedColumns: string[] = ['position', 'name', 'company', 'email', 'phone', 'whatsappno', 'leadvalue', 'tags', 'source', 'status'];
  dataSource = new MatTableDataSource<Leads>([]);
  selection = new SelectionModel<Leads>(true, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private EmployeeListService: EmployeeListService,
    private _sanitizer: DomSanitizer,
    private LeadServices: LeadsService,

  ) { }

  ngOnInit(): void {
    this.empoloyeeData = JSON.parse(localStorage.getItem('employeeData'));
    this.getEmployeeDatabyid(this.empoloyeeData.userId)
    this.getLeads(this.empoloyeeData.userId);
  }

  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  /*
   Get employee data by id api called
 */
  getEmployeeDatabyid(id) {
    this.isLoading = true;
    this.EmployeeListService
      .getEmployeedatabyid<Employee>(`/api/auth/EmployeeDetails?empId=${id}`)
      .subscribe((data: Employee[]) => {
        this.isLoading = false;
        console.log(data)
        this.EmployeeDatabyid = data;
        this.EmployeeFullName = this.EmployeeDatabyid.name
        this.EmployeeEmail = this.EmployeeDatabyid.email
        this.EmployeePhone = this.EmployeeDatabyid.phone
        this.EmployeeFacebook = this.EmployeeDatabyid.faceBook
        this.EmployeeLinkedin = this.EmployeeDatabyid.linkdIn
        this.EmployeeSkype = this.EmployeeDatabyid.skype
        this.EmployeeLanguage = this.EmployeeDatabyid.defaultlanguage
        this.EmployeeImage = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
          + this.EmployeeDatabyid.userImage);

        // this.EmployeeImage = this.EmployeeDatabyid.profile_image;
        // this.Employee
      })
  }

  /*
   Get Leads list api called
 */
  getLeads(id) {
    this.isLoading = true;
    this.LeadServices.getEmployeeLeads(`api/lead/LeadList?empId=${id}`)
      .subscribe((data: Leads[]) => {
        this.isLoading = false;
        this.Leads = data;
        console.log(this.Leads, "leads");
        this.dataSource.data = data
      });
  }

  handleMouseOver(row) {
    const position = row;
    this.dataSource.data.map((data: any) => {
      if (data === position) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const position = row;
    this.dataSource.data.map((data: any) => {
      if (data === position) {
        data.show = false;
      }
    });
  }
}
