import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-deleteinfo',
  templateUrl: './deleteinfo.component.html',
  styleUrls: ['./deleteinfo.component.scss']
})
export class DeleteinfoComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<DeleteinfoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DeleteinfoComponent,
    public matSnack: SnackbarService,
  ) { }

  ngOnInit(): void {
  }

// Close dialog
closeDialog() {
  this.dialogRef.close({ event: 'Cancel' });
}

//Delete
reject() {
  
      this.matSnack.showNotification1("Employee Deleted", "")
    
}
}
