import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddQuotationService } from 'src/app/shared/services/adminpanel/add-quotation.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { DeleteQuotationComponent } from '../delete-quotation/delete-quotation.component';
import { EditQuotationComponent } from '../edit-quotation/edit-quotation.component';

@Component({
  selector: 'app-delete-quotation-item',
  templateUrl: './delete-quotation-item.component.html',
  styleUrls: ['./delete-quotation-item.component.scss']
})
export class DeleteQuotationItemComponent implements OnInit {
  local_data: any;
  action: any;
  constructor(public dialogRef: MatDialogRef<DeleteQuotationItemComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DeleteQuotationItemComponent,
    private quotationService:AddQuotationService,public matSnack: SnackbarService,) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
    // console.log(this.local_data,"dtat")
  }

  ngOnInit(): void {
  }

  //Close dialog
  closeDialog() {
    this.dialogRef.close({ data: 'Close' });
  }

  //Delete Quotation api
  reject() {
    var quotationId= this.local_data.quotationId
    if(quotationId != null){
 
    this.quotationService.DeleteQuotationItemOnly(`/api/Quotation/deleteItem`,`${this.local_data.itemId}`)
    .subscribe((res) => {
      this.dialogRef.close({ data: res });
      this.matSnack.showNotification1("Quotation Item Deleted", "")
    });
  }
    if(quotationId == null)
    {
      // console.log("quotation id  null")
      this.dialogRef.close({ data: this.local_data });
    }
    
  }
}
