<div fxLayout="column" id="top" class="cardpadding">
  <mat-card fxLayout="column" fxLayoutGap="10px">
    <div class="color-primary backgroud-color-white  ">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <p class="mat-title">Employee Details
        </p>
        <button mat-raised-button color="primary" routerLink="/admin/employee">
          <mat-icon class="cursor-pointer">arrow_back</mat-icon> Back
        </button>
      </div>
    </div>
    <!-- View section -->
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <!-- Image*ngIf="this.EmployeeImage === 'assets/images/user-placeholder.jpg'" -->
        <div>
          <img src="assets/images/user-placeholder.jpg" alt="" class="profile-image" width="100">
        </div>
        <!-- <div *ngIf="this.EmployeeImage != 'assets/images/user-placeholder.jpg'">
                <img [src]="'data:image/jpg;base64,' + this.EmployeeImage" alt="" class="profile-image" width="100">
            </div> -->
        <!-- Name -->
        <div fxLayout="column">
          <p class="color-primary mat-h2 font-weight-600">{{this.EmployeeFullName}}</p>
        </div>
      </div>
      <mat-tab-group>
        <!-- Profile details tab -->
        <mat-tab>
          <ng-template mat-tab-label>
            Profile
          </ng-template>
          <div class="cardpadding mat-typography" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px"
            fxFlex="grow">
            <div fxLayout="column" fxLayoutGap="10px" fxFlex="32">
              <!-- <p class="color-primary background-color-grey font-weight-600 padding-left"> Information</p> -->
              <div class="padding-left" fxLayout="column" fxLayoutGap="10px">
                <!-- Email -->
                <div fxLayout="column">
                  <p class="color-primary font-weight-600">Email Address</p>
                  <p>{{this.EmployeeEmail}}</p>
                </div>
                <!-- Phone -->
                <div fxLayout="column">
                  <p class="color-primary font-weight-600">Phone</p>
                  <p>{{this.EmployeePhone}}</p>
                </div>
                <!-- Facebook -->
                <div fxLayout="column">
                  <p class="color-primary font-weight-600">Facebook</p>
                  <p>{{this.EmployeeFacebook}}</p>
                </div>
                <!-- Linkedin -->
                <div fxLayout="column">
                  <p class="color-primary font-weight-600">Linkedin</p>
                  <p>{{this.EmployeeLinkedin}}</p>
                </div>
                <!-- Skype -->
                <div fxLayout="column">
                  <p class="color-primary font-weight-600">Skype</p>
                  <p>{{this.EmployeeSkype}}</p>
                </div>
                <!-- Language -->
                <div fxLayout="column">
                  <p class="color-primary font-weight-600">Language</p>
                  <p>{{this.EmployeeLanguage}}</p>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Leads
          </ng-template>
          <div>
            <div>
              <!-- <p class="color-primary background-color-grey font-weight-600 padding-left"> Information</p> -->
              <div class="padding-left" fxLayout="column" fxLayoutGap="10px">
                <div class="lead-list">
                  <table mat-table [dataSource]="this.dataSource" class="mat-elevation-z8 leads-data">


                    <!-- Position Column -->
                    <ng-container matColumnDef="position">
                      <th mat-header-cell *matHeaderCellDef> #</th>
                      <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef> Name </th>
                      <td mat-cell *matCellDef="let element">

                        <p *ngIf="element.name == null">-</p>
                        <p *ngIf="element.name != null">{{element.name}}</p>
                      <td>
                    </ng-container>

                    <!-- company Column -->
                    <ng-container matColumnDef="company">
                      <th mat-header-cell *matHeaderCellDef> Company </th>

                      <td mat-cell *matCellDef="let element">
                        <p *ngIf="element.CompanyName != 'null'">{{element.companyName}}</p>
                        <p *ngIf="element.CompanyName === null ">-</p>
                      <td>
                    </ng-container>

                    <!-- email Column -->
                    <ng-container matColumnDef="email">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element">
                        <a class="fa fa-envelope e-color email e-color-hover " href="mailto: {{element.email}}"
                          target="_blank"></a>
                      <td>
                    </ng-container>

                    <!-- phone Column -->
                    <ng-container matColumnDef="phone">
                      <th mat-header-cell *matHeaderCellDef> Phone </th>
                      <td mat-cell *matCellDef="let element">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                          <a href="tel:{{element.phone}}" class="phone l-color"><i class="fa fa-phone"></i></a>
                          <!-- <a class="color-primary" href="tel:{{element.phone}}">{{element.phone}}</a> -->
                        </div>
                      <td>
                    </ng-container>

                    <!-- whatsappno Column -->
                    <ng-container matColumnDef="whatsappno">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element">
                        <a href="//api.whatsapp.com/send?phone=91{{element.whatsappnumber}}" class="whatsapp"
                          id="whatsapp-btn" target="_blank"><i class="fa fa-whatsapp"></i></a>
                      </td>
                    </ng-container>

                    <!-- lead value Column -->
                    <ng-container matColumnDef="leadvalue">
                      <th mat-header-cell *matHeaderCellDef> Value </th>
                      <td mat-cell *matCellDef="let element"> {{element.leadValue}}
                      <td>
                    </ng-container>

                    <!-- tags Column -->
                    <ng-container matColumnDef="tags">
                      <th mat-header-cell *matHeaderCellDef> Tags </th>
                      <td mat-cell *matCellDef="let element">
                        <!-- <p *ngIf="element.tags === null">-</p> -->
                        <mat-chip-list *ngIf="element.tags != null">
                          <span *ngFor="let symbols of element.tags.split(',')">
                            <mat-chip class=" mat-chips">{{symbols}} </mat-chip>
                          </span>
                        </mat-chip-list>
                        <p *ngIf="element.tags == null">-</p>
                        <!-- {{element.tags}} -->
                      <td>
                    </ng-container>

                    <!-- source Column -->
                    <ng-container matColumnDef="source">
                      <th mat-header-cell *matHeaderCellDef> Source</th>
                      <td mat-cell *matCellDef="let element">
                        <p *ngIf="element.sources != 'null'">{{element.sources}}</p>
                        <p *ngIf="element.sources === null">-</p>
                      <td>
                    </ng-container>

                    <!-- assigned Column -->
                    <ng-container matColumnDef="assigned">
                      <th mat-header-cell *matHeaderCellDef> Assigned </th>
                      <td mat-cell *matCellDef="let element">
                        {{element.assigned}}
                      <td>
                    </ng-container>

                    <!-- status Column -->
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef> Status </th>
                      <td mat-cell *matCellDef="let element">
                        {{element.status}}
                      <td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (mouseover)="handleMouseOver(row)"
                      (mouseleave)="handleMouseLeave(row)"></tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
      <mat-card *ngIf="isLoading" class="spinner" fxLayoutAlign="space-around center">
        <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
        </mat-progress-spinner>
      </mat-card>
    </div>
  </mat-card>
</div>