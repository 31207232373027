import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/modules/material/material.module';
import { HomeComponent } from './home/home.component';
import { WebsiteRoutingModule } from './website-roting.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
HomeComponent
  ],
  imports: [
   
    HttpClientJsonpModule,
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    WebsiteRoutingModule,
  ],
  providers: [],
})
export class WebsiteModule { }
