<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="container backgroud-color-primary">

    <mat-card class="cardsize" fxLayout="column" fxLayoutAlign="center">
        <mat-card-title class="padding-top">
            <span fxLayoutAlign="center" class="color-primary font-weight-500 font-uppercase">Forgot Password</span>
        </mat-card-title>

        <mat-card-content class="cardpadding margin-top">
            <div fxLayout="column" fxLayoutAlign="start stretch">
                <form [formGroup]="forgotFormGroup">
                    <div fxLayout="column" fxLayoutGap="10px">

                        <div>
                            <!-- Email -->
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Email</mat-label>
                                <input matInput formControlName="email" placeholder="Email">
                                <mat-error *ngIf="txtEmail.errors?.required"> Email is
                                    required. </mat-error>
                                <mat-error *ngIf="txtEmail.errors?.pattern">Invalid Email .
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                </form>
                <!-- Error -->
                <div fxLayout="row" fxLayoutAlign="center center">
                    <p class="color-danger">{{this.error}}</p>
                </div>
                <!-- Buttons -->
                <div fxLayout="row" fxLayoutAlign="start stretch" [style.margin-top]="'29px'">
                    <button mat-raised-button color="primary" class="full-width" (click)="send()"
                        [disabled]="forgotFormGroup.invalid">Send</button>
                </div>

            </div>
        </mat-card-content>
    </mat-card>
</div>