import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { SelectionModel } from '@angular/cdk/collections';
import { EmployeeListService } from 'src/app/shared/services/adminpanel/employee-list.service';
import { Employee } from 'src/app/shared/models/adminpanel/employee';
import { DeleteEmployeeComponent } from './delete-employee/delete-employee.component';
import { CONSTANT } from 'src/app/shared/utils/constant';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {

  public variables = ['Parth Shah', 'Tushar Panchal', 'Yash Shah', 'Jay Patel', 'Dhruv Boghani', 'pre'];
  public filteredList1 = this.variables.slice();
  public variables2 = CONSTANT.status;
  public filteredList2 = this.variables2.slice();
  toppings = new FormControl(this.variables2);
  isLoading = true;

  displayedColumns: string[] = ['position', 'actions', 'image', 'name', 'email', 'phone'];
  dataSource = new MatTableDataSource<Employee>([]);
  selection = new SelectionModel<Employee>(true, []);
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('scheduledOrdersPaginator') set paginator(pager: MatPaginator) {
    if (pager) this.dataSource.paginator = pager;
  }
  color: any;
  employee: any;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private EmployeeListService: EmployeeListService,
    private matSnack: SnackbarService
  ) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.getEmployee();
    // this.getEmployeeDatabyid();
    localStorage.removeItem('edit-employee');
    localStorage.removeItem('add-employee');
    localStorage.removeItem('view-employee');
    localStorage.removeItem('employeeData')
  }

  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  handleMouseOver(row) {
    const position = row;
    this.dataSource.data.map((data: any) => {
      if (data === position) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const position = row;
    this.dataSource.data.map((data: any) => {
      if (data === position) {
        data.show = false;
      }
    });
  }


  viewemployee(event) {
    localStorage.setItem('employeeData', JSON.stringify(event))
    localStorage.setItem('view-employee', 'View-employee')
    this.router.navigateByUrl('admin/employee/view-employee', { state: event });

  }

  editemployee(event) {
    localStorage.setItem('employeeData', JSON.stringify(event))
    localStorage.setItem('edit-employee', 'Edit-employee')
    this.router.navigateByUrl('admin/employee/add-employee', { state: event });

  }

  //Add employee page route
  addemployee() {

    localStorage.setItem('add-employee', 'Add-employee')
    this.router.navigateByUrl('admin/employee/add-employee');
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.employee.forEach(row =>
        this.selection.select(row)
      );
  }

  logSelection() {
    this.selection.selected.forEach(s => console.log(s.name));
  }

  someMethod(event) {
    console.log(event)
    const filterValue = event;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  someMethod1(event) {
    console.log(event)

    const filterValue = event;
    this.dataSource.filter = filterValue.trim().toLowerCase();

  }

  /*
    Get employee list api called
  */
  getEmployee() {
    this.EmployeeListService.getEmployee()
      .subscribe((data: Employee[]) => {
        this.isLoading = false;
        this.employee = data;
        this.dataSource.data = data
      });
  }




  /*
    Delete employee Popup
  */
  deleteemployee(element) {
    const dialogRef = this.dialog.open(DeleteEmployeeComponent, {
      height: 'auto',
      width: '30%',
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getEmployee()
      this.getEmployee()
    });

  }
}
