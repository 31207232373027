export const CONSTANT = {
    emailPattern: '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$',
    passwordPattern: '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[a-zA-Z0-9\d$@$!%*?&].{7,}',
    passwordrequired: 'Password is required.',
    passwordinvalid: 'At least 8 characters in length Lowercase and Uppercase letters Numbers Special characters',
    cpasswordrequired: 'Confirm Password is required.',
    cpasswordinvalid: 'Password and confirm password are not matching',
    languages: ['English', 'Spanish', 'Hindi', 'Arabic', 'French', 'Gujarati', 'Farsi', 'Punjabi', 'German', 'Urdu', 'Chinese', 'Vietnamese', 'Korean', 'Telugu', 'Hebrew', 'Tamil', 'Mandarin', 'Russian', 'Romanian', 'Greek', 'Portuguese', 'Egyptian', 'Hungarian', 'Italian', 'Malayalam', 'Persian', 'Polish', 'Cantonese', 'Filipino', 'Japanese', 'Norwegian', 'Syrian', 'Armenian', 'Bosnian', 'Chaldean', 'Lithuania', 'Serbian', 'Sign', 'Swedish', 'Taiwanese', 'Turkish'],
    role: ['Admin', 'Employee'],
    status: ['Open', 'Intrested', 'Not Intrested'],

    dashboardTooltip: 'Widgets that are shown only if they have enough data do not have options to be hidden or shown',
    dashBoardShowContent: ['Quick Statistics', 'Finance Overview', 'User Widget', 'My To Do Items', 'Calendar',
        'Weekly Payment Records', 'Contracts Expiring Soon', 'Leads Chart', 'projects_chart', 'Latest Project Activity'
    ],
    draft: 'DRAFT',
    notSent: 'NOT SENT',
    sent: 'SENT',
    unPaid: 'UNPAID',
    open: 'OPEN',
    partiallyPaid: 'PARTIALLY PAID',
    expired: 'EXPIRED',
    revised: 'REVISED',
    overDue: 'OVERDUE',
    decline: 'DECLINED',
    paid: 'PAID',
    accepted: 'ACCEPTED',
    // Quotation
    related: ['Lead', 'Customer'],

}