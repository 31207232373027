import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mail-send',
  templateUrl: './mail-send.component.html',
  styleUrls: ['./mail-send.component.scss']
})
export class MailSendComponent implements OnInit {
  email: any;

  constructor(private router: Router,) { }

  ngOnInit(): void {
    this.email = localStorage.getItem('email-change')

  }
  //Send email id
  back() {
    this.router.navigate(['admin/login']);
  }
}
