import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot(
    [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: '', loadChildren: () => import('./components/website/website.module').then(m => m.WebsiteModule) },
      { path: '', redirectTo: 'admin/login', pathMatch: 'full' },
      { path: 'admin', loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule) },
      { path: 'employee', loadChildren: () => import('./components/employee/employee.module').then(m => m.EmployeeModule) },
      { path: 'pages', loadChildren: () => import('./shared/components/pages/pages.module').then(m => m.PagesModule) },

    ],

    { useHash: true, anchorScrolling: 'enabled' }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
