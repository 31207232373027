<div fxLayout="column" id="top" class="cardpadding">
  <mat-card fxLayout="column" fxLayoutGap="10px">
    <div class="color-primary backgroud-color-white  ">
      <div fxLayout="row" fxLayoutAlign="space-between">

        <p class="mat-title" *ngIf="this.EditEmployee != 'Edit-employee'">Add new employee
        </p>
        <p class="mat-title" *ngIf="this.EditEmployee === 'Edit-employee'">Employee
        </p>
        <button mat-raised-button color="primary" routerLink="/admin/employee">
          <mat-icon class="cursor-pointer">arrow_back</mat-icon> Back
        </button>
      </div>
    </div>
    <div>
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            Profile
          </ng-template>
          <form [formGroup]="AddEmployeeForm">
            <div class="cardpadding" fxLayout="column">
              <div fxLayoutAlign="center center" style="margin-bottom: 20px;">
                <div class="circle">
                  <label class="hoverable">
                    <img *ngIf="this.AddEmployee === 'Add-employee'" src="assets/images/user-placeholder.jpg"
                      class="profile-img" alt="User Image" width="100">
                    <img *ngIf='this.PhotoUploadUrl == null && this.AddEmployee != "Add-employee"' class="profile-img"
                      [src]="this.EmployeeImageDefault != 'assets/images/user-placeholder.jpg' ?  this.EmployeeImage : 'assets/images/user-placeholder.jpg'"
                      alt="User Image" width="100">
                    <img class="profile-img" *ngIf='this.PhotoUploadUrl != null && this.AddEmployee != "Add-employee"'
                      [src]=" this.PhotoUploadUrl != null ?  this.PhotoUploadUrl : 'assets/images/user-placeholder.jpg'"
                      alt="User Image" width="100">
                    <label class="middle" for="fileInputprofile">
                      <mat-icon class="color-primary">photo_camera</mat-icon>
                    </label>
                  </label>
                  <input class="display-none" id="fileInputprofile" type='file' (change)="fileChangeEvent($event)">
                </div>
                <div>
                  <label class="middle">
                    <span>Upload a photo</span>
                  </label>
                </div>
              </div>

              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                fxLayoutAlign.lt-sm="start" fxFlex>
                <!-- First Name -->
                <div fxFlex="48" fxFlex.lt-sm="100">
                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="fname" [(ngModel)]="this.EmployeeFirstName" placeholder="">
                  </mat-form-field>
                </div>
                <!-- Last Name -->
                <div fxFlex="48" fxFlex.lt-sm="100">
                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="lname" [(ngModel)]="this.EmployeeLastName" placeholder="">
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                fxLayoutAlign.lt-sm="start" fxFlex>
                <!-- Email -->
                <div fxFlex="48" fxFlex.lt-sm="100">
                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>Email Address</mat-label>
                    <input matInput formControlName="email" [(ngModel)]="this.EmployeeEmail" placeholder="">
                  </mat-form-field>
                </div>
                <!-- Phone -->
                <div fxFlex="48" fxFlex.lt-sm="100">
                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>Phone</mat-label>
                    <input matInput formControlName="phone" [(ngModel)]="this.EmployeePhone" placeholder="">
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                fxLayoutAlign.lt-sm="start" fxFlex>
                <!-- Facebook -->
                <div fxFlex="48" fxFlex.lt-sm="100">
                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>Facebook</mat-label>
                    <input matInput formControlName="facebook" [(ngModel)]="this.EmployeeFacebook" placeholder="">
                  </mat-form-field>
                </div>
                <!-- Linkedin -->
                <div fxFlex="48" fxFlex.lt-sm="100">
                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>LinkedIn</mat-label>
                    <input matInput formControlName="linkedin" [(ngModel)]="this.EmployeeLinkedin" placeholder="">
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                fxLayoutAlign.lt-sm="start" fxFlex>
                <!-- Skype -->
                <div fxFlex="48" fxFlex.lt-sm="100">
                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>Skype</mat-label>
                    <input matInput formControlName="skype" [(ngModel)]="this.EmployeeSkype" placeholder="">
                  </mat-form-field>
                </div>

                <!-- Language -->
                <div fxFlex="48" fxFlex.lt-sm="100">
                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>Default Language</mat-label>
                    <mat-select [(ngModel)]="this.EmployeeLanguage" formControlName="language" name="language">
                      <mat-option *ngFor="let language of allLanguages" [value]="language">
                        {{language}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between start"
                fxLayoutAlign.lt-sm="start" fxFlex>
                <!-- Module -->
                <div fxFlex="48" fxFlex.lt-sm="100">
                  <section class="example-section" [formGroup]="modules">
                    Modules
                    <div fxLayout="row" fxLayoutGap="10px" class="margin-left">
                      <p>
                        <mat-checkbox [(ngModel)]=" this.EmployeeQuotation" formControlName="quotation" color="primary">
                          Quotation</mat-checkbox>
                      </p>
                      <p>
                        <mat-checkbox formControlName="product" [(ngModel)]="this.EmployeeProduct" color="primary">
                          Product</mat-checkbox>
                      </p>
                    </div>
                  </section>
                </div>

                <!-- Role -->
                <div fxFlex="48" fxFlex.lt-sm="100">
                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>Role</mat-label>
                    <!-- {{this.EmployeeRole}} -->
                    <mat-select [(ngModel)]="this.EmployeeRole" formControlName="role" name="role" class="form-control">
                      <mat-option *ngFor="let role of roles" [value]="role">
                        {{role}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                fxLayoutAlign.lt-sm="start" fxFlex *ngIf="this.EditEmployee != 'Edit-employee'">
                <div fxFlex="48" fxFlex.lt-sm="100">
                  <!-- Password -->
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" [type]="hide ? 'password' : 'text'"
                      placeholder="Password">
                    <mat-icon class="color-secondary" matSuffix (click)="hide = !hide">
                      {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="txtPassword.errors?.required">Password is required.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <!-- Button -->
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button mat-raised-button>Cancel</button>
                <button mat-raised-button color="primary" (click)="addEmployee()"
                  [disabled]="AddEmployeeForm.invalid">Save</button>
              </div>
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
      <mat-card *ngIf="isLoading" class="spinner" fxLayoutAlign="space-around center">
        <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
        </mat-progress-spinner>
      </mat-card>
    </div>
  </mat-card>
</div>