import { Component, OnInit,Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-website-header',
  templateUrl: './website-header.component.html',
  styleUrls: ['./website-header.component.scss']
})
export class WebsiteHeaderComponent implements OnInit {
  @Output() public sidenavClose = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }
  public onSidenavClose  = () => { 
    this.sidenavClose.emit();
  }
}
