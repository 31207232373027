<app-layout>
  <mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation">
      <!--Mobile side-nav code-->
      <mat-nav-list (sidenavClose)="sidenav.close()">
        <a mat-list-item (click)="onSidenavClose()" routerLink="/home">Home</a>
        <a mat-list-item (click)="onSidenavClose()" routerLink="/home">Our Expertise</a>
        <a mat-list-item (click)="onSidenavClose()" routerLink="/home">Services</a>
        <a
          mat-list-item
          (click)="onSidenavClose()"
          class="btn-highLight"
          color="warn"
          routerLink="/home">Contact Us
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar
        fxLayoutAlign="space-between center"
        (sidenavToggle)="sidenav.toggle()"
      >

          <div  fxLayoutAlign="center">
            <button fxHide.gt-xs class="menu_icon" mat-icon-button (click)="sidenav.toggle()">
              <mat-icon>menu</mat-icon>
            </button>
            <a mat-button color="primary" class="website_name" routerLink="/home"
            >Smoky CRM</a
          >
          </div>
        <div
          fxLayout
          fxLayoutAlign="end"
          fxLayoutGap.gt-md="10%"
          fxHide.xs
          class="navigation-items"
        >
          <div>
            <a mat-button routerLink="/home">Home</a>
          </div>
          <div>
            <a mat-button routerLink="/home">Our Expertise</a>
          </div>
          <div>
            <a mat-button routerLink="/home">Services</a>
          </div>
          <div>
            <a mat-button routerLink="/home">Blog</a>
          </div>
          <div>
            <a
              mat-raised-button
              class="btn-highLight"
              id="btn-highLight"
              color="warn"
              routerLink="/home"
              >Contact Us</a
            >
          </div>
        </div>
      </mat-toolbar>
      <!-- website navigation header as well-->
      <div class="website-content" [ngClass.lt-md]="{'website-content-md':true,'website-content':false}">
        <router-outlet></router-outlet>
        <div class="box1">
          <app-website-footer ></app-website-footer>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</app-layout>
