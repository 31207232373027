import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeadsService } from 'src/app/shared/services/adminpanel/leads/leads.service';
import { MailstatusService } from 'src/app/shared/services/adminpanel/mailstatus.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { DeleteLeadComponent } from '../../leads/delete-lead/delete-lead.component';

@Component({
  selector: 'app-delete-draft',
  templateUrl: './delete-draft.component.html',
  styleUrls: ['./delete-draft.component.scss']
})
export class DeleteDraftComponent implements OnInit {

  local_data: any;
  action: any;

  constructor(
    public dialogRef: MatDialogRef<DeleteLeadComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DeleteLeadComponent,
    public matSnack: SnackbarService,
    private MailstatusService: MailstatusService,

  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  ngOnInit(): void {
    console.log(this.local_data.id)
  }

  // Close dialog
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  //Delete lead api call
  reject() {
    var formData: any = new FormData();
    formData.append("id", this.local_data.id);

    this.MailstatusService.DeleteDraft(`api/EmailDraft/DeleteEmail`, formData)
      .subscribe((res) => {
        console.log(res)
        this.dialogRef.close({ data: res });
        this.matSnack.showNotification1("Draft Deleted", "")
      });
  }
}
