<div fxLayout="column" class="full-padding padding-top" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex fxHide>
        <!-- <mat-card  style="width:100%"> -->
        <mat-card style="width:100%" fxLayout="column" fxLayoutAlign=" center" fxFlex="32">

            <div fxLayout="row">
                <!-- <mat-icon class="blue-color">currency_rupee</mat-icon> -->
                <p fxLayoutAlign=" center" class="blue-color mat-h2">₹ 216,835.30</p>
            </div>
            <p class="">Outstanding Invoices</p>
        </mat-card>
        <mat-card style="width:100%" fxLayout="column" fxLayoutAlign=" center" fxFlex="32">

            <div fxLayout="row">
                <!-- <mat-icon class="blue-color">currency_rupee</mat-icon> -->
                <p fxLayoutAlign=" center" class="blue-color mat-h2">₹ 63,980.78</p>
            </div>
            <p class="">Past Due Invoices</p>
        </mat-card>
        <mat-card style="width:100%" fxLayout="column" fxLayoutAlign=" center" fxFlex="32">

            <div fxLayout="row">
                <!-- <mat-icon class="blue-color">currency_rupee</mat-icon> -->
                <p fxLayoutAlign=" center" class="blue-color mat-h2">₹ 42,279.64</p>
            </div>
            <p class="">Paid Invoices</p>
        </mat-card>
    </div>
    <!--Main Features -->
    <div fxLayout="row" fxLayoutAlign="space-between " fxFlex>
        <!--  INVOICES AWAITING PAYMENT -->
        <mat-card fxLayout="column" fxLayoutAlign="space-between " fxFlex="32" fxLayoutGap="10px">
            <div class="cardpadding" fxLayout="row" fxLayoutAlign="space-between " fxLayoutGap="10px">
                <p class="normal-font">
                    <i class="fa fa-hourglass-end  fa-xs"></i>
                    INVOICES AWAITING PAYMENT
                </p>
                <p class="normal-font">10/20</p>
            </div>
            <mat-progress-bar value="30" class="blue-progress-bar" mode="buffer">
            </mat-progress-bar>
        </mat-card>
        <!--  Converted Leads -->
        <mat-card fxLayout="column" fxLayoutAlign="space-between " fxFlex="32" fxLayoutGap="10px">
            <div class="cardpadding" fxLayout="row" fxLayoutAlign="space-between " fxLayoutGap="10px">
                <div fxLayout="row">
                    <mat-icon class="mat-icon-resize">update</mat-icon>
                    <p class="normal-font">
                        CONVERTED LEADS
                    </p>
                </div>
                <p class="normal-font">10/20</p>
            </div>
            <mat-progress-bar value="30" class="blue-progress-bar" mode="buffer">
            </mat-progress-bar>
        </mat-card>
        <!--  PROJECTS IN PROGRESS -->
        <mat-card fxLayout="column" fxLayoutAlign="space-between " fxFlex="32" fxLayoutGap="10px">
            <div class="cardpadding" fxLayout="row" fxLayoutAlign="space-between " fxLayoutGap="10px">
                <div fxLayout="row">
                    <mat-icon class="mat-icon-resize">markunread_mailbox</mat-icon>
                    <p class="normal-font">
                        PROJECTS IN PROGRESS
                    </p>
                </div>
                <p class="normal-font">10/20</p>
            </div>
            <mat-progress-bar value="30" class="blue-progress-bar" mode="buffer">
            </mat-progress-bar>
        </mat-card>

    </div>

    <!--Main Features -->
    <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex>
        <div fxLayout="column" fxFlex="32" fxLayoutGap="20px">

            <!--  TASKS NOT FINISHED -->
            <mat-card fxLayout="column" fxLayoutAlign="space-between " fxFlex="32" fxLayoutGap="10px" fxHide>
                <div class="cardpadding" fxLayout="row" fxLayoutAlign="space-between " fxLayoutGap="10px">
                    <div fxLayout="row">
                        <mat-icon class="mat-icon-resize">ballot</mat-icon>
                        <p class="normal-font">
                            TASKS NOT FINISHED
                        </p>
                    </div>
                    <p class="normal-font">10/20</p>
                </div>
                <mat-progress-bar value="50" class="blue-progress-bar" mode="buffer">
                </mat-progress-bar>
            </mat-card>


            <!-- Lead Status OVERVIEW -->
            <mat-card fxLayout="column" fxLayoutAlign="space-between " fxFlex="32" fxLayoutGap="10px">

                <div class="cardpadding" style="width: 100%;" fxLayout="column" fxLayoutGap="10px">
                    <p class="normal-font">LEAD STATUS OVERVIEW</p>
                    <mat-divider inset=“true”></mat-divider>
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div>
                            <!-- Draft -->
                            <div fxLayout="row" fxLayoutAlign="space-between start">
                                <div class="normal-font ">OPEN</div>
                                <p class="normal-font ">10/20</p>
                            </div>
                            <mat-progress-bar value="50" class="blue-progress-bar" mode="buffer">
                            </mat-progress-bar>
                        </div>
                        <div>
                            <!-- notSent -->
                            <div fxLayout="row" fxLayoutAlign="space-between start">
                                <div class="normal-font ">INTRESTED</div>
                                <p class="normal-font ">5/20</p>
                            </div>
                            <mat-progress-bar value="25" class="blue-progress-bar" mode="buffer">
                            </mat-progress-bar>
                        </div>
                        <div>
                            <!--  UNPAID -->
                            <div fxLayout="row" fxLayoutAlign="space-between start">
                                <div class="normal-font ">NOT INTRESTED</div>
                                <p class="normal-font ">5/20</p>
                            </div>
                            <mat-progress-bar value="25" class="blue-progress-bar" mode="buffer">
                            </mat-progress-bar>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <!-- LAED SOURCE OVERVIEW -->
        <mat-card fxLayout="column" fxLayoutAlign="space-between " fxFlex="32" fxLayoutGap="10px">
            <div class="cardpadding" style="width: 100%;" fxLayout="column" fxLayoutGap="10px">
                <p class="normal-font">LEAD SOURCE OVERVIEW</p>
                <mat-divider inset=“true”></mat-divider>
                <div fxLayout="column" fxLayoutGap="20px">
                    <div>
                        <!-- FACEBOOK -->
                        <div fxLayout="row" fxLayoutAlign="space-between start" fxHide>
                            <div class="normal-font ">FACEBOOK</div>
                            <p class="normal-font ">10/50</p>
                        </div>
                        <mat-progress-bar value="20" class="blue-progress-bar" mode="buffer" fxHide>
                        </mat-progress-bar>
                    </div>
                    <div>
                        <!-- JUSTDIAL -->
                        <div fxLayout="row" fxLayoutAlign="space-between start">
                            <div class="normal-font ">JUSTDIAL</div>
                            <p class="normal-font ">15/50</p>
                        </div>
                        <mat-progress-bar value="30" class="blue-progress-bar" mode="buffer">
                        </mat-progress-bar>
                    </div>
                    <div>
                        <!--  INDIAMART -->
                        <div fxLayout="row" fxLayoutAlign="space-between start">
                            <div class="normal-font ">INDIAMART</div>
                            <p class="normal-font ">5/50</p>
                        </div>
                        <mat-progress-bar value="10" class="blue-progress-bar" mode="buffer">
                        </mat-progress-bar>
                    </div>
                    <div>
                        <!--  CALLING -->
                        <div fxLayout="row" fxLayoutAlign="space-between start">
                            <div class="normal-font ">CALLING</div>
                            <p class="normal-font ">15/50</p>
                        </div>
                        <mat-progress-bar value="30" class="blue-progress-bar" mode="buffer">
                        </mat-progress-bar>
                    </div>
                    <div>
                        <!--  OTHERS -->
                        <div fxLayout="row" fxLayoutAlign="space-between start">
                            <div class="normal-font ">OTHERS</div>
                            <p class="normal-font ">5/50</p>
                        </div>
                        <mat-progress-bar value="10" class="blue-progress-bar" mode="buffer">
                        </mat-progress-bar>
                    </div>
                </div>
            </div>
        </mat-card>
        <!-- PROPOSAL OVERVIEW -->
        <mat-card fxLayout="column" fxLayoutAlign="space-between " fxFlex="32" fxLayoutGap="10px">
            <div class="cardpadding" style="width: 100%;" fxLayout="column" fxLayoutGap="10px">
                <p class="normal-font">QUOTATION OVERVIEW</p>
                <mat-divider inset=“true”></mat-divider>
                <div fxLayout="column" fxLayoutGap="20px">
                    <div>
                        <!-- Draft -->
                        <div fxLayout="row" fxLayoutAlign="space-between start">
                            <div class="normal-font ">{{draft}}</div>
                            <p class="normal-font ">1/43</p>
                        </div>
                        <mat-progress-bar value="10" class="blue-progress-bar" mode="buffer">
                        </mat-progress-bar>
                    </div>
                    <div>
                        <!-- notSent -->
                        <div fxLayout="row" fxLayoutAlign="space-between start">
                            <div class="normal-font ">{{notSent}}</div>
                            <p class="normal-font ">18/43</p>
                        </div>
                        <mat-progress-bar value="75.5" class="blue-progress-bar" mode="buffer">
                        </mat-progress-bar>
                    </div>
                    <div>
                        <!--  UNPAID -->
                        <div fxLayout="row" fxLayoutAlign="space-between start">
                            <div class="normal-font ">{{unPaid}}</div>
                            <p class="normal-font ">15/43</p>
                        </div>
                        <mat-progress-bar value="62.5" class="blue-progress-bar" mode="buffer">
                        </mat-progress-bar>
                    </div>
                    <div>
                        <!--  partiallyPaid -->
                        <div fxLayout="row" fxLayoutAlign="space-between start">
                            <div class="normal-font ">{{partiallyPaid}}</div>
                            <p class="normal-font ">2/43</p>
                        </div>
                        <mat-progress-bar value="8.33" class="blue-progress-bar" mode="buffer">
                        </mat-progress-bar>
                    </div>
                    <div>
                        <!--  OVERDUE -->
                        <div fxLayout="row" fxLayoutAlign="space-between start">
                            <div class="normal-font ">{{overDue}}</div>
                            <p class="normal-font ">1/43</p>
                        </div>
                        <mat-progress-bar value="4.17" class="blue-progress-bar" mode="buffer">
                        </mat-progress-bar>
                    </div>
                    <div>
                        <!--  paid -->
                        <div fxLayout="row" fxLayoutAlign="space-between start">
                            <div class="normal-font ">{{paid}}</div>
                            <p class="normal-font ">5/43</p>
                        </div>
                        <mat-progress-bar value="20.83" class="blue-progress-bar" mode="buffer">
                        </mat-progress-bar>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex>
        <!-- Consultations, Cancelled Consultations, Consultations summary-->
        <mat-card fxFlex="32" fxFlex.lt-sm="100">
            <div class="cardpadding" fxLayout="column" fxLayoutGap="5px">
                <p class="normal-font">REPORTS</p>
                <mat-divider inset=“true”></mat-divider>
                <div>
                    <!-- LEADS -->
                    <div fxLayout="row" fxLayoutAlign="space-between start">
                        <!--  <div class="normal-font ">{{draft}}</div> -->
                        <div class="normal-font ">LEADS</div>
                        <p class="normal-font ">6/43</p>
                    </div>
                    <mat-progress-bar value="20" class="blue-progress-bar" mode="buffer">
                    </mat-progress-bar>
                </div>
                <!-- <a class="normal-font text-primary-hover">LEADS</a> -->
                <div>
                    <!-- QUOTATIONS -->
                    <div fxLayout="row" fxLayoutAlign="space-between start">
                        <!--  <div class="normal-font ">{{draft}}</div> -->
                        <div class="normal-font ">QUOTATIONS</div>
                        <p class="normal-font ">10/43</p>
                    </div>
                    <mat-progress-bar value="10" class="blue-progress-bar" mode="buffer">
                    </mat-progress-bar>
                </div>
                <!-- <a class="normal-font text-primary-hover">QUOTATIONS</a> -->


                <a class="normal-font text-primary-hover" fxHide>Consultation Summary</a>
                <a class="normal-font text-primary-hover" fxHide>Doctors Productivity</a>
                <p class="normal-font text-primary-hover" fxHide>Payment Collection</p>
                <p class="normal-font text-primary-hover" fxHide>Activity View</p>
            </div>
        </mat-card>
        <!-- Doctors list, patient list -->
        <mat-card fxFlex="32" fxFlex.lt-sm="100">
            <div class="cardpadding" fxLayout="column" fxLayoutGap="5px">
                <!-- <p class="mat-title" fxLayoutAlign="center center">Manage</p> -->
                <p class="normal-font">MANAGES</p>
                <mat-divider inset=“true”></mat-divider>
                <div>
                    <!-- EMPLOYEES -->
                    <div fxLayout="row" fxLayoutAlign="space-between start">
                        <!--  <div class="normal-font ">{{draft}}</div> -->
                        <div class="normal-font ">EMPLOYEES</div>
                        <p class="normal-font ">15/43</p>
                    </div>
                    <mat-progress-bar value="35" class="blue-progress-bar" mode="buffer">
                    </mat-progress-bar>
                </div>
                <!--  <a class="normal-font text-primary-hover">EMPLOYEES</a> -->
                <div>
                    <!-- USERS -->
                    <div fxLayout="row" fxLayoutAlign="space-between start">
                        <!--  <div class="normal-font ">{{draft}}</div> -->
                        <div class="normal-font ">USERS</div>
                        <p class="normal-font ">17/43</p>
                    </div>
                    <mat-progress-bar value="15" class="blue-progress-bar" mode="buffer">
                    </mat-progress-bar>
                </div>
                <!--<a class="normal-font text-primary-hover">USERS</a> -->
            </div>
        </mat-card>
        <!-- <mat-card *ngIf="isLoading" class="spinner" fxLayoutAlign="space-around center" >
            <mat-progress-spinner 
              color="primary" 
              mode="indeterminate">
            </mat-progress-spinner>
          </mat-card> -->
        <div fxFlex="32"></div>
    </div>

</div>