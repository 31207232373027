import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Products } from 'src/app/shared/models/adminpanel/products';
import { ProductsService } from 'src/app/shared/services/adminpanel/products.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { CONSTANT } from 'src/app/shared/utils/constant';
interface Status {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  allLanguages: string[] = (CONSTANT.languages);
  selectedValue: any;
  selectedValue1: any;
  AddproductForm: FormGroup;
  maxDate = new Date();
  hide = true;
  txtPassword: any;
  PhotoUploadUrl: any;
  materialdata: any;
  imageChangedEvent: any;
  base64textString: string;
  PhotoUrl: any;
  imagePath: any;
  productDatabyid: any;
  Editproduct: string;
  productsData: any;
  tax: Status[] = [
    { value: '9.00% CGST', viewValue: '9.00% CGST' },
    { value: '9.00% SGST', viewValue: '9.00% SGST' },
    { value: '18.00% GST', viewValue: '18.00% GST' },
    { value: '18.00% IGST', viewValue: '18.00% IGST' },
  ];
  productItem: any;
  productTax: any;
  productRate: any;


  constructor(
    private matSnack: SnackbarService,
    private router: Router,
    private ProductService: ProductsService
  ) { }

  ngOnInit(): void {
    this.productsData = JSON.parse(localStorage.getItem('productData'));
    this.Editproduct = localStorage.getItem('edit-product')
    this.AddproductForm = new FormGroup({
      item: new FormControl('', Validators.required),
      rate: new FormControl('', Validators.required),
      tax: new FormControl('', Validators.required)
    })
    this.txtPassword = this.AddproductForm.get('password');
    if (this.Editproduct === 'Edit-product') {
      this.getProductDatabyid(this.productsData.id)
    }
  }


  //AddproductForm controls
  get alf() { return this.AddproductForm.controls; }

  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  //join symptomps function
  joinsymptomps(arrayValue) {

    var StringValue = arrayValue
    return Array.prototype.map.call(StringValue, function (item) {
      return item;
    }).join(", ");

  }

  //Add product api call
  addproduct() {
    if (this.Editproduct != 'Edit-product') {
      this.ProductService.AddProduct(
        this.alf.item.value,
        this.alf.rate.value,
        this.joinsymptomps(this.alf.tax.value),
        null
      ).pipe(first())
        .subscribe(
          data => {
            this.router.navigateByUrl('/admin/products')
            this.matSnack.showNotification("Product Added", "")
          },
          error => {
            console.log(error.error)
          });
    }
    if (this.Editproduct === 'Edit-product') {

      this.ProductService.UpdateProduct(
        `api/Product/UpdateProduct`,
        this.alf.item.value,
        this.alf.rate.value,
        this.joinsymptomps(this.alf.tax.value),
        null,
        this.productDatabyid.id,
      ).pipe(first())
        .subscribe(
          data => {
            this.router.navigateByUrl('/admin/products')
            this.matSnack.showNotification("Product Updated", "")
          },
          error => {
            console.log(error.error)
          });
    }
  }
  /*
    Get product data by id api called
  */
  getProductDatabyid(id) {
    this.ProductService
      .getProductdatabyid<Products>(`api/Product/ViewProduct?id=${id}`)
      .subscribe((data: Products[]) => {
        this.productDatabyid = data[0];
        this.productItem = this.productDatabyid.item
        // console.log(this.productItem)
        // .split(',')
        this.productTax = this.productDatabyid.tax;
        this.productRate = this.productDatabyid.rate
      })
  }
}
