import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmployeeListService } from 'src/app/shared/services/adminpanel/employee-list.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { Employee } from 'src/app/shared/models/adminpanel/employee';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AddfbinfoComponent } from '../addfbinfo/addfbinfo.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-editinfo',
  templateUrl: './editinfo.component.html',
  styleUrls: ['./editinfo.component.scss']
})
export class EditinfoComponent implements OnInit {
  displayedColumns: string[] = ['position', 'full_name', 'email', 'phone_number'];
  dataSource = new MatTableDataSource<any>([]);
  csvFile: any;
  data;
  csvFileData: any;
  filteredListEmployee: Employee[];
  assignedChangeUserId: any;
  facebookdata
  routeState
  myForm = new FormGroup({
    assigned: new FormControl('', Validators.required)
  });
  @ViewChild('FacebookLeadTable') set paginator(pager: MatPaginator) {
    if (pager) this.dataSource.paginator = pager;
  }
  employee: any;
  public filteredList1: any;
  constructor(
    private matSnack: SnackbarService,
    private router: Router,
    private EmployeeListService: EmployeeListService,
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog,
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.routeState = this.router.getCurrentNavigation().extras.state;
      this.dataSource.data = this.routeState
      // if (this.routeState) {
      //   this.data.frontEnd = this.routeState.frontEnd ? JSON.parse(this.routeState.frontEnd) : '';
      //   this.data.site = this.routeState.site ? this.routeState.site : '';
      // }
    }
  }

  ngOnInit(): void {
    //  this.routeState=localStorage.getItem("facebookdata")
    this.getEmployee()
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  get f() {
    return this.myForm.controls;
  }


  getEmployee() {
    this.EmployeeListService.getEmployee()
      .subscribe((data: Employee[]) => {
        this.employee = data[0];
        // this.filteredList1 = this.employee.map(function (a) { return a["name"]; });
        this.filteredListEmployee = data
        this.filteredList1 = this.filteredListEmployee.slice()

      });
  }

  //Upload excel file api call
  // submit() {
  //   this.spinnerService.show();
  //   this.LeadServices.ImportLeadsByOutSource( this.assignedChangeUserId,
  //     this.data

  //     ).pipe(first())
  //   .subscribe(
  //     data => {
  //         this.router.navigateByUrl('/admin/leads');
  //       this.matSnack.showNotification('New Lead Added', '')
  //       this.spinnerService.hide();

  //     },

  //     error => {
  //       console.log(error.error)
  //     });



  // }
  AssignedEmployee(event) {
    console.log(event.source.triggerValue)
    console.log(event.value)
    // this.assignedChangeName = event.source.triggerValue;
    this.assignedChangeUserId = event.value;
  }
  submit() {
    this.routeState.map(l => l.empId = this.assignedChangeUserId ? this.assignedChangeUserId : null)
    this.showmodel(this.routeState)
  }
  showmodel(element) {
    const dialogRef = this.dialog.open(AddfbinfoComponent, {
      height: 'auto',
      width: '30%',
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.getLeads()
      // this.getLeads()
    });
  }
  //Export sample file
  export() {
    window.open('/assets/images/sample_import_file.csv', '_blank');
  }

}
