import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Products } from '../../models/adminpanel/products';
import { RestmanagerService } from '../../utils/restmanagerService';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private restmanagerService: RestmanagerService;
  _apiEndPoint: string = '';

  constructor(private http: HttpClient,
    restmanagerService: RestmanagerService) {
    this.restmanagerService = restmanagerService;
    this._apiEndPoint = environment.Url;
  }

  //Add Products api
  AddProduct(Item: string,
    Rate: string,
    Tax: string,
    AssignedUserId: string,
   ) {
    return this.http.post<Products>(`${environment.apiUrl}/api/Product/AddProduct`, {
      Item,
      Rate,
      Tax,
      AssignedUserId,
      
      
    })
      .pipe(map(res => {
        return res;
      }));
  }

  //Update Products api
  UpdateProduct(apiURL: string ,Item: string,
    Rate: string,
    Tax: string,
    AssignedUserId: string,
    Id: string) {
    return this.http.post<Products>(`${environment.apiUrl}/${apiURL}`, {
      Item,
      Rate,
      Tax,
      AssignedUserId,
      Id
    })
      .pipe(map(res => {
        return res;
      }));
  }


  //Get Products-list api
  getProducts() {
    return this.http.get<Products[]>(`${environment.apiUrl}/api/Product/ListProduct`);
  }

  //Get Product data by id api
  getProductdatabyid<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this.http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }

    //Prdouct Assign  status  api
    AssignedProduct<T>(apiURL: string, UserId: any, ProductId: any): Observable<T[]> {

      return this.mapAndCatchError(
        this.http.post<T[]>(`${this._apiEndPoint}/${apiURL}`, {
         
          UserId,
          ProductId
        })
      );
    }
  private mapAndCatchError<TData>(response: Observable<TData>): Observable<TData> {

    return response.pipe(
      map((r: TData) => {

        return r;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(null);
      })
    );
  }

  //Delete Product  api
  DeleteProduct<T>(apiURL: string, id: any,): Observable<T[]> {

    return this.mapAndCatchError(
      this.http.post<T[]>(`${this._apiEndPoint}/${apiURL}`, {
        id
      })
    );
  }
}
