import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PagesRoutingModule } from './pages-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/modules/material/material.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ViewQuotationComponent } from './view-quotation/view-quotation.component';
import { MailSendComponent } from './mail-send/mail-send.component';



@NgModule({
  declarations: [
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ViewQuotationComponent,
    MailSendComponent
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule
  ]
})
export class PagesModule { }
