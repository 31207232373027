<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="container backgroud-color-primary">
    <p class="mat-display-2 color-white">Admin Panel</p>
    <mat-card class="cardsize" fxLayout="column" fxLayoutAlign="center">
        <mat-card-title class="padding-top">
            <span fxLayoutAlign="center" class="color-primary font-weight-500 font-uppercase">Login</span>
        </mat-card-title>

        <mat-card-content class="cardpadding margin-top">
            <div   fxLayout="column" fxLayoutAlign="start stretch">
             
                <form [formGroup]="loginFormGroup" (keyup.enter)="login()">
                    <div>
                        <!-- Email -->
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Email</mat-label>
                            <input matInput formControlName="email" placeholder="Email">
                            <mat-error *ngIf="txtEmail.errors?.required"> Email is
                                required. </mat-error>
                            <mat-error *ngIf="txtEmail.errors?.pattern">Invalid Email .
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <!-- Password -->
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Password</mat-label>
                            <input matInput formControlName="password" [type]="hide ? 'password' : 'text'"
                                placeholder="Password">
                            <mat-icon class="color-secondary" matSuffix (click)="hide = !hide">
                                {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            <mat-error *ngIf="txtPassword.errors?.required">Password is required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </form>
                <!-- Error -->
                <div fxLayout="row" fxLayoutAlign="center center">
                    <p class="color-danger">{{this.error}}</p>
                </div>
                <div [style.margin-top]="'10px'">
                    <!-- Remember me -->
                    <span fxFlex="45">
                        <mat-checkbox color="primary"></mat-checkbox><span class="color-secondary">
                            Remember me
                        </span>
                    </span>
                    <!-- Forgot password -->
                    <span fxFlex="55" fxLayoutAlign="end">
                        <a class="color-primary" routerLink="/pages/forgot-password">
                            Forgot password?</a>
                    </span>
                </div>
                <!-- Buttton -->
                <div fxLayout="row" fxLayoutAlign="start stretch" [style.margin-top]="'29px'">
                    <button mat-raised-button color="primary" class="full-width" (click)="login()"
                        [disabled]="loginFormGroup.invalid">Login</button>
                </div>
                <!-- <div *ngIf="isLoading" class="spinner" fxLayoutAlign="space-around center" >
                    <mat-progress-spinner  mode="indeterminate" diameter="50"
                      color="primary" 
                      mode="indeterminate">
                    </mat-progress-spinner>
                  </div> -->
              
            </div>
        </mat-card-content>
    </mat-card>
</div>