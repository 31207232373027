import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { StatusService } from 'src/app/shared/services/status.service';

@Component({
  selector: 'app-delete-status',
  templateUrl: './delete-status.component.html',
  styleUrls: ['./delete-status.component.scss']
})
export class DeleteStatusComponent implements OnInit {

 
  local_data: any;
  action: any;

  constructor(
    public dialogRef: MatDialogRef<DeleteStatusComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DeleteStatusComponent,
    public matSnack: SnackbarService,
    private statusService: StatusService
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  ngOnInit(): void {
  }

  // Close dialog
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  //Delete lead api call
  reject() {
    this.statusService.DeleteleadStatus(`${this.local_data.statusId}`).subscribe((data: any[]) => {
      this.dialogRef.close({ data: data });
           this.matSnack.showNotification1("Status Deleted", "")
          
         });
 
  }
}
