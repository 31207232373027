<div
class="container"
fxLayout="row wrap"
fxLayoutAlign="center"
fxLayoutGap.xs="0" >
<!-- *ngFor="let design of designs" -->
<div class="item" fxFlex="10%" >
  <div style="padding:5px">
    <div fxLayoutAlign="center">
      <img
        width="120"
        height="120"
        data-bind="attr: { src: thumbnail }" />
    </div>
    <div fxLayoutAlign="center">
      design.name }}
    </div>
    <div fxLayoutAlign="center">
       design.code }}
    </div>
  </div>
</div>
</div>


