import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsModule } from 'src/app/layout/layout.module';
import { BlankLayoutComponent } from 'src/app/layout/blank-layout/blank-layout.component';
import { RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ViewQuotationComponent } from './view-quotation/view-quotation.component';
import { MailSendComponent } from './mail-send/mail-send.component';



@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: BlankLayoutComponent,
        children: [
          { path: 'change-password', component: ChangePasswordComponent, pathMatch: 'full' },
          { path: 'forgot-password', component: ForgotPasswordComponent, pathMatch: 'full' },
          { path: 'quotation', component: ViewQuotationComponent, pathMatch: 'full' },
          { path: 'mail-send', component: MailSendComponent, pathMatch: 'full' },

        ],
      }
    ]),
    LayoutsModule
  ]
})
export class PagesRoutingModule { }
