import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestmanagerService } from 'src/app/shared/utils/restmanagerService';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';

@Injectable({
  providedIn: 'root'
})
export class MailstatusService {
  [x: string]: any;
  private restmanagerService: RestmanagerService;
  _apiEndPoint: string = '';
  constructor(private http: HttpClient,
    restmanagerService: RestmanagerService) {
    this.restmanagerService = restmanagerService;
    this._apiEndPoint = environment.Url;
  }

  getListOfDraftLog() {
    return this.http.get<any>(`${environment.apiUrl}/api/EmailDraft/ListOfDraftLog?pageNo=1&pageSize=8`);
  }

  getEmail() {
    return this.http.get<any[]>(`${environment.apiUrl}/api/EmailDraft/ListOfDraftLog`);
  }

  //Email Draft List api
  getListOfemailDraft() {
    return this.http.get<any>(`${environment.apiUrl}/api/EmailDraft/ListOfEmailDraft?pageNo=1&pageSize=4`);
  }

  getlist() {
    return this.http.get<any[]>(`${environment.apiUrl}/api/EmailDraft/ListOfEmailDraft`);
  }

  //Email Draft view api
  getviewemaildraft() {
    return this.http.get<any>(`${environment.apiUrl}/api/EmailDraft/ViewEmailDraft?id=25b82b93-3644-41ae-a7cd-98c4e895b99f`);
  }

  getallemail() {
    return this.http.get<any[]>(`${environment.apiUrl}/api/EmailDraft/ViewEmailDraft`);
  }

  getEmaildatabyid<T>(apiURL: string): Observable<T[]> {
    return this.mapAndCatchError(
      this.http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }

  private mapAndCatchError<TData>(response: Observable<TData>): Observable<TData> {
    return response.pipe(
      map((r: TData) => {
        return r;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(null);
      })
    );
  }

  //Add email api
  AddLead(subject: string,) {
    return this.http.post<any>(`${environment.apiUrl}/api/EmailDraft/AddEmailDraft`, {
      subject,
    })
      .pipe(map(res => {
        return res;
      }));
  }
  //Update Email Draft (edit) api
  AddEmail(formdata) {
    
    return this.http.post<any>(`${environment.apiUrl}/api/EmailDraft/AddEmailDraft`,
    formdata

  )
    .pipe(map(res => {
      return res;
    }));

}
  //Update Email Draft (edit) api
  UpdateEmail(formdata) {
    
      return this.http.post<any>(`${environment.apiUrl}/api/EmailDraft/UpdateEmailDraft`,
      formdata

    )
      .pipe(map(res => {
        return res;
      }));
  
  }

  //Send Email api
  SendEmail(From: string, To: string) {
    // string, subject: string, message: string, Attachment: string, type: string) {
    return this.http.post<any>(`${environment.apiUrl}/api/EmailDraft/SendEmail`,
      {
        From, To,
        //subject, message, Attachment, type
      })
      .pipe(map(res => {
        return res;
      }));
  }

  sendEmail(apiURL,formdata) {

    return this.http.post<any>(`${environment.apiUrl}/${apiURL}`,
      formdata

    )
      .pipe(map(res => {
        return res;
      }));
  }


  DeleteDraft<T>(apiURL: string, formdata): Observable<T[]> {

    return this.http.post<any>(`${environment.apiUrl}/${apiURL}`,
    formdata

  )
    .pipe(map(res => {
      return res;
    }));
  }

}


