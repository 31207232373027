import { Component, Inject, Optional } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeadsService } from 'src/app/shared/services/adminpanel/leads/leads.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
@Component({
  selector: 'app-statusremarks',
  templateUrl: './statusremarks.component.html',
  styleUrls: ['./statusremarks.component.scss']
})
export class StatusremarksComponent   {
  local_data
  commentFC = new FormControl('', [
    Validators.required, 
    Validators.maxLength(30)
  ]); 
  constructor(
    public matSnack: SnackbarService,
    private LeadServices: LeadsService,
    public dialogRef: MatDialogRef<StatusremarksComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: StatusremarksComponent
    
    
    ) {
      this.local_data = { ...data };
     }
  // Close dialog
  onCommentChange() {
    console.log(this.commentFC.invalid);
  } 
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  get alf() { return this.commentFC.value; }
    updateStatus() {
      // alert(this.local_data)
      console.log(this.local_data)
     this.LeadServices.ChangeLeadStatus(`api/lead/updateStatus`,this.local_data.id,this.local_data.value,this.alf)
      .subscribe((res) => {
         console.log(res)
        this.dialogRef.close({ data: res });
        this.matSnack.showNotification("Status Updated", "")
      });
    }
 

}
