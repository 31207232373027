<div fxLayout="column" id="top" class="cardpadding">
    <mat-card fxLayout="column" fxLayoutGap="15px">
        <div class="color-primary backgroud-color-white  ">
            <div fxLayout="row" fxLayoutAlign="space-between">
                <p class="mat-title">Email Status </p>
                <button mat-raised-button color="primary" routerLink="/admin/email">
                    <mat-icon class="cursor-pointer">arrow_back</mat-icon> Back
                </button>
            </div>
        </div>
        <!-- {{dataSource | json}}-->
        <div class="status-list ">
            <table mat-table [dataSource]="this.dataSource" class="mat-elevation-z8">
                <!-- Position Column -->
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef> #</th>
                    <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}}
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Subject </th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="column" fxLayoutGap="5px">
                            <p>{{element.subject}}</p>
                        </div>
                    <td>
                </ng-container>

                <!-- email Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let element">
                        <!-- {{element.emailIds }}-->
                        <mat-chip-list *ngIf="element.emailIds != null">
                            <span *ngFor="let emailIds of element.emailIds.split(',')">
                                <mat-chip class=" mat-chips">{{emailIds}} </mat-chip>
                            </span>
                        </mat-chip-list>
                        <p *ngIf="element.emailIds == null">-</p>
                    <td>
                </ng-container>

                <!-- send Column -->
                <ng-container matColumnDef="send">
                    <th mat-header-cell *matHeaderCellDef> Resend</th>
                    <td mat-cell *matCellDef="let element">
                        <div fxLayout="column" fxLayoutGap="5px">
                            <!--  <p>{{element.isEmailSend}}</p>-->
                            <!--  <button mat-button class="send_btn" routerLink="/admin/email/EmailStatus"
                                [disabled]="getListOfDraftLog || EmailStatus" (click)="saving()">Send</button>-->

                            <button mat-raised-button class="resendbtn" color="primary"
                                [disabled]="this.element.isEmailSend == true" (click)="resendMail(element)">
                                <mat-icon>redo</mat-icon>
                            </button>
                        </div>
                    <td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (mouseleave)="handleMouseLeave(row)"></tr>
            </table>
            <mat-paginator [length]="100" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
        </div>
    </mat-card>
</div>