<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="container backgroud-color-primary">

    <mat-card class="cardsize" fxLayout="column" fxLayoutAlign="center">
        <mat-card-title class="padding-top">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
            <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
        </svg>
        </mat-card-title>
        <mat-card-content class="cardpadding margin-top">
            <div fxLayout="column" fxLayoutAlign="start stretch">
                <span fxLayoutAlign="center" class="success color-primary font-weight-500 font-uppercase mat-h2">
                    Password reset email Sent!
                </span>
                <p>
                    An email has been sent to your email address (<b>{{this.email}}</b>). Follow the directions in the email to reset your password.
                </p>


                <!-- Buttons -->
                <div fxLayout="row" fxLayoutAlign="start stretch" >
                    <button mat-raised-button color="primary" class="full-width" (click)="back()">
                        Back
                    </button>
                </div>

            </div>
        </mat-card-content>
    </mat-card>
</div>