import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { SelectionModel } from '@angular/cdk/collections';
import { EmployeeListService } from 'src/app/shared/services/adminpanel/employee-list.service';
import { Employee } from 'src/app/shared/models/adminpanel/employee';
import { CONSTANT } from 'src/app/shared/utils/constant';
export class ItemStatus {
  name: string;
  checked: boolean;
  id: number;

  constructor(id, name) {
    this.id = id;
    this.name = name;
    this.checked = false;
  }
}

@Component({
  selector: 'app-contactlist',
  templateUrl: './contactlist.component.html',
  styleUrls: ['./contactlist.component.scss']
})
export class ContactlistComponent implements OnInit {

  public variables = ['Parth Shah', 'Tushar Panchal', 'Yash Shah', 'Jay Patel', 'Dhruv Boghani', 'pre'];
  public filteredList1 = this.variables.slice();
  public variables2 = CONSTANT.status;
  public filteredList2 = this.variables2.slice();
  toppings = new FormControl(this.variables2);
  isLoading = true;
  displayedColumns: string[] = ['select', 'position', 'image', 'name', 'email', 'phone'];
  dataSource = new MatTableDataSource<Employee>([]);
  selection = new SelectionModel<Employee>(true, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  color: any;
  employee: any;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private EmployeeListService: EmployeeListService,
    private matSnack: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.getEmployee();
    // this.getEmployeeDatabyid();
    localStorage.removeItem('edit-employee');
    localStorage.removeItem('add-employee');
    localStorage.removeItem('view-employee');
    localStorage.removeItem('employeeData')
  }

  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  handleMouseOver(row) {
    const position = row;
    this.dataSource.data.map((data: any) => {
      if (data === position) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const position = row;
    this.dataSource.data.map((data: any) => {
      if (data === position) {
        data.show = false;
      }
    });
  }

  //Add employee page route

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.employee.forEach(row =>
        this.selection.select(row)
      );
  }

  logSelection() {
    this.selection.selected.forEach(s => console.log(s.name));
  }

  someMethod(event) {
    console.log(event)
    const filterValue = event;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  someMethod1(event) {
    console.log(event)
    const filterValue = event;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /*
    Get employee list api called
  */
  getEmployee() {
    this.EmployeeListService.getEmployee()
      .subscribe((data: Employee[]) => {
        this.isLoading = false;
        this.employee = data;
        this.dataSource.data = data
      });
  }

  /*
    Delete employee Popup
  */
}
