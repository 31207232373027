import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Employee } from 'src/app/shared/models/adminpanel/employee';
import { EmployeeListService } from 'src/app/shared/services/adminpanel/employee-list.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {
  productright;
  quotationright;
  mobileQuery: MediaQueryList;
  count: any = 2;
  fillerNav = Array.from({ length: 3 }, (_, i) => `Nav Item ${i + 1}`);
  userinfo = JSON.parse(localStorage.getItem('currentUser'));

  private _mobileQueryListener: () => void;
  notifications = [
    { text: 'Proposal Declined - QUA-000124', time: '1 day ago' },
    { text: 'Ne lead assigned to you', time: '2 days ago' },
  ]
  currentUser: any;
  notificationCount: any;
  notificationData: any;
  Notificationinterval: any;
  notificationunread: any;
  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    private authenticationService: AuthenticationService,
    private router: Router,
    private observer: BreakpointObserver,
    private EmployeeListService: EmployeeListService,
    private NotificationService:NotificationService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  ngOnInit(): void {
   this.productright= localStorage.getItem('productright')
   console.log(this.productright)
   this.quotationright= localStorage.getItem('quotationright')
   console.log(this.quotationright)
   this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  //  this.getNotification()

  //  this.Notificationinterval = setInterval(() => {
  //   this.getNotification()
  // }, 1000);
  }


  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  stopNotificationinterval() {
    clearInterval(this.Notificationinterval);
  }
  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width > 720) {
      return true;
    } else {
      return false;
    }
  }

  IsNotificationRead(event) {
    console.log(event)
    this.NotificationService.notificationRead(event.notificationId)

    .pipe(first())
    .subscribe(
      data => {

      })
  }

  //Admin logout
  adminlogout() {
    this.authenticationService.logout();
    this.router.navigate(['/admin/login']);
    localStorage.removeItem('productright')
    localStorage.removeItem('quotationright')
    this.stopNotificationinterval()
  }

  getNotification() {
    this.NotificationService.getNotification(`api/Notificaion/ListNotification?userId=${this.currentUser.userId}`)
      .subscribe((data: any) => {
        // console.log(data)
        this.notificationCount = data.unreadCount;
        this.notificationData = data.notifications;
        var unread = []
        this.notificationunread = this.notificationData.find(item => item.isRead === true)

        unread.push(this.notificationunread)

        // console.log(unread.length)
      });
  }
}
