import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LeadsComponent } from './leads/leads.component';
import { AdminRoutingModule } from './admin-routing.module';
import { LoginComponent } from './login/login.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/modules/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { ViewLeadComponent } from './leads/view-lead/view-lead.component';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { SetReminderComponent } from './leads/view-lead/set-reminder/set-reminder.component';
import { AddLeadComponent } from './leads/add-lead/add-lead.component';
import { ImportLeadComponent } from './leads/import-lead/import-lead.component';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { NotesComponent } from './leads/view-lead/notes/notes.component';
import { DeleteNotesComponent } from './leads/view-lead/notes/delete-notes/delete-notes.component';
import { DeleteReminderComponent } from './leads/view-lead/set-reminder/delete-reminder/delete-reminder.component';
import { DeleteLeadComponent } from './leads/delete-lead/delete-lead.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { ViewEmployeeComponent } from './employee-list/view-employee/view-employee.component';
import { DeleteEmployeeComponent } from './employee-list/delete-employee/delete-employee.component';
import { AddEmployeeComponent } from './employee-list/add-employee/add-employee.component';
import { AddQuotationComponent } from './quotation/add-quotation/add-quotation.component';
import { QuotationComponent } from './quotation/quotation.component';
import { DeleteQuotationComponent } from './quotation/delete-quotation/delete-quotation.component';
import { EditQuotationComponent } from './quotation/edit-quotation/edit-quotation.component';
import { ProductsComponent } from './products/products.component';
import { AddProductComponent } from './products/add-product/add-product.component';
import { DeleteProductComponent } from './products/delete-product/delete-product.component';
import { UpdateQuotationComponent } from './quotation/update-quotation/update-quotation.component';
import { DeleteQuotationItemComponent } from './quotation/delete-quotation-item/delete-quotation-item.component';
import { StatusremarksComponent } from './leads/statusremarks/statusremarks.component';
import { SmsComponent } from './sms/sms.component';
import { EmailComponent } from './email/email.component';
import { TemplategalaryComponent } from './templategalary/templategalary.component';
import { ContactlistComponent } from './contactlist/contactlist.component';
import { StatusComponent } from './status/status.component';
import { AddStatusComponent } from './status/add-status/add-status.component';
import { DeleteStatusComponent } from './status/delete-status/delete-status.component';
import { EditStatusComponent } from './status/edit-status/edit-status.component';
import { ImportLeadsAssignComponent } from './leads/import-leads-assign/import-leads-assign.component';
import { FacebookinfoComponent } from './facebookinfo/facebookinfo.component';
import { AddfbinfoComponent } from './facebookinfo/addfbinfo/addfbinfo.component';
import { EditinfoComponent } from './facebookinfo/editinfo/editinfo.component';
import { DeleteinfoComponent } from './facebookinfo/deleteinfo/deleteinfo.component';
import { EditTemplateComponent } from './templategalary/edit-template/edit-template.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ViewTemplateComponent } from './templategalary/view-template/view-template.component';
import { EmailstatusComponent } from './email/emailstatus/emailstatus.component';
import { DeleteDraftComponent } from './templategalary/delete-draft/delete-draft.component';
import { AddDraftComponent } from './templategalary/add-draft/add-draft.component';


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    LeadsComponent,
    LoginComponent,
    DashboardComponent,
    ViewLeadComponent,
    SetReminderComponent,
    AddLeadComponent,
    ImportLeadComponent,
    NotesComponent,
    DeleteNotesComponent,
    DeleteReminderComponent,
    DeleteLeadComponent,
    AddEmployeeComponent,
    EmployeeListComponent,
    ViewEmployeeComponent,
    DeleteEmployeeComponent,
    QuotationComponent,
    AddQuotationComponent,
    DeleteQuotationComponent,
    EditQuotationComponent,
    ProductsComponent,
    AddProductComponent,
    DeleteProductComponent,
    UpdateQuotationComponent,
    DeleteQuotationItemComponent,
    StatusremarksComponent,
    SmsComponent,
    EmailComponent,
    TemplategalaryComponent,
    ContactlistComponent,
    StatusComponent,
    AddStatusComponent,

    DeleteStatusComponent,

    EditStatusComponent,

    ImportLeadsAssignComponent,

    FacebookinfoComponent,

    AddfbinfoComponent,

    EditinfoComponent,

    DeleteinfoComponent,

    EditTemplateComponent,

    ViewTemplateComponent,
    EmailstatusComponent,
    DeleteDraftComponent,
    AddDraftComponent




  ],
  imports: [

    HttpClientModule,
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    AdminRoutingModule,
    MatSelectFilterModule,
    NgxCsvParserModule,
    MatNativeDatetimeModule,
    MatDatetimepickerModule,
    CKEditorModule
  ],
  providers: [DatePipe],
})
export class AdminModule { }
