import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Notes } from 'src/app/shared/models/adminpanel/leads/notes';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  _apiEndPoint: string = '';
  constructor(private http: HttpClient) {
    this._apiEndPoint = environment.Url;
  }

  //Add Lead Notes api
  AddLeadNotes(LeadId,
    Note,
    DateContacted) {
    return this.http.post<any>(`${environment.apiUrl}/api/Lead/AddLeadNote`, {
      LeadId,
      Note,
      DateContacted

    })
      .pipe(map(res => {
        return res;
      }));
  }

  //Update Lead note api
  UpdateLeadNotes(
    apiURL,
    id,
    Note,
    DateContacted) {
    return this.http.post<Notes>(`${environment.apiUrl}/${apiURL}`, {
      id,
      Note,
      DateContacted

    })
      .pipe(map(res => {
        return res;
      }));
  }


  //Get Notes data by id api
  getNotesdatabyid<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this.http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }
  private mapAndCatchError<TData>(response: Observable<TData>): Observable<TData> {

    return response.pipe(
      map((r: TData) => {

        return r;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(null);
      })
    );
  }

  //Delete Note  api
  DeleteNote<T>(apiURL: string, id: any): Observable<T[]> {

    return this.mapAndCatchError(
      this.http.post<T[]>(`${this._apiEndPoint}/${apiURL}`, {
        id
      })
    );
  }
}
