import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LeadsService } from 'src/app/shared/services/adminpanel/leads/leads.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { CONSTANT } from 'src/app/shared/utils/constant';
import * as XLSX from 'xlsx';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-import-lead',
  templateUrl: './import-lead.component.html',
  styleUrls: ['./import-lead.component.scss']
})
export class ImportLeadComponent implements OnInit {
  public variables = ['Parth Shah', 'Tushar Panchal', 'Yash Shah', 'Jay Patel', 'Dhruv Boghani'];
  public filteredList1 = this.variables.slice();
  data;
  public variables2 = CONSTANT.status;
  public filteredList2 = this.variables2.slice();
  public variables3 = ['Demo', 'Facebook', 'calling'];
  public filteredList3 = this.variables3.slice();
  fileName: string = 'SheetJS.xlsx';
  displayedColumns: string[] = ['name', 'position', 'company', 'description', 'country', 'zip', 'city', 'state', 'address', 'email', 'website', 'phone', 'whatsappno', 'leadvalue', 'tags', 'servicecategory', 'aadharcard',];
  dataSource = new MatTableDataSource<any>([]);
  csvRecords: any[] = [];
  header = true;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('fileImportInput', { static: false }) fileImportInput: any;
  csvFile: any;
  csvFileData: any;

  myForm = new FormGroup({
    name: new FormControl('',),
    file: new FormControl('',),
    fileSource: new FormControl('',)
  });
  userinfo: any;
  userId: string;

  constructor(
    private matSnack: SnackbarService, private http: HttpClient,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private LeadServices: LeadsService,
    ) {
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.userinfo = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = this.userinfo.userId
    // console.log(this.userinfo.userid)
  }

  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  get f() {
    return this.myForm.controls;
  }

  //File change function
  onFileChange(event) {

    if (event.target.files.length > 0) {
      this.csvFileData = event.target.files[0]
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }

  //Upload file api call
  submit() {
    // console.log(encodeURIComponent(this.userId))
    this.spinnerService.show();
    this.LeadServices.ImportLeadsByOutSource( this.userId,
      this.data
      
      ).pipe(first())
    .subscribe(
      data => {
          this.router.navigateByUrl('/employee/leads');
        this.matSnack.showNotification('New Lead Added', '')
        this.spinnerService.hide();

      },

      error => {
        console.log(error.error)
      });

    // const formData = new FormData();
    // formData.append('ExcelFile', this.myForm.get('fileSource').value);
    // formData.append('userId', `${this.userId}`);
    // this.http.post(`https://e2ykjrud28.execute-api.ap-south-1.amazonaws.com/Prod/api/lead/ImportLead`, formData)
    //   .subscribe(res => {
    //     this.router.navigateByUrl('/employee/leads');
    //     this.matSnack.showNotification('New Lead Added', '')
    //   })

   
  }



  onFileChange1(evt: any) {
    const target : DataTransfer =  <DataTransfer>(evt.target);
    this.csvFileData = target.files[0]
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;

      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });    
      wb.SheetNames.forEach(sheet => {
        let rowObject = XLSX.utils.sheet_to_json(
          wb.Sheets[sheet]
        );
        this.data = rowObject;
    

      });
     
    

    };

    reader.readAsBinaryString(target.files[0]);

  }
  //Export sample file
  export() {
    window.open('/assets/images/sample_import_file.csv', '_blank');
  }

}
