import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RestmanagerService } from '../../utils/restmanagerService';

@Injectable({
  providedIn: 'root'
})
export class EmployeeListService {
  private restmanagerService: RestmanagerService;
  _apiEndPoint: string = '';

  constructor(private http: HttpClient,
    restmanagerService: RestmanagerService) {
    this.restmanagerService = restmanagerService;
    this._apiEndPoint = environment.Url;
  }

  //Get Employees-list api
  getEmployee() {
    return this.http.get<any[]>(`${environment.apiUrl}/api/auth/EmployeeList`);
  }

  //Get Employee data by id api
  getEmployeedatabyid<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this.http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
     
    );
  }
  private mapAndCatchError<TData>(response: Observable<TData>): Observable<TData> {

    return response.pipe(
      map((r: TData) => {

        return r;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(null);
      })
    );
  }

  //Delete Employee  api
  DeleteEmployee<T>(apiURL: string,UserId): Observable<T[]> {

    return this.mapAndCatchError(
      this.http.post<T[]>(`${this._apiEndPoint}/${apiURL}`, {
        UserId
      })
    );
  }

}
