<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
    <mat-toolbar color="primary" class="example-toolbar">
        <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="grow">
            <div fxLayout="row" fxLayoutAlign="start center">
                <button mat-icon-button (click)="snav.toggle()">
                    <mat-icon>format_align_left</mat-icon>
                </button>
               
                <p class="example-app-name  mat-title" *ngIf="this.userinfo.role[0] != 'Employee'">Admin Panel</p>
                <p class="example-app-name  mat-title" *ngIf="this.userinfo.role[0] === 'Employee'">{{this.userinfo.name}}
                </p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="header">
                <div>
                    <button mat-button [matMenuTriggerFor]="menu" [disableRipple]="true">
                        <img src="/assets/images/user-placeholder.jpg" alt="" class="profile-img">
                    </button>
                    <mat-menu #menu="matMenu" xPosition="after">
                        <button mat-menu-item>My Profile</button>
                        <button mat-menu-item>My Timesheets</button>
                        <button mat-menu-item>Edit Profile</button>
                        <!-- <button mat-menu-item routerLink="/admin/employee/add-employee"
                            *ngIf="this.userinfo.role != 'Employee'">Add Employee</button> -->
                        <!-- <button mat-menu-item routerLink=" /pages/change-password">Change Password</button> -->
                        <button mat-menu-item (click)="adminlogout()">Logout</button>
                    </mat-menu>
                </div>
                <!-- <div fxHide>
                    <button mat-icon-button [matMenuTriggerFor]="menu1">

                        <mat-icon matBadgeColor="accent" [matBadgeHidden]="this.notificationCount == 0" [matBadge]="this.notificationCount ">
                            access_time
                        </mat-icon>
                    </button>
                    <mat-menu #menu1="matMenu" class="my-menu">

                        <div class="notification-container" [ngClass.xs]="'notification-containermobile'">
                            <mat-nav-list *ngIf="this.count == '' ">
                                <mat-list-item>
                                    No Notifications
                                </mat-list-item>
                                <mat-divider></mat-divider>
                            </mat-nav-list>
                            <mat-nav-list *ngFor="let nav of notificationData">
                                <mat-list-item mat-line (click)="IsNotificationRead(nav)"
                                    matTooltip="Info about the action">
                                    <div fxLayout="column" fxLayoutGap="5px" [ngClass]="{'text-bold': nav.isRead === false}">
                                        <p mat-line class="notificationline-hover">
                                            {{nav.notificationTitle}}
                                        </p>

                                        <mat-small mat-line class="notificationtime">

                                            {{nav.sendDate |date:'dd-MM-yyyy hh:mm'}}
                                        </mat-small>
                                    </div>
                                    <button *ngIf="notification.isRead == 'False'" type="button"
                                class="notificationpoint  bordernotification"></button>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                            </mat-nav-list>
                        </div>
                    </mat-menu>
                </div> -->
                <div>
                    <button mat-icon-button [matMenuTriggerFor]="menu1">

                        <mat-icon matBadgeColor="accent" [matBadgeHidden]="this.notificationCount == 0" [matBadge]="this.notificationCount">
                            notifications_active</mat-icon>
                    </button>
                    <mat-menu #menu1="matMenu" class="my-menu">

                        <div class="notification-container" [ngClass.xs]="'notification-containermobile'">
                            <mat-nav-list *ngIf="this.notificationCount == 0">
                                <mat-list-item>
                                    No Notifications
                                </mat-list-item>
                                <mat-divider></mat-divider>
                            </mat-nav-list>
                            <mat-nav-list *ngFor="let nav of this.notificationData" >
                                <mat-list-item mat-line (click)="IsNotificationRead(nav)" [ngClass]="{'text-bold': nav.isRead === false}">
                                    <p mat-line class="notificationline-hover ">
                                        {{nav.notificationTitle}}
                                    </p>

                                    <p mat-line class="notificationtime">
                                        {{nav.sendDate |date:'dd-MM-yyyy hh:mm'}}
                                        <!-- {{this.getNowUTC(notification.createdDateTime) | dateAgo }} -->
                                    </p>
                                    <!-- <button *ngIf="notification.isRead == 'False'" type="button"
                                class="notificationpoint  bordernotification"></button> -->
                                </mat-list-item>
                                <mat-divider></mat-divider>
                            </mat-nav-list>
                        </div>
                    </mat-menu>
                </div>
            </div>

        </div>
    </mat-toolbar>

    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
        <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
            fixedTopGap="56" [opened]="isLargeScreen()" class="backgroud-color-primary">
            <div class="sidebar" *ngIf="this.userinfo.role[0] != 'Employee'">
                <mat-nav-list fxLayout="column" fxLayoutGap="10px">
                    <a mat-list-item routerLink="/admin/dashboard" routerLinkActive="active-list-item">
                        <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="padding-right-side">home</mat-icon>
                            <p>Dashboard</p>
                        </div>
                    </a>
                    <a mat-list-item routerLink="/admin/leads" routerLinkActive="active-list-item">
                        <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="padding-right-side">perm_phone_msg</mat-icon>
                            <p>Leads</p>
                        </div>
                    </a>
                    <a mat-list-item routerLink="/admin/reports" routerLinkActive="active-list-item" fxHide>
                        <mat-icon class="padding-right-side">bar_chart</mat-icon>
                        <p>Reports</p>
                    </a>

                    <a mat-list-item routerLink="/admin/quotation" routerLinkActive="active-list-item" *ngIf="this.quotationright === 'true'">
                        <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="padding-right-side">request_quote</mat-icon>
                            <p>Quotation</p>
                        </div>
                    </a>
                  
                    <a mat-list-item routerLink="/admin/products" routerLinkActive="active-list-item" *ngIf="this.productright === 'true'">
                        <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="padding-right-side">list_alt</mat-icon>
                            <p>Products</p>
                        </div>
                    </a>
                  
                    <a mat-list-item routerLink="/admin/employee" routerLinkActive="active-list-item">
                        <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="padding-right-side">group</mat-icon>
                            <p>Employee</p>
                        </div>
                    </a>


                    <!-- <a mat-list-item routerLink="/admin/sms" routerLinkActive="active-list-item">
                        <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="padding-right-side">message</mat-icon>
                           
                            <p>SMS</p>
                        </div>
                    </a> -->
                    
                    <a mat-list-item routerLink="/admin/status" routerLinkActive="active-list-item">
                        <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="padding-right-side">layers</mat-icon>
                            <p>Status</p>
                        </div>
                    </a>
                    <a mat-list-item routerLink="/admin/email" routerLinkActive="active-list-item">
                        <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="padding-right-side">email</mat-icon>
                            
                            <p>Email</p>
                        </div>
                    </a>
                    <a mat-list-item routerLink="/admin/Campaign" routerLinkActive="active-list-item">
                        <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                            <!-- <mat-icon class="padding-right-side">Facebook</mat-icon> -->
                            <i class="padding-right-side fa fa-facebook-f"></i>
                            <p>Facebook</p>
                        </div>
                    </a>
                </mat-nav-list>
            </div>
           
            <div class="sidebar" *ngIf="this.userinfo.role[0] === 'Employee'">
                <mat-nav-list fxLayout="column" fxLayoutGap="10px">
                    <a mat-list-item routerLink="/employee/dashboard" routerLinkActive="active-list-item">
                        <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="padding-right-side">home</mat-icon>
                            <p>Dashboard</p>
                        </div>
                    </a>
                    <a mat-list-item routerLink="/employee/leads" routerLinkActive="active-list-item">
                        <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="padding-right-side">perm_phone_msg</mat-icon>
                            <p>Leads</p>
                        </div>
                    </a>
                    <a mat-list-item routerLink="/admin/quotation" routerLinkActive="active-list-item" *ngIf="this.quotationright === 'true'">
                        <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="padding-right-side">request_quote</mat-icon>
                            <p>Quotation</p>
                        </div>
                    </a>
                    <a mat-list-item routerLink="/admin/products" routerLinkActive="active-list-item" *ngIf="this.productright === 'true'">
                        <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon class="padding-right-side">list_alt</mat-icon>
                            <p>Products</p>
                        </div>
                    </a>
                </mat-nav-list>
            </div>
        </mat-sidenav>

        <mat-sidenav-content style="background: white;">
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>