import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private matSnackbar: MatSnackBar) { }

  //Successful message
  showNotification(message: string, buttonText: string) {
    this.matSnackbar.open(message, buttonText, {
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: "redNoMatch"
    })
  }

  //Delete message
  showNotification1(message: string, buttonText: string) {
    this.matSnackbar.open(message, buttonText, {
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: "redNoMatch1"
    })
  }

  openSnackBar(message: string, action: string, className: string) {
    this.matSnackbar.open(message, action, {
      duration: 2000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: [className]
    });
  }




  //Successful message
  showNotificationcenter(message: string, buttonText: string) {
    this.matSnackbar.open(message, buttonText, {
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: "redNoMatch2"
    })
  }
}
