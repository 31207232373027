<!-- Material Tab -->
<div fxLayout="column" class="full-padding padding-top" fxLayoutGap="20px">
  
  <mat-card fxLayout="column" fxLayoutAlign="start" fxFlex="32">
    <div fxLayout="row" fxLayoutAlign="end end" >
      <button mat-raised-button color="primary" routerLink="/admin/email/add-template">Add New Draft</button>
    </div>
    <mat-card-title class="color-primary" fxLayout="row" fxLayoutAlign="center center">Templates</mat-card-title>

    <mat-card-content>
      <!-- {{this.ListOfEmailData | json}} -->
      <div class="content">
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
          <div fxFlex="25" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let image of this.ListOfEmailData">

            <mat-card onMouseOver="this.style.backgroundColor='rgba(45, 47, 122, 0.24)'"
              onMouseOut="this.style.backgroundColor='#FFFFFF'" (click)="select(image)" class="cursor-pointer"
              fxFlex="100">
              <!--  <mat-card-header>
                <mat-card-title>Mountains {{num}}</mat-card-title>
              </mat-card-header> -->
              <img mat-card-image src="/assets/images/email.jpg" alt="Free unsplash image">
              <mat-card-content fxLayout="column" fxLayoutAlign="center">
                <p>
                  {{image.subject}}
                </p>
              </mat-card-content>
              <mat-card-actions>
                <mat-icon mat-button [matMenuTriggerFor]="menu">more_horiz</mat-icon>
                <mat-menu #menu="matMenu" xPosition="after">
                  <button mat-menu-item routerLink="/admin/email/edit-template"
                    (click)="editTemplate(image)">Edit</button>
                  <button mat-menu-item routerLink="/admin/email/view-template"
                    (click)="previewTemplate(image)">Preview</button>
                    <button mat-menu-item 
                      (click)="deleteTemplate(image)">Delete</button>
                </mat-menu>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-paginator [pageSize]="size" [pageIndex]="page" [pageSizeOptions]="[4, 8, 12]" (page)="getData($event)">
    </mat-paginator>
  </mat-card>
</div>

<!-- /Material Tab -->