import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import * as jspdf from 'jspdf';

// import { jsPDF } from 'jspdf'

import * as _html2canvas from "html2canvas";

import { Quotation } from 'src/app/shared/models/adminpanel/quotation';
import { AddQuotationService } from 'src/app/shared/services/adminpanel/add-quotation.service';
import { QuotationItem } from 'src/app/shared/models/adminpanel/quotation_item';
const html2canvas: any = _html2canvas;
@Component({
  selector: 'app-view-quotation',
  templateUrl: './view-quotation.component.html',
  styleUrls: ['./view-quotation.component.scss']
})
export class ViewQuotationComponent implements OnInit {
  dataSource_new = new MatTableDataSource<quotation>(quotation_data);
  quotationDataById;
  quotationSubject:string;
  quotationRelated:any;
  quotationDate:any;
  quotationOpenTill:any;
  quotationDiscount:any;
  quotationTags:any;
  quotationStatus:any;
  quotationAssigned:any;
  quotationTo:string;
  quotationAddress:string;
  quotationCity:string;
  quotationState:string;
  quotationCountry:string;
  quotationZipCode:string;
  quotationEmail:string;
  quotationPhone:string;
  tags;
  dataSourcenew: any = [];
  dataSource2 = new MatTableDataSource<any>([]);
  savdata: any;
  discountAmount: any;
  adjustmentAmount: number;
  total: any = 0.00;
  subtotal: number;
  discount: string;
  adjustment: string;
  products: any;
  taxAmountCGST = []
  taxAmountSGST = []
  taxAmounteighteenIGST = [];
  taxAmounteighteenGST = [];
  taxCount2:any;
  filteredListEmployee: any;
  filteredList1: any;
  ItemsData: any;
  rateData: any;
  taxData: any;
  itemData: any;
  ninepercentCGST: any;
  eighteenpercentCGST: number;
  totalTaxAmountCGST: number;
  totalTaxAmountSGST: number;
  totalTaxAmounteighteenIGST: number;
  totalTaxAmounteighteenGST: number;
  ninepercentSGST: number;
  eighteenpercentGST: number;
  eighteenpercentIGST: number;
  allTaxTotal: number;
  assignedChangeName: any;
  relatedChangeName: any;
  discountChange: any;
  statusValueChange: any;
  quotationId: string;
  textSelectedValued: any;
  itemsSelectedValued: any;
  amountItem: any;
  employee: Quotation[];
  amountCountData:any;
  adjustmentExist: any;
  discountExist: any;
  discountAmountExist: any;
  adjustmentAmountExist: any;
  displayedColumns: string[] = ['number', 'items', 'qty', 'rate', 'tax', 'amount'];
  quotationid: any;


  constructor(private addQuotationService: AddQuotationService) { }

  ngOnInit(): void {
    this.quotationid = localStorage.getItem('quotationData');
    this.getQuotationItem()
    this.getQuotationData()
  }

  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  getTotalCost() {
    return this.dataSource2.data.map(t => t.rate).reduce((acc, value) => acc + value, 0);
  }
    totalAmount(data) {
      console.log(data,"total AMount")
      this.subtotal = this.dataSource2.data.map(x=>parseInt(x.rate)*parseInt(x.qty)).reduce((a,b)=> (a+b),0)
      // this.subtotal = this.dataSource2.data.map(function (a) {
      //   console.log(a,"a data")
      //    return a["Rate"]; 
      //   }).reduce((partial_sum, a) => partial_sum + parseInt(a), 0)
      if (this.adjustmentAmount === undefined && this.discountAmount === undefined) {
        this.total = this.subtotal
      } else if (this.adjustmentAmount === undefined && this.discountAmount != undefined) {
        this.total = this.total
        this.discountCount(this.discount, this.subtotal)
        this.total = this.discountAmount + this.subtotal
      } else if (this.adjustmentAmount != undefined && this.discountAmount === undefined) {
        this.total = parseInt(this.total)
        this.total = this.adjustmentAmount + this.subtotal
        // this.adjustmentCount(this.adjustment)
      } else {
        this.discountCount(this.discount, this.subtotal)
        // this.adjustmentCount(this.adjustment)
        this.total = this.subtotal + this.discountAmount + this.adjustmentAmount
      }
    }

    discountCount(discount, subtotal) {
      if(discount == null)
      { 
        var discount2= this.discountAmountExist;
        console.log(discount2,"subtotal")
        this.discountAmount = ((discount2) * (this.subtotal) )/ 100
        console.log(this.discountAmount,"discount function")
        if (this.adjustmentAmount != undefined) {
          this.total = Number(this.adjustmentAmount) + this.discountAmount + this.subtotal
          // this.total = this.discountAmount + subtotal
          // console.log(this.total)
        } else if (this.adjustmentAmount === undefined) {
          this.total = this.discountAmount + this.subtotal
          // console.log(this.total)
        }
      }
      if (discount != null)
      { 
        console.log(discount,"subtotal")
        this.discountAmount = -((discount) * (this.subtotal) )/ 100
        console.log(this.discountAmount,"discount function")
        if (this.adjustmentAmount != undefined) {
          this.total = Number(this.adjustmentAmount) + this.discountAmount + this.subtotal
          // this.total = this.discountAmount + subtotal
          // console.log(this.total)
        } else if (this.adjustmentAmount === undefined) {
    
          this.total = this.discountAmount + this.subtotal
          // console.log(this.total)
        }
      }
  
    }

    adjustmentCount(adjustment) {
      this.adjustmentAmount = Number(adjustment)
      console.log(this.adjustmentAmount,"adjustmentamount from function")
      if (this.discountAmount != undefined) {
        this.total = Number(this.adjustmentAmount) + this.discountAmount + this.subtotal
        // this.total = this.discountAmount + this.subtotal
        // console.log(this.total)
      } else if (this.discountAmount === undefined) {
        this.total = this.adjustmentAmount + this.subtotal
        // console.log(this.total)
      }
    }
  // get data by id
  getQuotationData(){
    var quotationData = localStorage.getItem('quotationData')
    // console.log(quotationData,"quotationdata")
    this.addQuotationService
    .getQuotationById<Quotation>(`api/Quotation/quotation?quotationId=${quotationData}`)
    .subscribe((data: Quotation[]) => {
      // console.log(data)
     
      this.quotationDataById = data;
      this.quotationSubject  = this.quotationDataById.subject;
      this.quotationRelated  = this.quotationDataById.related;
      this.quotationDate  = this.quotationDataById.date;
      this.quotationOpenTill  = this.quotationDataById.openTill;
      this.quotationDiscount  = this.quotationDataById.discountType;
      this.tags  = this.quotationDataById.Favoritetags;
      console.log("data.................split(',')",this.quotationDataById)
     
      this.quotationStatus  = this.quotationDataById.status;
      this.quotationAssigned  = this.quotationDataById.assigned;
      this.quotationTo  = this.quotationDataById.toPerson;
      this.quotationAddress  = this.quotationDataById.address;
      this.quotationCity  = this.quotationDataById.city;
      this.quotationState  = this.quotationDataById.state;
      this.quotationCountry  = this.quotationDataById.country;
      this.quotationZipCode  = this.quotationDataById.zipCode;
      this.quotationEmail  = this.quotationDataById.email;
      this.quotationPhone  = this.quotationDataById.phone;
      this.total = this.quotationDataById.total
      this.discountAmountExist = this.quotationDataById.discount
      this.adjustmentAmountExist = this.quotationDataById.adjustment
      var newAdjust = this.adjustmentAmountExist
      this.adjustmentCount(newAdjust)
      function add(accumulator, a) {
        return accumulator + a;
      }
      const sum = [1, 2, 3].reduce(add, 0);

    })

  } 

  taxCount(tax, amount) {
    console.log(tax,"taxcount")
    console.log(amount," amount for tax")
    for (let index = 0; index < tax.length; index++) {
      const element = tax[index];
      var subElement = element.split(",")
      console.log(subElement,"subElement")
        if(subElement.length > 0){
        for (let index2 = 0;index2 < subElement.length;index2++)
        {
          var newElement =subElement[index2]
          console.log(newElement,"loop number")
          if (newElement === '9.00% CGST') {
            var taxamount = 9
            console.log((parseInt(amount) * taxamount) / 100)
            this.ninepercentCGST = (parseInt(amount) * taxamount) / 100
            this.taxAmountCGST.push(this.ninepercentCGST)
          }
          if (newElement === '9.00% SGST' ) {
            var taxamount = 9
            console.log((parseInt(amount) * taxamount) / 100)
            this.ninepercentSGST = (parseInt(amount) * taxamount) / 100
            this.taxAmountSGST.push(this.ninepercentSGST)
          }
          if (newElement === '18.00% GST'   ) {
            var taxamount = 18
            console.log((parseInt(amount) * taxamount) / 100)
            this.eighteenpercentGST = (parseInt(amount) * taxamount) / 100
            this.taxAmounteighteenGST.push(this.eighteenpercentGST)
          }
          if (newElement === '18.00% IGST'  ) {
            var taxamount = 18
            console.log((parseInt(amount) * taxamount) / 100)
            this.eighteenpercentIGST = (parseInt(amount) * taxamount) / 100
            this.taxAmounteighteenIGST.push(this.eighteenpercentIGST)
          }
        }

       }
        if (element === '9.00% CGST') {
          var taxamount = 9
          console.log((parseInt(amount) * taxamount) / 100)
          this.ninepercentCGST = (parseInt(amount) * taxamount) / 100
          this.taxAmountCGST.push(this.ninepercentCGST)
        }
        if (element === '9.00% SGST' ) {
          var taxamount = 9
          console.log((parseInt(amount) * taxamount) / 100)
          this.ninepercentSGST = (parseInt(amount) * taxamount) / 100
          this.taxAmountSGST.push(this.ninepercentSGST)
        }
        if (element === '18.00% GST'   ) {
          var taxamount = 18
          console.log((parseInt(amount) * taxamount) / 100)
          this.eighteenpercentGST = (parseInt(amount) * taxamount) / 100
          this.taxAmounteighteenGST.push(this.eighteenpercentGST)
        }
        if (element === '18.00% IGST'  ) {
          var taxamount = 18
          console.log((parseInt(amount) * taxamount) / 100)
          this.eighteenpercentIGST = (parseInt(amount) * taxamount) / 100
          this.taxAmounteighteenIGST.push(this.eighteenpercentIGST)
        }
    }
    console.log(this.taxAmountCGST.reduce((a, b) => a + b, 0));
    console.log(this.taxAmountSGST.reduce((a, b) => a + b, 0));
    console.log(this.taxAmounteighteenIGST.reduce((a, b) => a + b, 0));
    console.log(this.taxAmounteighteenGST.reduce((a, b) => a + b, 0));
    this.totalTaxAmountCGST = this.taxAmountCGST.reduce((a, b) => a + b, 0);
    this.totalTaxAmountSGST = this.taxAmountSGST.reduce((a, b) => a + b, 0);
    this.totalTaxAmounteighteenIGST = this.taxAmounteighteenIGST.reduce((a, b) => a + b, 0);
    this.totalTaxAmounteighteenGST = this.taxAmounteighteenGST.reduce((a, b) => a + b, 0);
    this.allTaxTotal = this.totalTaxAmountCGST + this.totalTaxAmountSGST + this.totalTaxAmounteighteenIGST + this.totalTaxAmounteighteenGST
  }
  getQuotationItem() {
    var quotationData = localStorage.getItem('quotationData')
    // this.spinner.show();
    this.addQuotationService.getQuotationItem<QuotationItem>(`api/Quotation/Items?quotationid=${quotationData}`)
      .subscribe((data: QuotationItem[]) => {
        setTimeout(()=>{   
          this.dataSource2.data = data
          this.getQuotationData()
          this.subtotal = this.dataSource2.data.map(x=>parseInt(x.rate)*parseInt(x.qty)).reduce((a,b)=> (a+b),0)
          this.discountCount(Math.abs(this.discountAmountExist), this.subtotal)
          var amoutNew =this.dataSource2.data.map(x=>parseInt(x.rate)*parseInt(x.qty))
          var discountTypenew =this.dataSource2.data.map(x=>x.tax)
          this.taxCount(discountTypenew,amoutNew)
          //  console.log(discountTypenew,"discount from get API")
          //  console.log(this.discountType,"discountType get API")
          this.totalAmount(this.dataSource2)
        }, 1000);
        // this.spinner.hide();

        // console.log(this.dataSource2.data ,"data table")
      });
  }

  download() {

    var data = document.getElementById('contentToConvert');
    // html2canvas(data as any).then(canvas => {
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   const contentDataURL = canvas.toDataURL('image/png');
    //   let pdfData = new jspdf('p', 'mm', 'a4');
    //   var position = 0;
    //   pdfData.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
    //   pdfData.save(`MyPdf.pdf`);
    // });
    html2canvas(data, {
      logging: false,
      scale: 3
    }).then(function (canvas) {
      var pdf = new jspdf('h', 'mm', 'a4', true);//A4 paper, portrait
      var ctx = canvas.getContext('2d'),
        a4w = 195.9, a4h = 279.4,//A4 size,279.4 215.9mm x 279.4mm, 10 mm margin on each side, display area 190x277
        imgHeight = Math.floor(a4h * canvas.width / a4w),//Convert pixel height of one page image to A4 display scale

        renderedHeight = 0;
      console.log(imgHeight)
      var logo = document.getElementById("logo");//Icon placed in header
      while (renderedHeight < canvas.height) {
        var page = document.createElement("canvas");
        page.width = canvas.width;
        page.height = Math.min(imgHeight, canvas.height - renderedHeight);//Maybe less than one page
        page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);

        pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 20, a4w, Math.min(a4h, a4w * page.height / page.width));
        pdf.setFontSize(18)
        renderedHeight += imgHeight;
        if (renderedHeight < canvas.height) {
          pdf.addPage();//Add an empty page if there is more to follow

        }


        // var page = document.createElement("canvas");
        // page.width = canvas.width;
        // page.height = Math.min(imgHeight, canvas.height - renderedHeight);//Maybe less than one page

        // //Trim the specified area with getImageData and draw it into the canvas object created earlier
        // page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);
        // //Add an image to the page with a 10 mm / 20 mm margin
        // pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 20, a4w, Math.min(a4h, a4w * page.height / page.width));
        // //Add header logo
        // // pdf.setTextColor(23, 99, 145);
        // // pdf.setFontSize(30)
        // // pdf.text('CRM', 10, 10, {
        // //   align: 'left'
        // // });
        // pdf.addImage(logo, 'PNG', 5, 5);

        // renderedHeight += imgHeight;
        // if (renderedHeight < canvas.height) {
        //   pdf.addPage();//Add an empty page if there is more to follow

        // }

        // addFooters(pdf)

      }

      pdf.save('Quotation.pdf');
    });
  }
}
const quotation_data: quotation[] = [
  {
    items: 'Digital Marketing Proposal ', qty: '1', rate: '100.00', unit: '10', tax: '9.00% CGST,9.00% SGST%', amount: '100.00'
  },
  {
    items: 'New Product for quotation', qty: '1', rate: '120.00', unit: '10', tax: '9.00% CGST', amount: '120.00'
  }

];
export interface quotation {
  items: string;
  qty: string;
  unit: string;
  rate: string;
  tax: string;
  amount: string;
}