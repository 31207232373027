import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotesService } from 'src/app/shared/services/adminpanel/leads/notes.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-delete-notes',
  templateUrl: './delete-notes.component.html',
  styleUrls: ['./delete-notes.component.scss']
})
export class DeleteNotesComponent implements OnInit {

  local_data: any;
  action: any;

  constructor(
    public dialogRef: MatDialogRef<DeleteNotesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DeleteNotesComponent,
    public matSnack: SnackbarService,
    private LeadNotesServices: NotesService
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  ngOnInit(): void {
  }

  // Close dialog
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  //Delete notes api call
  reject() {
    this.LeadNotesServices.DeleteNote(`api/Lead/DeleteLeadNote`,`${this.local_data.id}`)
      .subscribe((res) => {
        this.dialogRef.close({ data: res });
        this.matSnack.showNotification1("Note Deleted", "")
      });
  }

}
