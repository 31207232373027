<div class="background-image" [ngClass.lt-sm]="{'background-image_sm':true,'background-image':false}">
  <!-- Banner -->
  <div fxLayout="row" class="container"  fxLayout.lt-sm="column">
    <div fxFlex="50" fxFlex.lt-sm="100" class="hero_txt" fxLayout="column" fxLayoutAlign="center center"  >
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="txt_bold" [ngClass.lt-md]="{'txt_bold_sm':true,'txt_bold':false}" fxLayout="column" >
          <span>Providing</span>
          <span>good relations</span>
          <span>With customers.</span>
        </div>
        <div>
          <p fxFlex="50%" fxFlex.lt-md="100%" class="hero-subTxt" [ngClass.lt-md]="{'hero-subTxt':false}">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
        <div fxLayoutGap="5px">
          <a mat-raised-button
            class="btn-highLight"
            id="btn-highLight"
            color="warn"
            routerLink="/admin/login">Let's get started</a>
          <button color="warn" mat-button>
              <mat-icon>play_arrow</mat-icon>
              Watch the video
          </button>
        </div>
      </div>
    </div>
    <div fxFlex="50" fxLayoutAlign="center stretch" fxHide.lt-sm>
      <img class="hero-image" src="../../../../assets/images/website/hero_right.png">
    </div>
  </div>
  <!-- Start Free Trial -->
  <div class="elipse_bg" [ngClass.lt-md]="{'elipse_bg_sm':true,'elipse_bg':false}">
    <div  fxLayout="row" fxLayoutAlign="center center" style="padding:5%" fxLayoutGap="2%">
      <div  class="you-tube-image" fxHide.lt-md>
        <img  class="cornerimage"  src="../../../../assets/images/website/crm_youtube.png">
      </div>
      <div  fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="15px">
          <span class="mat-display-1 labels no-margin">Start Free Trial Now...!</span>
          <form class="example-form">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput required>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Phone Number</mat-label>
              <input matInput required>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput required>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Company</mat-label>
              <input matInput >
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Website</mat-label>
              <input matInput >
            </mat-form-field>
            <button class="example-full-width btn-send" mat-raised-button>Send</button>
          </form>
      </div>
    </div>
   </div>

 <!-- Service -->
 <div class="mat-h1 sm-font" [ngClass.lt-md]="{'container':true}" fxLayoutAlign="space-around center" fxLayoutAlign.lt-md="center center">
   SmokyCRM Development Services
 </div>
 <div class="sub-container" fxLayout="row" fxLayoutGap.lt-md="15px" fxLayout.lt-md="column"  fxLayoutAlign="center center">
  <div fxLayout="row" fxLayoutGap="-5%" fxLayoutAlign="center center">
    <img  class="img_laptop" [ngClass.lt-md]="{'img_laptop':false,'img_laptop_sm':true}"   src="../../../../assets/images/website/crm_mockup.png">
    <img class="img_mobile" [ngClass.lt-md]="{'img_mobile':false,'img_mobile_sm':true}"  src="../../../../assets/images/website/mobile-1.png">
  </div>
  <div  fxLayout="column" [ngClass.lt-md]="{'container':true}">
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="1%">
      <img width="50px" height="40px" src="../../../../assets/images/website/security xd-1.png">
      <p class="hero-subTxt">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="1%">
      <img width="50px" height="40px" src="../../../../assets/images/website/security xd-1.png">
      <p class="hero-subTxt">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="1%">
      <img width="50px" height="40px" src="../../../../assets/images/website/security xd-1.png">
      <p class="hero-subTxt">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="1%">
      <img width="50px" height="40px" src="../../../../assets/images/website/security xd-1.png">
      <p class="hero-subTxt">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </p>
    </div>
  </div>
 </div>

  <!-- Offer -->
  <div fxLayout="column"  fxLayoutAlign="space-around center">
    <span class="mat-h1 "> What We Offer?</span>
    <p class="hero-subTxt offer_subText" [ngClass.lt-md]="{'container':true}">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor inci didunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua.
    </p>
  </div>
  <!-- Offer content -->
  <div class="offer-container">
    <mat-card fxLayout="row" class="offer-matCard" fxLayout.lt-sm="column" fxLayoutGap="5%">
      <mat-card class="offer-subCard" fxLayout="column" fxFlex="30%">
        <button mat-fab class="color-brown" aria-label="Example icon button with a menu icon">
          <mat-icon>manage_accounts</mat-icon>
        </button>
        <p class="mat-h1">User Management</p>
        <p class="hero-subTxt offer-subContent">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
        <div fxLayout="row">
          <a class="labels ">More</a>
          <mat-icon  class="labels ">chevron_right</mat-icon>
        </div>
      </mat-card>
      <mat-card class="offer-subCard" fxLayout="column" fxFlex="30%">
        <button mat-fab class="color-green" aria-label="Example icon button with a menu icon">
          <mat-icon>admin_panel_settings</mat-icon>
        </button>
        <p class="mat-h1">Role Management</p>
        <p class="hero-subTxt offer-subContent">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
        <div fxLayout="row">
          <a class="labels ">More</a>
          <mat-icon  class="labels ">chevron_right</mat-icon>
        </div>
      </mat-card>
      <mat-card class="offer-subCard" fxLayout="column" fxFlex="30%">
        <button mat-fab color="accent" aria-label="Example icon button with a menu icon">
          <mat-icon>category</mat-icon>
        </button>
        <p class="mat-h1">Product Management</p>
        <p class="hero-subTxt offer-subContent">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
        <div fxLayout="row">
          <a class="labels ">More</a>
          <mat-icon  class="labels ">chevron_right</mat-icon>
        </div>
      </mat-card>
    </mat-card>
  </div>
  <!-- Offer content 2-->
  <div class="offer-container">
    <mat-card fxLayout="row" fxLayout.lt-sm="column" class="offer-matCard" fxLayoutGap="5%">
      <mat-card class="offer-subCard" fxLayout="column" fxFlex="30%">
        <button mat-fab class="color-pink" aria-label="Example icon button with a menu icon">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
        <p class="mat-h1">Load Addition</p>
        <p class="hero-subTxt offer-subContent">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
        <div fxLayout="row">
          <a class="labels ">More</a>
          <mat-icon  class="labels ">chevron_right</mat-icon>
        </div>
      </mat-card>
      <mat-card class="offer-subCard" fxLayout="column" fxFlex="30%">
        <button mat-fab class="color-yellow" aria-label="Example icon button with a menu icon">
          <mat-icon>hub</mat-icon>
        </button>
        <p class="mat-h1">Lead Distribution</p>
        <p class="hero-subTxt offer-subContent">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
        <div fxLayout="row">
          <a class="labels ">More</a>
          <mat-icon  class="labels ">chevron_right</mat-icon>
        </div>
      </mat-card>
      <mat-card class="offer-subCard" fxLayout="column" fxFlex="30%">
        <button mat-fab class="color-lightPink" aria-label="Example icon button with a menu icon">
          <mat-icon>format_quote</mat-icon>
        </button>
        <p class="mat-h1">Quotation</p>
        <p class="hero-subTxt offer-subContent">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
        <div fxLayout="row">
          <a class="labels ">More</a>
          <mat-icon  class="labels ">chevron_right</mat-icon>
        </div>
      </mat-card>
    </mat-card>
  </div>
  <!-- Offer content 3-->
  <div class="offer-container">
    <mat-card fxLayout="row" fxLayout.lt-sm="column" class="offer-matCard" fxLayoutGap="5%">
      <mat-card class="offer-subCard" fxLayout="column" fxFlex="30%">
        <button mat-fab class="color-purple" aria-label="Example icon button with a menu icon">
          <mat-icon>search</mat-icon>
        </button>
        <p class="mat-h1">in Depth Analytics</p>
        <p class="hero-subTxt offer-subContent">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
        <div fxLayout="row">
          <a class="labels ">More</a>
          <mat-icon  class="labels ">chevron_right</mat-icon>
        </div>
      </mat-card>
      <mat-card class="offer-subCard" fxLayout="column" fxFlex="30%">
        <button mat-fab class="color-lightGreen" aria-label="Example icon button with a menu icon">
          <mat-icon>question_answer</mat-icon>
        </button>
        <p class="mat-h1">Message Integration</p>
        <p class="hero-subTxt offer-subContent">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
        <div fxLayout="row">
          <a class="labels ">More</a>
          <mat-icon  class="labels ">chevron_right</mat-icon>
        </div>
      </mat-card>
      <mat-card class="offer-subCard" fxLayout="column" fxFlex="30%">
        <button mat-fab class="color-lightPurple" aria-label="Example icon button with a menu icon">
          <mat-icon>notifications_none</mat-icon>
        </button>
        <p class="mat-h1">Notification</p>
        <p class="hero-subTxt offer-subContent">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
        <div fxLayout="row">
          <a class="labels ">More</a>
          <mat-icon  class="labels ">chevron_right</mat-icon>
        </div>
      </mat-card>
    </mat-card>
  </div>
   <!-- Brand & Client -->
  <div fxLayout="column"  fxLayoutAlign="space-around center">
    <span class="mat-h1 sm-font" [ngClass.lt-md]="{'container':true}" > Work For All This Brand & Client</span>
    <p class="hero-subTxt offer_subText" [ngClass.lt-md]="{'container':true}">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor inci didunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua.
    </p>
  </div>
  <!-- Brand & Client Content -->
  <div class="offer-container" fxLayoutAlign="center" fxShow fxShow.sm fxHide.lt-sm>
    <div  class="nestedCircle"  fxLayoutAlign="center center">
      <div class="nestedSecond" fxLayoutAlign="center center">
        <div class="nestedThird" fxLayoutAlign="center center">
          <div fxLayoutAlign="center center">
            <img width="100px;" src="../../../../assets/images/website/Mask Group 2.png">
          </div>
          <div fxLayoutAlign="center center">
            <img width="80px;" src="../../../../assets/images/website/suncad-logo-default-min-e1634197949236.png">
          </div>
          <div fxLayoutAlign="center center">
            <img width="100px" src="../../../../assets/images/website/Mask Group 3.png">
          </div>
          <div fxLayoutAlign="center center">
            <img width="80px" src="../../../../assets/images/website/mile1.png">
          </div>
          <div fxLayoutAlign="center center">
            <img width="80px" src="../../../../assets/images/website/FLS-logo-logo-fa-min-1024x581-1.png">
          </div>
          <div fxLayoutAlign="center center">
            <img width="100px" src="../../../../assets/images/website/Mask Group 1.png">
          </div>
          <div fxLayoutAlign="center center">
            <img width="90px" src="../../../../assets/images/website/bsm.png">
          </div>
          <span class="nestedFourth"  fxLayoutAlign="center center" fxLayoutGap="-10px">
            <button mat-fab class="color-pink" aria-label="Example icon button with a menu icon">
            </button>
            <button mat-fab class="color-yellow" aria-label="Example icon button with a menu icon">
            </button>
            <button mat-fab class="color-lightGreen" aria-label="Example icon button with a menu icon">
            </button>
          </span>
      </div>
    </div>
  </div>
 </div>

  <!--Small screen Brand & Client Content -->
 <div class="offer-container_sm" fxLayoutAlign="center" fxHide fxHide.sm fxShow.lt-sm>
  <div class="nestedSecond_sm" fxLayoutAlign="center center">
    <div class="nestedThird_sm" fxLayoutAlign="center center">
      <div fxLayoutAlign="center center">
        <img width="50px;" src="../../../../assets/images/website/Mask Group 2.png">
      </div>
      <div fxLayoutAlign="center center">
        <img width="50px;"  src="../../../../assets/images/website/suncad-logo-default-min-e1634197949236.png">
      </div>
      <div fxLayoutAlign="center center" >
        <img width="50px" src="../../../../assets/images/website/Mask Group 3.png">
      </div>
      <div fxLayoutAlign="center center">
        <img width="50px" src="../../../../assets/images/website/mile1.png">
      </div>
      <div fxLayoutAlign="center center">
        <img width="50px" src="../../../../assets/images/website/FLS-logo-logo-fa-min-1024x581-1.png">
      </div>
      <div fxLayoutAlign="center center" >
        <img width="50px" src="../../../../assets/images/website/Mask Group 1.png">
      </div>
      <div fxLayoutAlign="center center">
        <img width="50px" src="../../../../assets/images/website/bsm.png">
      </div>
      <span class="nestedFourth"  fxLayoutAlign="center center" fxLayoutGap="-30px">
        <button mat-fab class="color-pink_sm" aria-label="Example icon button with a menu icon">
        </button>
        <button mat-fab class="color-yellow_sm" aria-label="Example icon button with a menu icon">
        </button>
        <button mat-fab class="color-lightGreen-sm" aria-label="Example icon button with a menu icon">
        </button>
      </span>
  </div>
</div>
</div>
  <!-- Testimonial   -->
  <div  fxLayoutAlign="space-around center"  [ngClass.lt-md]="{'container':true}"  >
    <span class="mat-h1 sm-font" > Let's See What Our Client Think</span>
  </div>
  <!-- Testimonial   Content-->
  <div class="offer-container" fxLayoutAlign="center">
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="50px">
      <div fxLayout="column" fxLayoutAlign="start start" fxFlex="50" fxFlexOrder.lt-md="2">
        <div class="labels Testimonial-Name">Ricky Walton</div>
        <p class="Testimonial-subName">Developer</p>
        <div fxLayout="row" fxLayoutGap="25px">
          <mat-icon class="rate-icon">star_rate</mat-icon>
          <mat-icon class="rate-icon">star_rate</mat-icon>
          <mat-icon class="rate-icon">star_rate</mat-icon>
          <mat-icon class="rate-icon">star_rate</mat-icon>
          <mat-icon class="rate-icon">star_rate</mat-icon>
        </div>
        <p class="Testimonial">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor inci didunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div fxLayout="row" fxLayout="center" fxFlex="50">
          <div>
            <a mat-raised-button
          class="btn-highLight"
          id="btn-highLight"
          color="warn"
          routerLink="/home">Previous</a>
          </div>
          <div>
            <button color="warn" mat-button>
              Next
          </button>
          </div>
        </div>
      </div>
      <div fxLayoutAlign="center"  fxFlex="50" fxFlexOrder.lt-md="1">
        <div  class="Testimonial-imgContainer" [ngClass.lt-md]="{'Testimonial-imgContainer_sm':true,'Testimonial-imgContainer':false}" fxLayoutAlign="center center">
          <img  src="../../../../assets/images/website/Mask Group 4.png">
        </div>
      </div>
    </div>
  </div>
    <!-- Start Free Trial -->
    <div >
      <div class="elipse_bg" [ngClass.lt-md]="{'elipse_bg_sm':true,'elipse_bg':false}" fxLayout="row"  >
        <div fxFlex="50" fxLayoutAlign="end center" fxHide.lt-sm>
          <img width="500" height="500" src="/assets/images/website/work.png">
        </div>
        <div fxFlex="40" fxFlex.lt-sm="100" fxLayout="column" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center" [ngClass.lt-md]="{'container':true}">
          <span class="mat-h1 sm-font">Interested To Buy From Us?</span>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor inci didunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor inci didunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <div fxLayout="row">
            <a class="labels ">Smokycrm.mm.@gmail.com</a>
            <mat-icon  class="labels ">chevron_right</mat-icon>
          </div>
        </div>
      </div>
     </div>
</div>
