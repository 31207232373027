<div class="container bg">
    <div fxLayout="row" fxLayout.lt-sm="column"  fxLayout.lt-sm="center center" fxLayoutAlign="center start"  fxLayoutGap.gt-md="10%">
        <div  fxLayout="column" fxLayoutAlign.lt-sm="center center" [ngClass.lt-sm]="{'no-margin-sm':true}"  fxLayoutGap="20px" fxFlex.lt-sm="100">
            <span class="mat-h1 labels"> Smoky CRM</span>
            <div class="hero-subTxt" fxLayout.lt-sm="column"  fxLayoutAlign.lt-sm="center center"  fxFlex="20px" fxFlex.lt-sm="100">
               <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor inci didunt ut labore et dolore magna aliqua.</p>
                <div fxLayout="row" fxLayoutGap="20px">
                    <div class="social_Icons"  fxLayoutAlign="space-around center" >
                        <img src="../../../assets/images/website/youTube.png">
                    </div>
                    <div class="social_Icons twitter"  fxLayoutAlign="space-around center" >
                        <img src="../../../assets/images/website/twitter.png">
                    </div>
                    <div class="social_Icons"  fxLayoutAlign="space-around center" >
                        <img src="../../../assets/images/website/gmail.png">
                    </div>
                    <div class="social_Icons"  fxLayoutAlign="space-around center" >
                        <img src="../../../assets/images/website/fb.png">
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div  fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="center center"  fxLayoutGap="10px">
                <span class="mat-h1">Company</span>
                <a >Home</a>
                <a >Our Expertise</a>
                <a >Services</a>
                <a >Blog</a>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="center center"  fxLayoutGap="10px">
            <span class="mat-h1">Contact</span>
            <pre>+91 88804 10214</pre>
            <a >smokycrm.mm.@gmail.com</a>
            <a >https://smokycrm.com</a>
            <a >Ahemdabad,India.</a>
        </div>
    </div>
</div>
