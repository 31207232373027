import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { NotesService } from 'src/app/shared/services/adminpanel/leads/notes.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  updateNotesGroup: FormGroup
  local_data: any;
  action: any;
  notesData: any;
  constructor(
    private LeadNotesServices: NotesService,
    public dialogRef: MatDialogRef<NotesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: NotesComponent,
    private matSnack: SnackbarService
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  ngOnInit(): void {
    this.notesData = this.local_data.note
    this.updateNotesGroup = new FormGroup({
      updatenotes: new FormControl('', Validators.required)
    })
  }

  // Close dialog
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  //updatenotesform controls
  get unlf() { return this.updateNotesGroup.controls; }

  //Update notes api call
  UpdateNotes() {
    this.LeadNotesServices.UpdateLeadNotes(
      `api/Lead/UpdateLeadNote`,
      this.local_data.id,
      this.unlf.updatenotes.value,
      this.local_data.date_contacted
    ).pipe(first())
      .subscribe(
        data => {
          this.dialogRef.close({ data: data });
          this.matSnack.showNotification("Note Updated", "")
        },
        error => {
          console.log(error.error)
        });
  }
}
