import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Quotation } from '../../models/adminpanel/quotation';
import { QuotationItem } from '../../models/adminpanel/quotation_item';
@Injectable({
  providedIn: 'root'
})
export class AddQuotationService {
  _apiEndPoint: string = '';
  constructor(private http: HttpClient,) { 
    this._apiEndPoint = environment.Url;
  }
    //Get quotation-list api
    getQuotation() {
      return this.http.get<any[]>(`${environment.apiUrl}/api/Quotation/quotationList`);
    }
      //Get quotation data by id api
    getQuotationById<T>(apiURL: string): Observable<T[]> {
    return this.mapAndCatchError(
      this.http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }
    //Add quotation api
    AddQuotation(Email: string,
      Phone: string,
      Subject: string,
      Related:string,
      date:string, 
      OpenTill:string,
      DiscountType:string,
      Favoritetags:string,
      Status:string,
      Assigned: string,
      Toperson:string,
      Address: string,
      City: string,
      State: string,
      Country: string,
      ZipCode: string,
      Total: string,Quatation_items,Discount:string,Adjustment:string) {
      return this.http.post<Quotation>(`${environment.apiUrl}/api/Quotation/quotationCreate`, {
        Email,Phone,Subject,Related,date,OpenTill,DiscountType,Favoritetags,Status, 
        Assigned,Toperson,Address,City,State,Country,ZipCode,Total,Quatation_items,Discount,Adjustment
      })
        .pipe(map(res => {
          return res;
        }));
    }
    //Update quotation api
    UpdateQuotation(
      apiURL: string,
      quotationId:string,
      Email: string,
      Phone: string,
      Subject: string,
      Related:string,
      date:string, 
      OpenTill:string,
      DiscountType:string,
      Favoritetags:string,
      Status:string,
      Assigned: string,
      Toperson:string,
      Address: string,
      City: string,
      State: string,
      Country: string,
      ZipCode: string,
      Total: string,Discount:string,Adjustment:string) {
      return this.http.post<Quotation>(`${environment.apiUrl}/${apiURL}`, {quotationId,
        Email,Phone,Subject,Related,date,OpenTill,DiscountType,Favoritetags,Status, 
        Assigned,Toperson,Address,City,State,Country,ZipCode,Total,Discount,Adjustment
      })
        .pipe(map(res => {
          return res;
        }));
    }

    // Add Quotation Item
    AddQuotationItem(Items:string,Description:string,Qty:string,Rate:string,Tax:string,quotationId:string){
      return this.http.post<QuotationItem>(`${environment.apiUrl}/api/Quotation/addItem`, {
        Items,
        Description,
        Qty,
        Rate,
        Tax,
        quotationId
      })
      .pipe(map(res => {
        return res;
      }));
  }
    // update Quotation Item
    // ,quotationId:string
    UpdateQuotationItem(apiURL: string,itemId:string,Items:string,Description:string,Qty:string,Rate:string,Tax:string){ 
      return this.http.post<QuotationItem>(`${environment.apiUrl}/${apiURL} `, {
        itemId,
        Items,
        Description,
        Qty,
        Rate,
        Tax,
       
        // amount
      })
      .pipe(map(res => {
        return res;
      }));
  }

  // Get quotation Items 
 getQuotationItem<T>(apiURL: string): Observable<T[]> {
  return this.mapAndCatchError(
    this.http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
  );
}  

    //Delete quotationItem  api
    DeleteQuotationItem<T>(apiURL: string,quotationid:any): Observable<T[]> {
      return this.mapAndCatchError(
        this.http.post<T[]>(`${this._apiEndPoint}/${apiURL}`, {
         
          quotationid
        })
      );
    }
    //Delete itemsOnly  api
      DeleteQuotationItemOnly<T>(apiURL: string,itemId:any): Observable<T[]> {
        return this.mapAndCatchError(
          this.http.post<T[]>(`${this._apiEndPoint}/${apiURL}`, {
            itemId
          })
        );
      }
    private mapAndCatchError<TData>(response: Observable<TData>): Observable<TData> {

      return response.pipe(
        map((r: TData) => {
  
          return r;
        }),
        catchError((error: HttpErrorResponse) => {
          return of(null);
        })
      );
    }

  
}
