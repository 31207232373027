import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map, first } from 'rxjs/operators';
import { Employee } from 'src/app/shared/models/adminpanel/employee';
import { Leads } from 'src/app/shared/models/adminpanel/leads/leads';
import { LeadsService } from 'src/app/shared/services/adminpanel/leads/leads.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EmployeeListService } from 'src/app/shared/services/adminpanel/employee-list.service';
import { CONSTANT } from 'src/app/shared/utils/constant';
import { StatusService } from 'src/app/shared/services/status.service';

@Component({
  selector: 'app-add-lead',
  templateUrl: './add-lead.component.html',
  styleUrls: ['./add-lead.component.scss']
})
export class AddLeadComponent implements OnInit {

  // public variables = ['Parth Shah', 'Tushar Panchal', 'Yash Shah', 'Jay Patel', 'Dhruv Boghani'];
  public filteredList1

  public variables2 = CONSTANT.status;
  public filteredList2 = this.variables2.slice();
  public variables3 = ['Demo', 'Facebook', 'calling'];
  public filteredList3 = this.variables3.slice();
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredtags: Observable<string[]>;
  tags: string[] = [];
  alltags: string[] = ['Demo', 'SEO', 'CRM', 'CRM demo', 'Test'];
  allLanguages: string[] = (CONSTANT.languages);
  allCategories: string[] = ['Portfolio Management', 'Financial Planning'];
  selectedValue: any;
  selectedValue1: any;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  AddLeadForm: FormGroup;
  maxDate = new Date();
  employee: any;
  assignedChangeName: any;
  assignedChangeUserId: any;
  filteredListEmployee: Employee[];
  userinfo: any;

  constructor(
    private LeadServices: LeadsService,
    private matSnack: SnackbarService,
    private router: Router,
    private EmployeeListService: EmployeeListService,
    private statusservice: StatusService,
  ) {
    this.filteredtags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => tag ? this._filter(tag) : this.alltags.slice()));
  }

  ngOnInit(): void {
    this.userinfo = JSON.parse(localStorage.getItem('currentUser'));
    this.AddLeadForm = new FormGroup({
      name: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      position: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      website: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      zip: new FormControl('', Validators.required),
      whatsappnumber: new FormControl('', Validators.required),
      leadvalue: new FormControl('', Validators.required),
      company: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      contacted: new FormControl('', Validators.required),
      aadharcard: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
      sources: new FormControl('', Validators.required),
      assigned: new FormControl('', Validators.required),
      language: new FormControl('', Validators.required),
      servicecategory: new FormControl('', Validators.required),
      tagCtrl: new FormControl(''),
    })
    this.getEmployee()
    this.getListOfStatus()
  }


  //addleadform controls
  get alf() { return this.AddLeadForm.controls; }

  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.alltags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

  //join symptomps function
  joinsymptomps(arrayValue) {

    var StringValue = arrayValue
    return Array.prototype.map.call(StringValue, function (item) {
      return item;
    }).join(",");

  }

  AssignedEmployee(event) {
    this.assignedChangeName = event.source.triggerValue;
    this.assignedChangeUserId = event.value;
  }

  //Add lead api call
  addLead() {
    this.LeadServices.AddLead(
      this.alf.name.value,
      this.alf.company.value,
      this.alf.email.value,
      this.alf.phone.value,
      this.alf.whatsappnumber.value,
      this.alf.leadvalue.value,
      this.joinsymptomps(this.tags),
      this.userinfo.userid,
      this.userinfo.name,
      this.alf.status.value,
      this.alf.address.value,
      this.alf.city.value,
      this.alf.state.value,
      this.alf.country.value,
      this.alf.zip.value,
      this.alf.language.value,
      this.alf.description.value,
      this.alf.contacted.value,
      this.alf.sources.value,
      '',
      this.alf.website.value,
      '',
      '',
    ).pipe(first())
      .subscribe(
        data => {
          console.log(data)
          this.getLeads()
          this.router.navigateByUrl('/employee/leads')
          this.matSnack.showNotification("Lead Added", "")

        },

        error => {
          console.log(error.error)
        });

  }

  /*
    Get Leads list api called
  */
  getLeads() {
    this.LeadServices.getLeads()
      .subscribe((data: Leads[]) => {
      });

  }

  /*
    Get employee list api called
  */
  getEmployee() {
    this.EmployeeListService.getEmployee()
      .subscribe((data: Employee[]) => {
        this.employee = data;
        this.filteredListEmployee = data
        this.filteredList1 = this.filteredListEmployee.slice()
      });
  }
  getListOfStatus(){
 
    this.statusservice.getListOfStatus().subscribe((data: []) => {
      if(data){
      
       
        this.filteredList2 = data
     
        this.variables2=data
        // console.log(this.filteredList2.statusName)
       // this.isLoading = false;
      } 
      
       
      });
    
  }
}
