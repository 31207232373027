<div fxLayout="column" class="full-padding padding-top" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="20px">
    <!-- <mat-card  style="width:100%"> -->
    <mat-card appMaterialElevation style="width:100%" fxLayout="column" fxLayoutAlign=" center" fxFlex="32"
      class="cursor-pointer">
      <div fxLayout="row">
        <!-- <p fxLayoutAlign=" center" class="blue-color mat-h2"><mat-icon class="padding-right-side">email</mat-icon> Pending Email</p> -->
        <div class="container" class="blue-color mat-h2">
          <mat-icon class="v-align-middle">email</mat-icon>
          <span class="v-align-middle">Pending Email</span>
        </div>
      </div>
      <p class="">50000</p>
    </mat-card>
    <mat-card style="width:100%" fxLayout="column" fxLayoutAlign=" center" fxFlex="32" class="cursor-pointer">

      <div fxLayout="row">
        <!-- <mat-icon class="blue-color">currency_rupee</mat-icon> -->
        <!-- <p fxLayoutAlign=" center" class="blue-color mat-h2"><mat-icon class="padding-right-side">email</mat-icon> Used Email</p> -->
        <div class="container" class="blue-color mat-h2">
          <mat-icon class="v-align-middle">email</mat-icon>
          <span class="v-align-middle">Used Email</span>
        </div>
      </div>
      <p class="">25000</p>
    </mat-card>

  </div>
  <!-- <div fxLayout="row" fxLayoutAlign="center"  fxLayoutGap="20px" >
    <mat-card style="width:100%"   fxLayout="column" fxLayoutAlign=" center" fxFlex="32" (click)="goContactlist()">
        <div fxLayout="row">   
            <div class="container"  class="blue-color mat-h2">
                <mat-icon class="v-align-middle">group</mat-icon>
                <span class="v-align-middle">Contact list</span> 
              </div>
        </div>
      
    </mat-card>
    <mat-card style="width:100%" fxLayout="column" fxLayoutAlign=" center" fxFlex="32" (click)="goTemplate()">
        <div fxLayout="row">
            <div class="container"  class="blue-color mat-h2">
                <mat-icon class="v-align-middle">add_comment</mat-icon>
                <span class="v-align-middle">Template</span> 
              </div>
        </div>
   </mat-card> 
</div> -->

  <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="20px">
    <mat-card style="width:100%" fxLayout="column" fxLayoutAlign=" center" fxFlex="32" (click)="goContactlist()"
      class="cursor-pointer">
      <div fxLayout="row">
        <div class="container" class="blue-color mat-h2">
          <mat-icon class="v-align-middle">group</mat-icon>
          <span class="v-align-middle">Contact list</span>
        </div>
      </div>
    </mat-card>

    <mat-card routerLink="/admin/email/Template" style="width:100%" fxLayout="column" fxLayoutAlign=" center"
      fxFlex="32" class="cursor-pointer">
      <div fxLayout="row">
        <div class="container" class="blue-color mat-h2">
          <mat-icon class="v-align-middle">email</mat-icon>
          <span class="v-align-middle">Email Template</span>
        </div>
      </div>
    </mat-card>
  </div>

  <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="20px">
    <mat-card routerLink="/admin/email/emailstatus" style="width:100%" fxLayout="column" fxLayoutAlign=" center"
      fxFlex="32" class="cursor-pointer">
      <div fxLayout="row">
        <div class="container" class="blue-color mat-h2">
          <mat-icon class="v-align-middle">email</mat-icon>
          <span class="v-align-middle">Email Status</span>
        </div>
      </div>
    </mat-card>
  </div>
</div>