import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { CONSTANT } from 'src/app/shared/utils/constant';
import { DomSanitizer } from '@angular/platform-browser';
import { AddEmployeeService } from 'src/app/shared/services/adminpanel/add-employee.service';
import { EmployeeListService } from 'src/app/shared/services/adminpanel/employee-list.service';
import { Employee } from 'src/app/shared/models/adminpanel/employee';
export interface Items {
  value: string;
  reason: string;
}
@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss']
})
export class AddEmployeeComponent implements OnInit {
  isLoading;
  allLanguages: string[] = (CONSTANT.languages);
  roles: string[] = (CONSTANT.role);
  items: Items[] = [
    { value: 'Long wait', reason: 'Long wait' },
    { value: 'Doctor not available', reason: 'Doctor not available' },
    { value: 'Change my mind', reason: 'Change my mind' },
    { value: 'Preferred doctor not available', reason: 'Preferred doctor not available' },
    { value: 'Other', reason: 'Other' }
  ]
  showhidepregnant: boolean;
  checkboxValue: any;
  selected = -1; myForm: FormGroup;
  selectedValue: any;
  selectedValue1: any;
  AddEmployeeForm: FormGroup;
  maxDate = new Date();
  hide = true;
  txtPassword: any;
  PhotoUploadUrl: any;
  materialdata: any;
  imageChangedEvent: any;
  base64textString: string;
  PhotoUrl: any;
  imagePath: any;
  empoloyeeData: any;
  EmployeeDatabyid: any;
  EmployeeFirstName: any;
  EmployeeLastName: any;
  EmployeeEmail: any;
  EmployeePhone: any;
  EmployeeFacebook: any;
  EmployeeLinkedin: any;
  EmployeeSkype: any;
  EmployeeLanguage: any;
  EmployeeImage: any;
  EditEmployee: string;
  EmployeeImageDefault: any;
  AddEmployee: string;
  modules: FormGroup;
  EmployeeRole: any;
  EmployeeQuotation: any;
  EmployeeProject: any;
  EmployeeProduct: any;
  quotationCheck: boolean;
  empoloyeeCheck: boolean;

  constructor(
    private AddEmployeeService: AddEmployeeService,
    private matSnack: SnackbarService,
    private router: Router,
    private _sanitizer: DomSanitizer,
    fb: FormBuilder,
    private EmployeeListService: EmployeeListService
  ) {
  }

  ngOnInit(): void {
    this.empoloyeeData = JSON.parse(localStorage.getItem('employeeData'));
    this.EditEmployee = localStorage.getItem('edit-employee')
    this.AddEmployee = localStorage.getItem('add-employee')
    this.AddEmployeeForm = new FormGroup({
      useremail: new FormControl(''),
      fname: new FormControl('', Validators.required),
      lname: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      facebook: new FormControl('', Validators.required),
      linkedin: new FormControl('', Validators.required),
      skype: new FormControl('', Validators.required),
      language: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      password: new FormControl('', [
        // Validators.required,
        Validators.pattern(CONSTANT.passwordPattern),
      ]),
      checkbox: new FormControl('',),
    })
    this.modules = new FormGroup({
      quotation: new FormControl(),
      product: new FormControl(),
    });
    this.txtPassword = this.AddEmployeeForm.get('password');
    if (this.EditEmployee === 'Edit-employee') {
      this.getEmployeeDatabyid(this.empoloyeeData.userId)
    }
  }

  onChange(event: any) {
    this.checkboxValue = event.source.value;
    console.log(this.checkboxValue, "this.checkboxValue")
  }

  //AddEmployeeForm controls
  get alf() { return this.AddEmployeeForm.controls; }
  get alfd() { return this.modules.controls; }

  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  fileChangeEvent(evt) {
    var files = evt.target.files;
    var file = files[0];
    if (evt.target.files && evt.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(evt.target.files[0]);
      reader.onload = (event) => {
        this.PhotoUploadUrl = event.target.result;
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'
      + this.base64textString);
    console.log(this.imagePath)
  }

  //Add Employee api call
  addEmployee() {
    // if (this.AddEmployeeForm.invalid) {
    //  console.log(this.AddEmployeeForm)
    //   return;
    // }
    var name = this.alf.fname.value + " " + this.alf.lname.value
    var employee = this.alf.role.value == 'Employee' ? true : false
    var admin = this.alf.role.value == 'Admin' ? true : false
    if (this.EditEmployee != 'Edit-employee') {
      var profile_image = this.base64textString ? this.base64textString : 'assets/images/user-placeholder.jpg'
      this.AddEmployeeService.AddEmployee(

        this.alf.email.value,
        this.alf.fname.value,
        this.alf.lname.value,
        this.alf.phone.value,

        this.alf.facebook.value,
        this.alf.linkedin.value,
        this.alf.skype.value,
        this.alf.language.value,
        // this.alf.role.value,
        this.alf.password.value,
        this.alfd.quotation.value,
        this.alfd.product.value,
        employee,
        admin,
        profile_image
      ).pipe(first())
        .subscribe(
          data => {
            console.log(data)
            this.router.navigateByUrl('/admin/employee')
            this.matSnack.showNotification("Employee Added", "")
          },
          error => {
            console.log(error.error)
          });
    }
    if (this.EditEmployee === 'Edit-employee') {
      var employee = this.alf.role.value == 'Employee' ? true : false
      var admin = this.alf.role.value == 'Admin' ? true : false
      var profile_image_update = this.base64textString ? this.base64textString : this.EmployeeDatabyid.profile_image
      this.AddEmployeeService.UpdateEmployee(
        `api/Auth/UpdateEmployee`,
        this.EmployeeDatabyid.userId,
        this.alf.email.value,
        this.alf.fname.value,
        this.alf.lname.value,
        this.alf.phone.value,
        this.alf.facebook.value,
        this.alf.linkedin.value,
        this.alf.skype.value,
        this.alf.language.value,

        this.EmployeeDatabyid.password,
        this.alfd.quotation.value,
        this.alfd.product.value,
        employee,
        admin,
        profile_image_update
      ).pipe(first())
        .subscribe(
          data => {
            // console.log(data)
            this.router.navigateByUrl('/admin/employee')
            this.matSnack.showNotification("Employee Updated", "")
          },
          error => {
            console.log(error.error)
          });
    }
  }

  /*
   Select Module
 */
  checked() {
    if (this.EmployeeQuotation == "1") {
      this.EmployeeQuotation = true;
    }
    else {
      this.EmployeeQuotation = false;
    }
  }
  checked2() {
    if (this.EmployeeProduct == "1") {
      this.EmployeeProduct = true;
    }
    else {
      this.EmployeeProduct = false;
    }
  }

  /*
  Get employee data by id api called
*/
  getEmployeeDatabyid(id) {
    this.isLoading = true;

    this.EmployeeListService
      .getEmployeedatabyid<Employee>(`api/auth/EmployeeDetails?empId=${id}`)
      .subscribe((data: Employee[]) => {
        this.isLoading = false;
        console.log(data)
        this.EmployeeDatabyid = data;
        // var fullname = this.EmployeeDatabyid.name.split(' ');
        this.EmployeeFirstName = this.EmployeeDatabyid.firstName
        this.EmployeeLastName = this.EmployeeDatabyid.lastName;
        this.EmployeeEmail = this.EmployeeDatabyid.email
        this.EmployeePhone = this.EmployeeDatabyid.phone
        this.EmployeeFacebook = this.EmployeeDatabyid.faceBook
        this.EmployeeLinkedin = this.EmployeeDatabyid.linkdIn
        this.EmployeeSkype = this.EmployeeDatabyid.skype
        this.EmployeeLanguage = this.EmployeeDatabyid.defaultlanguage
        this.EmployeeRole = this.EmployeeDatabyid.userRoles[0]
        this.EmployeeQuotation = this.EmployeeDatabyid.isQuotationAccess
        this.EmployeeProduct = this.EmployeeDatabyid.isProductAccess
        this.EmployeeImageDefault = this.EmployeeDatabyid.userImage
        this.EmployeeImage = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'
          + this.EmployeeDatabyid.userImage);

        this.checked();
        this.checked2();
      })

  }

}
