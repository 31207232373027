<div class="color-white backgroud-color-primary cardpadding">
  <div fxLayout="row" fxLayoutAlign="space-between">

    <p class="mat-title">Set Lead Reminder
    </p>
    <mat-icon class="cursor-pointer" (click)="closeDialog()">close</mat-icon>
  </div>
</div>

<div class="cardpadding datetimepicker" fxLayout="column" fxLayoutGap="20px">
  <div>
    <form [formGroup]="group">
      <div fxLayout="column">
        <p class="color-primary">Date to be notified:</p>
        <!-- {{this.reminderdate}} -->
        <mat-form-field appearance="outline">
         
          <input formControlName="datenotify" [(ngModel)]="this.reminderdate"
            (dateChange)="currentPeriodClicked($event)" matInput [matDatetimepicker]="dts" required>
          <mat-datetimepicker-toggle [for]="dts" matSuffix></mat-datetimepicker-toggle>
          <mat-datetimepicker touchUi #dts type="datetime" twelvehour="true" timeInterval="1">
          </mat-datetimepicker>
        </mat-form-field>
      </div>


      <div fxLayout="column">
        <p class=" color-primary">Send reminder to:</p>
        <mat-form-field appearance="outline">
         
          <mat-select formControlName="sendto" [(ngModel)]="this.reminderreminderto">
            <mat-select-filter [array]="filteredList1" (filteredReturn)="filteredList1 =$event">
            </mat-select-filter>
          
            <mat-option *ngFor="let item of filteredList1" [value]="item">
              {{item}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <p class=" color-primary">Description</p>
        <mat-form-field appearance="outline" class="full-width">
          <textarea formControlName="description" [(ngModel)]="this.reminderdescription" matInput></textarea>
        </mat-form-field>
      </div>

      <div fxLayout="column">
        <mat-checkbox color="primary" [(ngModel)]="this.remindersendmail" formControlName="sendmail">
          Send also an email for this reminder
        </mat-checkbox>
      </div>
    </form>
  </div>


  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
    <button mat-raised-button (click)="closeDialog()">Cancel</button>
    <button mat-raised-button color="primary" (click)="UpdateLeadReminder()">Save</button>
  </div>
</div>