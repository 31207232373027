<div fxLayout="column" id="top" class="cardpadding">
  <mat-card fxLayout="column" fxLayoutGap="10px">
    <div class="color-primary backgroud-color-white cardpadding" id="top">
      <div fxLayout="row" fxLayoutAlign="space-between center">

        <p class="mat-title">{{this.LeadName}}
        </p>
        <button mat-raised-button color="primary" routerLink="/admin/leads">
          <mat-icon class="cursor-pointer">arrow_back</mat-icon> Back
        </button>
      </div>
    </div>
    <div>
      <mat-divider>
      </mat-divider>
    </div>
    <div class="mat-typography">
      <div>
        <mat-tab-group>
          <!-- Lead details tab -->
          <mat-tab>
            <ng-template mat-tab-label>
              Profile
            </ng-template>
            <!-- View lead section -->
            <div fxLayout="column" fxLayoutGap="10px" *ngIf="this.viewLead === 'View-Lead'">
              <div class="cardpadding" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" fxFlex="grow">
                <div fxLayout="column" fxLayoutGap="10px" fxFlex="32">
                  <p class="color-primary background-color-grey font-weight-600 padding-left">Lead Information
                  </p>
                  <div class="padding-left" fxLayout="column" fxLayoutGap="10px">

                    <!-- Lead Name -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Name</p>
                      <p>{{this.LeadName}}</p>
                    </div>
                    <!-- Email -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Email Address</p>
                      <p>{{this.LeadEmail}}</p>
                    </div>
                    <!-- Website -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Website</p>
                      <p>{{this.LeadWebsite}}</p>
                    </div>
                    <!-- Phone -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Phone</p>
                      <p>{{this.LeadPhone}}</p>
                    </div>
                    <!-- Whatsapp -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Whatsapp Number</p>
                      <p>{{this.LeadWhatsapp}}</p>
                    </div>
                    <!-- Lead value -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Lead Value</p>
                      <p>{{this.LeadValue}}</p>
                    </div>
                    <!-- Company -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Comapany</p>
                      <p>{{this.LeadCompany}}</p>
                    </div>
                    <!-- Address -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Address</p>
                      <p>{{this.LeadAddress}}</p>
                    </div>
                    <!-- city -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">City</p>
                      <p>{{this.LeadCity}}</p>
                    </div>
                    <!-- State -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">State</p>
                      <p>{{this.LeadState}}</p>
                    </div>
                    <!-- Country -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Country</p>
                      <p>{{this.LeadCountry}}</p>
                    </div>
                    <!-- Zip -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Zip Code</p>
                      <p>{{this.LeadZip}}</p>
                    </div>
                    <!-- Description -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Description</p>
                      <p>{{this.LeadDescription}}</p>
                    </div>

                  </div>
                </div>

                <div fxLayout="column" fxLayoutGap="10px" fxFlex="32">
                  <p class="color-primary background-color-grey font-weight-600 padding-left">General
                    Information</p>
                  <div class="padding-left" fxLayout="column" fxLayoutGap="10px">
                    <!-- Status -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Status</p>
                      <p>{{this.LeadStatus}}</p>
                    </div>
                    <!-- Sources -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Source</p>
                      <p>{{this.LeadSorces}}</p>
                    </div>
                    <!-- Language -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Default Language</p>
                      <p>{{this.LeadLanguage}}</p>
                    </div>
                    <!-- Assigned -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Assigned</p>
                      <p>{{this.LeadAssigned}}</p>
                    </div>
                    <!-- Tags -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Tags</p>
                      <mat-chip-list>
                        <!-- {{this.leadData.tags}} -->
                        <span *ngFor="let symbols of this.tags">
                          <mat-chip class=" mat-chips">{{symbols}} </mat-chip>
                        </span>
                      </mat-chip-list>
                    </div>
                    <!-- Created -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Created</p>
                      <p>{{this.LeadCreated | date:'dd-MM-yyyy hh:mm a'}}</p>
                    </div>
                    <!-- Last contact -->
                    <div fxLayout="column">
                      <p class="color-primary font-weight-600">Last Contact</p>
                      <p>{{this.LeadContacted | date:'dd-MM-yyyy hh:mm a'}}</p>
                    </div>
                  </div>
                </div>

              </div>
              <div>
                <mat-divider></mat-divider>
              </div>
              <div class="cardpadding" fxLayout="column" fxLayoutGap="10px">
                <p class="color-primary background-color-grey font-weight-600 padding-left">Last Activity</p>
                <p class="">Lead assigned to <span class="color-primary">{{this.LeadAssigned}}</span>
                </p>
              </div>
            </div>

            <!-- Edit lead section -->
            <div fxLayout="column" fxLayoutGap="10px" *ngIf="this.editLead === 'Edit-Lead'">
              <form [formGroup]="UpdateLeadForm">
                <div class="cardpadding" fxLayout="column" fxLayoutGap="20px">

                  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" fxFlex>
                    <!-- Status -->
                    <div fxFlex="32">
                      <mat-form-field appearance="outline" class="full-width form-field-font">
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="this.LeadStatus" formControlName="status">

                          <mat-option *ngFor="let topping of filteredList2" [value]="topping">
                            {{topping}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <!-- Sources -->
                    <div fxFlex="32">
                      <mat-form-field appearance="outline" class="full-width form-field-font">
                        <mat-label>Sources</mat-label>
                        <mat-select [(ngModel)]="this.LeadSorces" placeholder="Sources" formControlName="sources">
                          <mat-select-filter [array]="variables3" (filteredReturn)="filteredList3 =$event">
                          </mat-select-filter>
                          <mat-option *ngFor="let item of filteredList3" [value]="item">
                            {{item}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <!-- Assigned -->
                    <div fxFlex="32" fxHide>
                      <mat-form-field appearance="outline" class="full-width form-field-font">
                        <mat-label>Assigned</mat-label>
                        <mat-select placeholder="Assigned " (selectionChange)="AssignedEmployee($event)"
                          [(ngModel)]="this.LeadAssignedId" formControlName="assigned">
                          <mat-select-filter [array]="filteredListEmployee" [displayMember]="'name'"
                            (filteredReturn)="filteredList1 =$event">
                          </mat-select-filter>
                          <mat-option *ngFor="let item of filteredList1" [value]="item.userid">
                            {{item.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div>
                    <mat-divider></mat-divider>
                  </div>
                  <div fxLayout="column">
                    <!-- Tags -->
                    <mat-form-field class="example-chip-list">

                      <mat-chip-list #chipList aria-label="tag selection">
                        <mat-chip *ngFor="let tag of this.tags" [selectable]="selectable" [removable]="removable"
                          (removed)="remove(tag)" class='tag-chips'>
                          {{tag}}
                          <mat-icon matChipRemove *ngIf="removable" class="chip-clear-color">cancel
                          </mat-icon>
                        </mat-chip>
                        <input placeholder="Tag" #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
                          [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          (matChipInputTokenEnd)="add($event)">
                      </mat-chip-list>
                      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let tag of filteredtags | async" [value]="tag">
                          {{tag}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-divider></mat-divider>
                  </div>
                  <div fxLayout="column">
                    <!-- Name -->
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                      fxLayoutAlign.lt-sm="start" fxFlex>
                      <div fxFlex="48" fxFlex.lt-sm="100">
                        <mat-form-field appearance="outline" class="full-width form-field-font">
                          <mat-label>Name</mat-label>
                          <input matInput [(ngModel)]="this.LeadName" formControlName="name" placeholder="">
                        </mat-form-field>
                      </div>
                      <!-- Address -->
                      <div fxFlex="48" fxFlex.lt-sm="100">
                        <mat-form-field appearance="outline" class="full-width form-field-font">
                          <mat-label>Address</mat-label>
                          <input matInput [(ngModel)]="this.LeadAddress" formControlName="address" placeholder="">
                        </mat-form-field>
                      </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                      fxLayoutAlign.lt-sm="start" fxFlex>
                      <div fxFlex="48" fxFlex.lt-sm="100" fxHide>
                        <!-- Position -->
                        <mat-form-field appearance="outline" class="full-width form-field-font">
                          <mat-label>Position</mat-label>
                          <input matInput [(ngModel)]="this.LeadPosition" placeholder="" formControlName="position">
                        </mat-form-field>
                      </div>
                      <div fxFlex="48" fxFlex.lt-sm="100">
                        <!-- Email -->
                        <mat-form-field appearance="outline" class="full-width form-field-font">
                          <mat-label>Email Address</mat-label>
                          <input matInput [(ngModel)]="this.LeadEmail" formControlName="email" placeholder="">
                        </mat-form-field>
                      </div>
                      <!-- City -->
                      <div fxFlex="48" fxFlex.lt-sm="100">
                        <mat-form-field appearance="outline" class="full-width form-field-font">
                          <mat-label>City</mat-label>
                          <input matInput [(ngModel)]="this.LeadCity" formControlName="city" placeholder="">
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                      fxLayoutAlign.lt-sm="start" fxFlex>
                      <div fxFlex="48" fxFlex.lt-sm="100">
                        <!-- Website -->
                        <mat-form-field appearance="outline" class="full-width form-field-font">
                          <mat-label>Website</mat-label>
                          <input matInput placeholder="" [(ngModel)]="this.LeadWebsite" formControlName="website">
                        </mat-form-field>
                      </div>
                      <div fxFlex="48" fxFlex.lt-sm="100">
                        <!-- State-->
                        <mat-form-field appearance="outline" class="full-width form-field-font">
                          <mat-label>State</mat-label>
                          <input matInput [(ngModel)]="this.LeadState" formControlName="state" placeholder="">
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                      fxLayoutAlign.lt-sm="start" fxFlex>
                      <div fxFlex="48" fxFlex.lt-sm="100">
                        <!-- Phone -->
                        <mat-form-field appearance="outline" class="full-width form-field-font">
                          <mat-label>Phone</mat-label>
                          <input matInput [(ngModel)]="this.LeadPhone" formControlName="phone" placeholder="">
                        </mat-form-field>
                      </div>
                      <div fxFlex="48" fxFlex.lt-sm="100">
                        <!-- Country -->
                        <mat-form-field appearance="outline" class="full-width form-field-font">
                          <mat-label>Country</mat-label>
                          <input matInput [(ngModel)]="this.LeadCountry" formControlName="country" placeholder="">
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                      fxLayoutAlign.lt-sm="start" fxFlex>
                      <div fxFlex="48" fxFlex.lt-sm="100">
                        <!-- Whatsapp -->
                        <mat-form-field appearance="outline" class="full-width form-field-font">
                          <mat-label>Whatsapp Number</mat-label>
                          <input matInput [(ngModel)]="this.LeadWhatsapp" formControlName="whatsappnumber"
                            placeholder="">
                        </mat-form-field>
                      </div>
                      <div fxFlex="48" fxFlex.lt-sm="100">
                        <!-- Zip -->
                        <mat-form-field appearance="outline" class="full-width form-field-font">
                          <mat-label>Zip Code</mat-label>
                          <input matInput [(ngModel)]="this.LeadZip" formControlName="zip" placeholder="">
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                      fxLayoutAlign.lt-sm="start" fxFlex>
                      <div fxFlex="48" fxFlex.lt-sm="100">
                        <!-- Lead value -->
                        <mat-form-field appearance="outline" class="full-width form-field-font">
                          <mat-label>Lead Value</mat-label>
                          <input matInput [(ngModel)]="this.LeadValue" formControlName="leadvalue" placeholder="">
                          <span matSuffix>₹&nbsp;</span>
                        </mat-form-field>
                      </div>
                      <div fxFlex="48" fxFlex.lt-sm="100">
                        <!-- Language -->
                        <mat-form-field appearance="outline" class="full-width form-field-font">
                          <mat-label>Default Language</mat-label>
                          <mat-select [(ngModel)]="this.LeadLanguage" name="language" formControlName="language">
                            <mat-option *ngFor="let language of allLanguages" [value]="language">
                              {{language}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                      fxLayoutAlign.lt-sm="start" fxFlex>

                      <div fxFlex="48" fxFlex.lt-sm="100">
                        <!-- Company -->
                        <mat-form-field appearance="outline" class="full-width form-field-font">
                          <mat-label>Comapany</mat-label>
                          <input matInput [(ngModel)]="this.LeadCompany" formControlName="company" placeholder="">
                        </mat-form-field>
                      </div>
                    </div>
                    <div>
                      <!-- description-->
                      <mat-form-field appearance="outline" class="full-width form-field-font">
                        <mat-label>Description</mat-label>
                        <textarea matInput [(ngModel)]="this.LeadDescription" formControlName="description"></textarea>
                      </mat-form-field>
                    </div>
                    <div>
                      <!-- Contacted -->
                      <mat-form-field appearance="outline" class="full-width form-field-font">
                        <mat-label>Contacted</mat-label>
                        <input matInput [matDatetimepicker]="dts" formControlName="contacted" [max]="maxDate"
                          [(ngModel)]="this.contact_date" required autocomplete="false">
                        <mat-datetimepicker-toggle [for]="dts" matSuffix></mat-datetimepicker-toggle>
                        <mat-datetimepicker touchUi #dts type="datetime" twelvehour="true" timeInterval="1">
                        </mat-datetimepicker>
                      </mat-form-field>
                    </div>

                    <div fxHide>
                      <!-- Service Category -->
                      <mat-form-field appearance="outline" class="full-width form-field-font">
                        <mat-label>Service Categories</mat-label>
                        <mat-select [(ngModel)]="this.LeadServiceCategory" name="category"
                          formControlName="servicecategory">
                          <mat-option *ngFor="let category of allCategories" [value]="category">
                            {{category}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div fxFlex fxHide>
                      <div fxFlex="48" fxFlex.lt-sm="100">
                        <!-- Aadhar card no. -->
                        <mat-form-field appearance="outline" class="full-width form-field-font">
                          <mat-label>Aadhar Card Number</mat-label>
                          <input matInput placeholder="" [(ngModel)]="this.LeadAadharcard" formControlName="aadharcard">
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <!-- Buttons -->
                  <div align="end" fxLayoutGap="10px">
                    <button mat-raised-button routerLink="/admin/leads">Cancel</button>
                    <button mat-raised-button color="primary" (click)="updateLead()">Save</button>
                  </div>
                </div>
              </form>
            </div>
          </mat-tab>

          <!-- Reminder tab -->
          <mat-tab>
            <ng-template mat-tab-label>
              Reminders
            </ng-template>
            <div class="cardpadding" fxLayout="column" fxLayoutGap="10px">
              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between start" fxLayoutGap="10px" fxFlex>
                <div fxFlex="25" fxFlex.lt-sm="100">
                  <button mat-raised-button color="primary" class="" (click)="SetReminder()">
                    <mat-icon>notifications_active</mat-icon>
                    SET LEAD REMINDER
                  </button>

                </div>
                <div *ngIf="this.show === true" fxFlex="75" fxFlex.lt-sm="100">
                  <div class="color-white backgroud-color-primary cardpadding">
                    <div fxLayout="row" fxLayoutAlign="space-between">

                      <p class="mat-title font-weight-600">Set Lead Reminder
                      </p>
                    </div>
                  </div>

                  <div class="cardpadding datetimepicker" fxLayout="column" fxLayoutGap="10px">
                    <form [formGroup]="setReminderGroup">
                      <div>
                        <!-- Date to be notified -->
                        <div fxLayout="column">
                          <p class=" color-primary">Date to be notified:</p>
                          <mat-form-field appearance="outline" class="form-field-font">
                            <input formControlName="datenotify" matInput [matDatetimepicker]="dts" required
                              [min]="minDate" autocomplete="false">
                            <mat-datetimepicker-toggle [for]="dts" matSuffix>
                            </mat-datetimepicker-toggle>
                            <mat-datetimepicker touchUi #dts type="datetime" twelvehour="true" timeInterval="1">
                            </mat-datetimepicker>
                          </mat-form-field>
                        </div>

                        <!-- Send reminder to -->
                        <div fxLayout="column">
                          <p class=" color-primary">Send reminder to:</p>
                          <mat-form-field appearance="outline" class="form-field-font">
                            <mat-select formControlName="sendto">
                              <mat-select-filter [array]="filteredList1" (filteredReturn)="filteredList1 =$event">
                              </mat-select-filter>
                              <mat-option *ngFor="let item of filteredList1" [value]="item">
                                {{item}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>

                        <!-- Description -->
                        <div fxLayout="column">
                          <p class=" color-primary">Description</p>
                          <mat-form-field appearance="outline" class="full-width form-field-font">
                            <textarea matInput formControlName="description"></textarea>
                          </mat-form-field>
                        </div>

                        <!-- Send mail -->
                        <div fxLayout="column">
                          <mat-checkbox color="primary" (change)="showOptions($event)" formControlName="sendmail"
                            value="True">
                            Send also an email for this reminder
                          </mat-checkbox>
                        </div>
                      </div>
                    </form>

                    <!-- Buttons -->
                    <div align="end" fxLayoutGap="10px">
                      <button mat-raised-button (click)="closeSetRemind()">Cancel</button>
                      <button mat-raised-button color="primary" (click)="AddLeadReminder()">Save</button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <mat-divider></mat-divider>
              </div>
              <!-- Reminder list -->
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <p class="mat-h2 color-primary font-weight-600">Reminder List</p>
                </div>
                <div>
                  <mat-form-field appearance="outline">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event)">
                    <mat-icon matPrefix>search</mat-icon>
                  </mat-form-field>
                </div>

              </div>
              <div class="setremind">
                <table mat-table [dataSource]="this.dataSource" class="mat-elevation-z8">

                  <!-- Description Column -->
                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> Description </th>
                    <td mat-cell *matCellDef="let element">
                      <div fxLayout="column">
                        <p>{{element.description}}</p>

                      </div>

                    <td>
                  </ng-container>

                  <!-- actions Column -->
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element" class="action-button">
                      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="editReminder(element)">
                          Edit
                        </button>
                        <button mat-menu-item (click)="deleteReminder(element)">
                          Delete
                        </button>
                      </mat-menu>
                    <td>
                  </ng-container>

                  <!-- Date Column -->
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.reminder_date | date:'dd-MM-yyyy hh:mm a'}}
                    </td>
                  </ng-container>

                  <!-- Remind Column -->
                  <ng-container matColumnDef="remind">
                    <th mat-header-cell *matHeaderCellDef> Remind </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.reminder_to}}
                    </td>
                  </ng-container>

                  <!-- Is Notify Column -->
                  <ng-container matColumnDef="isnotify">
                    <th mat-header-cell *matHeaderCellDef> Is Notify? </th>
                    <td mat-cell *matCellDef="let element">
                      <div *ngIf="element.send_mail == 'true'">Yes</div>
                      <div *ngIf="element.send_mail == 'false'">No</div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (mouseover)="handleMouseOver(row)"
                    (mouseleave)="handleMouseLeave(row)"></tr>
                </table>

              </div>
            </div>
          </mat-tab>

          <!-- Notes tab -->
          <mat-tab>
            <ng-template mat-tab-label>
              Notes
            </ng-template>

            <div class="cardpadding" fxLayout="column" fxLayoutGap="10px">
              <form [formGroup]="addNotesGroup">

                <!-- Notes -->
                <div>
                  <mat-form-field appearance="outline" class="full-width form-field-font">
                    <mat-label>Notes</mat-label>
                    <textarea matInput formControlName="notes"></textarea>
                  </mat-form-field>
                </div>

                <!-- Contacted date add or not -->
                <div fxLayout="column" fxLayoutGap="15px">
                  <mat-radio-group fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" formControlName="options"
                    (change)="changeOption($event)" [(ngModel)]="selectedRadio" required>
                    <mat-radio-button color="primary" class="example-radio-button " *ngFor="let show of shows"
                      [value]="show.value">
                      {{show.value}}
                      <!-- show==='I have not contacted this lead' -->
                    </mat-radio-button>
                  </mat-radio-group>

                  <!-- Contacted date -->
                  <div fxLayout="column" *ngIf="this.touch===true">
                    <mat-form-field appearance="outline" class="full-width form-field-font">
                      <mat-label>Date Contacted</mat-label>
                      <input formControlName="dataofnotes" matInput [matDatetimepicker]="dts" [max]="maxDate"
                        autocomplete="false">
                      <mat-datetimepicker-toggle [for]="dts" matSuffix></mat-datetimepicker-toggle>
                      <mat-datetimepicker touchUi #dts type="datetime" twelvehour="true" timeInterval="1">
                      </mat-datetimepicker>
                    </mat-form-field>
                  </div>
                </div>

                <!-- Buttons -->
                <div align="end">
                  <button mat-raised-button color="primary" (click)="addNotes()">Add Notes</button>
                </div>

              </form>
              <div>
                <mat-divider></mat-divider>
              </div>
              <div fxLayout="column" fxLayoutGap="20px">
                <div>
                  <p class="mat-h2 color-primary font-weight-600">Notes List</p>
                </div>

                <div class="notes">
                  <table mat-table [dataSource]="this.dataSource1" class="mat-elevation-z8">

                    <!-- Notes Column -->
                    <ng-container matColumnDef="note">
                      <th mat-header-cell *matHeaderCellDef> Note </th>
                      <td mat-cell *matCellDef="let element; let i = index;">
                        <div fxLayout="column">
                          <p>{{element.note}}</p>
                        </div>
                      <td>
                    </ng-container>

                    <!-- actions Column -->
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef> </th>
                      <td mat-cell *matCellDef="let element" class="action-button">
                        <button mat-icon-button [matMenuTriggerFor]="menu1"
                          aria-label="Example icon-button with a menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu1="matMenu">

                          <button mat-menu-item (click)="editnotes(element)">
                            Edit
                          </button>
                          <button mat-menu-item (click)="deleteNotes(element)">
                            Delete
                          </button>
                        </mat-menu>
                      <td>
                    </ng-container>

                    <!-- Created Date Column -->
                    <ng-container matColumnDef="date">
                      <th mat-header-cell *matHeaderCellDef>Created Date </th>
                      <td mat-cell *matCellDef="let element"> {{element.created_at | date:'dd-MM-yyyy
                        hh:mm a'}}
                      </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;" (mouseover)="handleMouseOver1(row)"
                      (mouseleave)="handleMouseLeave1(row)"></tr>
                  </table>

                </div>


              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>

    </div>
  </mat-card>
</div>