import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { FacebookCampaignService } from 'src/app/shared/services/adminpanel/facebook-campaign.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Component({
  selector: 'app-addfbinfo',
  templateUrl: './addfbinfo.component.html',
  styleUrls: ['./addfbinfo.component.scss']
})
export class AddfbinfoComponent implements OnInit {

  local_data: any;
  action: any;

  constructor(
    public dialogRef: MatDialogRef<AddfbinfoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: AddfbinfoComponent,
    public matSnack: SnackbarService,
    private fbCampaignService: FacebookCampaignService,
    private router:Router
  ) {
    this.local_data = data ;
    console.log(this.local_data)
    // this.action = this.local_data.action;
  }

  ngOnInit(): void {
    console.log(this.local_data)
  }

  // Close dialog
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  //Delete lead api call
  reject() {
    // AddFaceBookCampaignData(data) {

 
      this.fbCampaignService.AddFaceBookCampaignData(
        this.local_data
      ).pipe(first())
        .subscribe(
          data => {
            console.log(data)
  
            // this.newmdg=data.message
            this.matSnack.showNotification("facebook leads added", "")
            this.router.navigate(["admin/leads"])
          },
  
          error => {
            console.log(error.error)
          });
  
    // }
  
  }

}
