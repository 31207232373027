
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { RestmanagerService } from 'src/app/shared/utils/restmanagerService';
import { environment } from 'src/environments/environment';
// import { DoctorDashboard } from '../_models/doctor/doctordashboard';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  private restmanagerService: RestmanagerService;
  _apiEndPoint

  constructor(private http: HttpClient,
    restmanagerService: RestmanagerService) {
    this.restmanagerService = restmanagerService;
    this._apiEndPoint = environment.Url;
  }

  //Doctor patient in queue api
  getListOfStatus() {


    return this.http.get<any>(`${environment.apiUrl}/api/Admin/ListOfStatus`);

  }
  // getListOfStatus() {
  //   return this.restmanagerService.getAll<any>(`api/Admin/ListOfStatus`)
  //     .pipe(map(data => {
  //       return data;
  //     }));
  // }
  // this.DashbordService.DoctorPatientInQueue(this.DoctorId)
  // .pipe()
  // .subscribe(
  //   data => {
  //     this.count = data.count
  //   },
  // this.dashbordService.ConsultationSummary(doctorid, isViewAdmin, null).subscribe((data: ConsultationSummary[]) => {


  // report api
  // ConsultationSummary api
  AddleadStatus(statusName) {
    return this.restmanagerService.addwithout<any>(`api/Admin/AddStatus`, { statusName: statusName })
      .pipe(map(data => {
        return data;
      }));
  }

  // GetDoctorReport api
  UpdateleadStatus(statusId, statusName) {
    return this.restmanagerService.addwithout<any>(`api/Admin/UpdateStatus`, { statusId: statusId, statusName: statusName })
      .pipe(map(data => {
        return data;
      }));
  }

  // SpecialitySummary api
  DeleteleadStatus(statusId) {
    return this.restmanagerService.addwithout<any>(`api/Admin/deleteStatus`, { statusId: statusId })
      .pipe(map(data => {
        return data;
      }));
  }


}

