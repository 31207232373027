import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { DeleteQuotationComponent } from '../delete-quotation/delete-quotation.component';
import { MatDialog } from '@angular/material/dialog';
import { EditQuotationComponent } from '../edit-quotation/edit-quotation.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ProductsService } from 'src/app/shared/services/adminpanel/products.service';
import { Products } from 'src/app/shared/models/adminpanel/products';
import { AddQuotationService } from 'src/app/shared/services/adminpanel/add-quotation.service';
import { DeleteQuotationItemComponent } from '../delete-quotation-item/delete-quotation-item.component';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';


interface Related {
  value: string;
  viewValue: string;
}
interface Status {
  value: string;
  viewValue: string;
}
interface Assigned {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-add-quotation',
  templateUrl: './add-quotation.component.html',
  styleUrls: ['./add-quotation.component.scss']
})
export class AddQuotationComponent implements OnInit {
  AddQuotationForm: FormGroup;
  AddQuotationDataForm: FormGroup;
  selectable = true;
  removable = true;
  isChecked = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredtags: Observable<string[]>;
  tags: string[] = [];
  alltags: string[] = ['1BHK', 'Cosmetic', 'Gujarat', 'SEO', 'tag1'];
  selectQuantity: string;
  quantity: string[] = ['Qty', 'Hours', 'Qty/Hours'];
  displayedColumns: string[] = ['items', 'description', 'qty', 'rate', 'tax', 'amount', 'actions'];
  dataSource_new = new MatTableDataSource<quotation>([]);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  qty: any;
  rate: any;
  amount: any;
  arrayofData = []
  taxAmountCGST = []
  taxAmountSGST = []
  taxAmounteighteenIGST = [];
  taxAmounteighteenGST = [];
  related: Related[] = [
    { value: 'option2', viewValue: 'Lead' },
    { value: 'option3', viewValue: 'Customer' },
  ];

  status: Status[] = [
    { value: 'option1', viewValue: 'Draft' },
    { value: 'option2', viewValue: 'Sent' },
    { value: 'option3', viewValue: 'Open' },
    { value: 'option4', viewValue: 'Revised' },
    { value: 'option5', viewValue: 'Declined' },
    { value: 'option6', viewValue: 'Accepted' },
  ];
  tax: Status[] = [
    { value: '9.00% CGST', viewValue: '9.00% CGST' },
    { value: '9.00% SGST', viewValue: '9.00% SGST' },
    { value: '18.00% GST', viewValue: '18.00% GST' },
    { value: '18.00% IGST', viewValue: '18.00% IGST' },
  ];

  assigned: Assigned[] = [
    { value: 'option1', viewValue: 'test user' },
    { value: 'option2', viewValue: 'SOHIL @LIME' },
    { value: 'option3', viewValue: 'Rahul Maharana' },
    { value: 'option4', viewValue: 'Nikita R' },
    { value: 'option5', viewValue: 'Nidhi M' },
    { value: 'option6', viewValue: 'Jane Doe ' },
  ];
  dataSourcenew: any = [];
  savdata: any;
  discountAmount: any;
  adjustmentAmount: number;
  total: any;
  subtotal: number;
  discount: string;
  adjustment: string;
  products: any;
  filteredListEmployee: any;
  filteredList1: any;
  ItemsData: any;
  rateData: any;
  taxData: any;
  itemData: any;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  ninepercentCGST: any;
  eighteenpercentCGST: number;
  totalTaxAmountCGST: number;
  totalTaxAmountSGST: number;
  totalTaxAmounteighteenIGST: number;
  totalTaxAmounteighteenGST: number;
  ninepercentSGST: number;
  eighteenpercentGST: number;
  eighteenpercentIGST: number;
  allTaxTotal: number;
  assignedChangeName: any;
  relatedChangeName: any;
  discountChange: any;
  statusValueChange: any;

  constructor(public dialog: MatDialog,
    private ProductService: ProductsService,
    private addQuotationService: AddQuotationService,
    private router: Router,
    private matSnack: SnackbarService,
  ) {
    this.filteredtags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => (tag ? this._filter(tag) : this.alltags.slice())),
    );
    this.amount = this.qty * this.rate
  }

  ngOnInit(): void {
    this.AddQuotationForm = new FormGroup({
      subject: new FormControl(''),
      related: new FormControl(''),
      date: new FormControl('', Validators.required),
      openTill: new FormControl('', Validators.required),
      comment: new FormControl(''),
      tagCtrl: new FormControl(''),
      statusForm: new FormControl(''),
      assigned: new FormControl('', Validators.required),
      discountType: new FormControl('', Validators.required),
      to: new FormControl(''),
      address: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl(''),
      zipCode: new FormControl(''),
      email: new FormControl(''),
      phone: new FormControl(''),
    });

    this.AddQuotationDataForm = new FormGroup({
      description: new FormControl(''),
      longDescription: new FormControl(''),
      unit: new FormControl(''),
      rate: new FormControl(''),
      tax: new FormControl(''),
      discount: new FormControl(''),
      adjustment: new FormControl(''),
    })
    this.getProducts()
  }

  // AddQuotationForm controls
  get aqf() { return this.AddQuotationForm.controls; }
  // AddQuotationDataForm controls
  get aqdf() { return this.AddQuotationDataForm.controls; }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }
    // Clear the input value
    if (input) {
      input.value = '';
    }
    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.alltags.filter(tag => tag.toLowerCase().includes(filterValue));
  }

  // Delete Quotation Item
  openDialog_delete(element) {
    const dialogRef = this.dialog.open(DeleteQuotationItemComponent, {
      height: 'auto',
      width: '30%',
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      this.deleteRowData(result.data);
    });
  }

  // edit Quotation Item
  openDialog_edit(element) {
    // console.log(element)
    const dialogRef = this.dialog.open(EditQuotationComponent, {
      height: 'auto',
      width: 'auto',
      data: element
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updateRowData(result.data);
    });
  }

  //join symptomps function
  joinsymptomps(arrayValue) {
    var StringValue = arrayValue
    return Array.prototype.map.call(StringValue, function (item) {
      return item;
    }).join(", ");
  }

  // Tax count Function
  taxCount(tax, amount) {
    // console.log(tax)
    // console.log(amount)
    for (let index = 0; index < tax.length; index++) {
      const element = tax[index];
      if (element === '9.00% CGST') {
        var taxamount = 9
        // console.log((amount * taxamount) / 100)
        this.ninepercentCGST = (amount * taxamount) / 100
        this.taxAmountCGST.push(this.ninepercentCGST)
      }
      if (element === '9.00% SGST') {
        var taxamount = 9
        // console.log((amount * taxamount) / 100)
        this.ninepercentSGST = (amount * taxamount) / 100
        this.taxAmountSGST.push(this.ninepercentSGST)
      }
      if (element === '18.00% GST') {
        var taxamount = 18
        // console.log((amount * taxamount) / 100)
        this.eighteenpercentGST = (amount * taxamount) / 100
        this.taxAmounteighteenGST.push(this.eighteenpercentGST)
      }
      if (element === '18.00% IGST') {
        var taxamount = 18
        // console.log((amount * taxamount) / 100)
        this.eighteenpercentIGST = (amount * taxamount) / 100
        this.taxAmounteighteenIGST.push(this.eighteenpercentIGST)
      }
    }
    // console.log(this.taxAmountCGST.reduce((a, b) => a + b, 0));
    // console.log(this.taxAmountSGST.reduce((a, b) => a + b, 0));
    // console.log(this.taxAmounteighteenIGST.reduce((a, b) => a + b, 0));
    // console.log(this.taxAmounteighteenGST.reduce((a, b) => a + b, 0));
    this.totalTaxAmountCGST = this.taxAmountCGST.reduce((a, b) => a + b, 0);
    this.totalTaxAmountSGST = this.taxAmountSGST.reduce((a, b) => a + b, 0);
    this.totalTaxAmounteighteenIGST = this.taxAmounteighteenIGST.reduce((a, b) => a + b, 0);
    this.totalTaxAmounteighteenGST = this.taxAmounteighteenGST.reduce((a, b) => a + b, 0);
    this.allTaxTotal = this.totalTaxAmountCGST + this.totalTaxAmountSGST + this.totalTaxAmounteighteenIGST + this.totalTaxAmounteighteenGST
  }

  addQuotationData() {
    var id = new Date();
    this.dataSourcenew.push({
      id: id.getTime(),
      Items: this.itemData,
      Description: this.aqdf.longDescription.value,
      Qty: this.aqdf.unit.value,
      Rate: this.aqdf.rate.value,
      Tax: this.joinsymptomps(this.aqdf.tax.value),
      amount: this.aqdf.rate.value * this.aqdf.unit.value,
      taxCount: this.taxCount(this.aqdf.tax.value, this.aqdf.rate.value * this.aqdf.unit.value)
    });
    this.AddQuotationDataForm.reset()
    console.log(this.taxCount, "data source")
    this.table.renderRows();
    this.totalAmount(this.dataSourcenew)
  }

  updateRowData(row_obj) {
    console.log(row_obj)
    this.dataSourcenew = this.dataSourcenew.filter((value, key) => {
      if (value.id == row_obj.id) {
        value.Items = row_obj.Items;
        value.Description = row_obj.Description;
        value.Qty = row_obj.Qty;
        value.Rate = row_obj.Rate;
        value.Tax = row_obj.Tax;
        value.amount = row_obj.amount;
        value.taxCount = this.taxCount(row_obj.tax.split(','), row_obj.amount)
      }
      return true;
    });
    this.totalAmount(this.dataSourcenew)
  }

  // Dropdown menus get option
  Assigned(event) {
    this.assignedChangeName = event.source.triggerValue;
  }
  Related(event) {
    this.relatedChangeName = event.source.triggerValue;
  }
  discountType(event) {
    this.discountChange = event.source.triggerValue;
  }
  statusValue(event) {
    this.statusValueChange = event.source.triggerValue;
  }
  deleteRowData(row_obj) {
    console.log(row_obj)
    this.dataSourcenew = this.dataSourcenew.filter((value, key) => {
      return value.id != row_obj.id;
    });
    this.totalAmount(this.dataSourcenew)
  }

  // Add quotation APi
  save() {
    this.savdata = {
      subject: this.aqf.subject.value,
      date: this.aqf.date.value,
      opentill: this.aqf.openTill.value,
      comment: this.aqf.comment.value,
      to: this.aqf.to.value,
      address: this.aqf.address.value,
      city: this.aqf.city.value,
      state: this.aqf.state.value,
      country: this.aqf.country.value,
      zipCode: this.aqf.zipCode.value,
      email: this.aqf.email.value,
      phone: this.aqf.phone.value,
      total: this.total,
      arrayofQuotation: this.dataSourcenew,
    }
    this.addQuotationService.AddQuotation(
      this.aqf.email.value,
      this.aqf.phone.value,
      this.aqf.subject.value,
      this.relatedChangeName, this.aqf.date.value, this.aqf.openTill.value, this.discountChange,
      this.joinsymptomps(this.tags), this.statusValueChange, this.assignedChangeName,
      this.aqf.to.value,
      this.aqf.address.value, this.aqf.city.value, this.aqf.state.value,
      this.aqf.country.value, this.aqf.zipCode.value, this.total, this.dataSourcenew, this.aqdf.discount.value, this.aqdf.adjustment.value)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigateByUrl('/admin/quotation')
          this.matSnack.showNotification("Quotation Add", "")
          // console.log(data)
        },
        error => {
          console.log(error.error)
        });
  }

  // Total Amount Function
  totalAmount(data) {
    this.subtotal = data.map(function (a) { return a["amount"]; }).reduce((partial_sum, a) => partial_sum + a, 0)
    if (this.adjustmentAmount === undefined && this.discountAmount === undefined) {
      this.total = this.subtotal
    } else if (this.adjustmentAmount === undefined && this.discountAmount != undefined) {
      this.total = this.total
      this.discountCount(this.discount, this.subtotal)
      // this.total = this.discountAmount + this.subtotal

    } else if (this.adjustmentAmount != undefined && this.discountAmount === undefined) {
      this.total = this.total
      // this.total = this.adjustmentAmount + this.subtotal
      this.adjustmentCount(this.adjustment)
    } else {
      this.discountCount(this.discount, this.subtotal)
      this.adjustmentCount(this.adjustment)
      this.total = this.subtotal + this.discountAmount + this.adjustmentAmount
    }
  }

  applyFilter(event) {
    // console.log((event.target as HTMLInputElement).value)
    this.discount = (event.target as HTMLInputElement).value;
    this.discountCount(this.discount, this.subtotal)
  }

  // Discount Function
  discountCount(discount, subtotal) {
    console.log(subtotal)
    this.discountAmount = -(Number(discount) * this.subtotal) / 100
    // console.log(this.adjustmentAmount)
    if (this.adjustmentAmount != undefined) {
      this.total = Number(this.adjustmentAmount) + this.discountAmount + this.subtotal
      // this.total = this.discountAmount + subtotal
      // console.log(this.total)
    } else if (this.adjustmentAmount === undefined) {
      this.total = this.discountAmount + this.subtotal
      // console.log(this.total)
    }
  }

  applyFilter1(event) {
    // console.log((event.target as HTMLInputElement).value)
    this.adjustment = (event.target as HTMLInputElement).value;
    this.adjustmentCount(this.adjustment)
    // this.total = Number(adjustment) + this.discountAmount + subtotal
  }

  // Adjustment COunt
  adjustmentCount(adjustment) {
    this.adjustmentAmount = Number(adjustment)
    if (this.discountAmount != undefined) {
      this.total = Number(this.adjustmentAmount) + this.discountAmount + this.subtotal
      // this.total = this.discountAmount + subtotal
      // console.log(this.total)
    } else if (this.discountAmount === undefined) {
      this.total = this.adjustmentAmount + this.subtotal
      // console.log(this.total)
    }
  }

  /*
Get products list api called
*/
  getProducts() {
    this.ProductService.getProducts()
      .subscribe((data: Products[]) => {
        this.products = data[0];
        this.filteredListEmployee = data
        this.filteredList1 = this.filteredListEmployee.slice()
      });
  }

  //Assign item from data
  AssignedItem(event) {
    console.log("event", event.value)
    this.ItemsData = event.value
    this.itemData = this.ItemsData.item
    this.rateData = this.ItemsData.rate
    this.taxData = this.ItemsData.tax.split(',');
  }
}

export interface quotation {
  items: string;
  longDescription: string;
  unit: string;
  rate: string;
  tax: string;
}