import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { CONSTANT } from 'src/app/shared/utils/constant';
import { MailstatusService } from 'src/app/shared/services/adminpanel/mailstatus.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { first } from 'rxjs/operators';

export class mailstatus {
  name: string;
  id: number;

  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}

@Component({
  selector: 'app-emailstatus',
  templateUrl: './emailstatus.component.html',
  styleUrls: ['./emailstatus.component.scss']
})
export class EmailstatusComponent implements OnInit {
  [x: string]: any;
  public variables = ['Parth Shah', 'Tushar Panchal', 'Yash Shah', 'Jay Patel', 'Dhruv Boghani', 'pre'];
  // public filteredList1 = this.variables.slice();
  public variables2 = CONSTANT.status;
  // public filteredList2 = this.variables2.slice();
  toppings = new FormControl(this.variables2);
  isLoading = true;
  totalRows = 0;
  pageSize = 7;
  currentPage = 0;
  pageIndex = 0;
  searchText: string = "";
  filteredList1
  statusdata
  // = this.variables2.slice();
  public filteredList2
  pageSizeOptions: number[] = [5, 10, 25, 100];
  displayedColumns: string[] = ['position', 'name', 'email', 'send'];
  dataSource = new MatTableDataSource<MailstatusService>([]);
  selection = new SelectionModel<MailstatusService>(true, []);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private MailstatusService: MailstatusService,
    private matSnack: SnackbarService,
    private changeDetectorRefs: ChangeDetectorRef

  ) { }

  ngOnInit(): void {
    this.EmailStatus();
    localStorage.removeItem('edit-listofdraftlog');
    localStorage.removeItem('add-listofdraftlog');
    localStorage.removeItem('view-listofdraftlog');
    localStorage.removeItem('listofdraftlogData')
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.changeDetectorRefs.detectChanges();
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    console.log(this.paginator)
  }
  handleMouseOver(row) {
    const position = row;
    this.dataSource.data.map((data: any) => {
      if (data === position) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const position = row;
    this.dataSource.data.map((data: any) => {
      if (data === position) {
        data.show = false;
      }
    });
  }

  // Email status
  EmailStatus() {
    this.MailstatusService.getEmaildatabyid<any>(`api/EmailDraft/ListOfDraftLog`)
      .subscribe((data: any) => {
        this.mailstatus = data.data
        console.log(this.mailstatus)
        this.dataSource.data = data.data.emailDraftLogs
        // console.log(data.result.leadList)
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = data.data.count;
      });
  }
  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    // this.applyFilter($event);
  }

  /** Whether the number of selected elements matches the total number of rows. */

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.mailstatus.forEach(row =>
        this.selection.select(row)
      );
  }

  // logSelection() {
  //   this.selection.selected.forEach(s => console.log(s.getMailStatusdatabyid));
  // }

  someMethod(event) {
    console.log(event)
    const filterValue = event;
    this.dataSource.filter = filterValue.trim().toLowerCase();

  }

  someMethod1(event) {
    console.log(event)
    const filterValue = event;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /*
    Get email status list api called
  */

  getListOfDraftLog() {
    this.isLoading = true;
    this.MailstatusService.getListOfDraftLog()
      .subscribe((data: any) => {
        this.isLoading = false;
        this.mailstatus = data;
        this.dataSource = data.data
        console.log(data)
      });
  }

  resendMail(data){
    console.log(data)
    var formData = new FormData();
    formData.append("From", 'no-reply@smokycrm.com');
    formData.append("To", data.to);
    formData.append("subject", data.subject);
    formData.append("message", data.body);
    formData.append("cc", data.cc);
    formData.append("bcc", data.bcc);
    formData.append("Attachment", data.attachment);
    formData.append("type", data.type);
    formData.append("DraftId", data.draftId);
    this.MailstatusService.sendEmail(`api/EmailDraft/SendEmail`, formData)
    .pipe(first())
    .subscribe(
        data => {
          console.log(formData)
            console.log(data)
            this.EmailStatus()
        },
        error => {
        }
    );
  }
}




