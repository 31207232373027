<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="container backgroud-color-primary">

    <mat-card class="cardsize" fxLayout="column" fxLayoutAlign="center">
        <mat-card-title class="padding-top">
            <span fxLayoutAlign="center" class="color-primary font-weight-500 font-uppercase">Change Password</span>
        </mat-card-title>

        <mat-card-content class="cardpadding margin-top">
            <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <p class="mat-h4">Email id is <span class="color-primary"> {{this.emailNew }}</span>
                        
                    </p>
                </div>
                <form [formGroup]="changeFormGroup">
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div>
                   
                            <!-- New password -->
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>New Password</mat-label>
                                <input matInput formControlName="password" [type]="hide ? 'password' : 'text'"
                                    placeholder="New Password" autocomplete="disabled" required>
                                <mat-icon class="color-secondary" matSuffix (click)="hide = !hide">
                                    {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                <mat-error *ngIf="password.errors?.required"> {{this.passwordrequired}}
                                </mat-error>
                                <mat-error *ngIf="password.errors?.pattern">{{this.passwordinvalid}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div>
                            <!-- Confirm password -->
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Confirm Password</mat-label>
                                <input matInput formControlName="c_password" [type]="hidecon ? 'password' : 'text'"
                                    placeholder="Confirm Password" autocomplete="disabled" required>
                                <mat-icon class="color-secondary" matSuffix (click)="hidecon = !hidecon">
                                    {{hidecon ? 'visibility_off' : 'visibility'}}</mat-icon>
                                <mat-error *ngIf="c_password.errors?.required"> {{this.cpasswordrequired}}
                                </mat-error>
                                <mat-error *ngIf="c_password.errors?.pattern">{{this.passwordinvalid}}
                                </mat-error>
                                <mat-error *ngIf="changeFormGroup.get('c_password').hasError('mismatch')">
                                    {{this.cpasswordinvalid}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>

                <!-- buttons -->
                <div fxLayout="row" fxLayoutAlign="start stretch" [style.margin-top]="'29px'">
                    <button mat-raised-button color="primary" class="full-width" (click)="changePassword()"
                        [disabled]="changeFormGroup.invalid">Change Password</button>
                </div>

            </div>
        </mat-card-content>
    </mat-card>
</div>