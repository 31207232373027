<!-- <div fxLayout="column" id="top">
    <div class="color-primary backgroud-color-white cardpadding ">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <p class="mat-title" >Campaign Account verification</p>
 
      </div>
    </div>
    <div>
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            Campaign
          </ng-template>
          <form  [formGroup]="verifyFormGroup" (keyup.enter)="CampaignFallingAccountVerification()">
            <div class="cardpadding" fxLayout="column">
              <div fxLayoutAlign="start">
  
                <div fxLayout="column" fxLayout.lt-sm="column" fxLayoutAlign="start" fxLayoutAlign.lt-sm="start" fxFlex>
                  
                  <div>
                    <mat-form-field   appearance="outline" class="full-width form-field-font">
                      <mat-label>account ID</mat-label>
                    
                      <input matInput formControlName="accountID" placeholder="Enter valid accountID" >
                    </mat-form-field>
                  </div>
                  
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <p class="color-danger">{{this.error}}</p>
            </div>
               <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button mat-raised-button type ='reset'>Reset</button>
                <button mat-raised-button color="primary" (click)="CampaignFallingAccountVerification()"   [disabled]="verifyFormGroup.invalid">Verify</button>
              </div>
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div> -->
<div fxLayout="column" id="top" class="cardpadding">
  <mat-card fxLayout="column" fxLayoutGap="10px">
    <h2 align="center" class="color-primary"> Facebook leads </h2>
    <div>
      <mat-divider>
      </mat-divider>
    </div>

    <mat-horizontal-stepper [linear]="isLinear" #stepper labelPosition="bottom">
      <mat-step [stepControl]="formCampaignGroup" label="Campaign Verification">
        <div style="padding-top: 3%;">
          <form [formGroup]="formCampaignGroup">

            <div class="example-form">
              <mat-form-field appearance="outline" class="example-full-width">
                <input matInput placeholder="accountID" required formControlName="accountID">
                <mat-error *ngIf="formCampaignGroup.controls['accountID'].hasError('required')">
                  accountID is required!
                </mat-error>
              </mat-form-field>

            </div>
            <br />

            <button mat-raised-button color="primary" [disabled]="formCampaignGroup.invalid"
              (click)="CampaignFallingAccountVerification()">Next</button>

          </form>
        </div>
      </mat-step>

      <mat-step [stepControl]="formAddsGroup" label="Advertisement Verification">
        <div style="padding-top: 3%;">
          <form [formGroup]="formAddsGroup">
            <!-- <ng-template matStepLabel>Name</ng-template> -->
            <div class="example-form">
              <!-- <mat-form-field  appearance="outline" class="example-full-width">
                <input type="text" matInput placeholder="addID" required formControlName="addID">
                <mat-error *ngIf="formAddsGroup.controls['addID'].hasError('required')">
                  addID is required!
                    </mat-error>
              </mat-form-field> -->
              <mat-form-field appearance="outline" class="example-full-width">
                <!--   -->
                <mat-select placeholder="Select Campaign" name="Select Campaign" formControlName="campaignId">

                  <mat-option *ngFor="let education of educationList" [value]="education.id">{{ education.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <br />
            <div fxLayoutGap="5px">
              <button mat-raised-button color="primary" [disabled]="formAddsGroup.invalid"
                (click)="getAdsFallingCampaignVerification()">Next</button>
              <button mat-raised-button color="primary" (click)="goBack()">Back</button>
              <button mat-raised-button color="warn" (click)="stepper.reset()">Reset</button>
            </div>
          </form>
        </div>
      </mat-step>

      <mat-step [stepControl]="FormGroup" label="Form Verification">
        <div style="padding-top: 3%;">
          <form [formGroup]="FormGroup">
            <!-- <ng-template matStepLabel>Name</ng-template> -->
            <div class="example-form">
              <mat-form-field appearance="outline" class="example-full-width">
                <input type="text" matInput placeholder="formId" required formControlName="formId">
                <mat-error *ngIf="FormGroup.controls['formId'].hasError('required')">
                  formId is required!
                </mat-error>

              </mat-form-field>
              <mat-form-field appearance="outline" class="example-full-width">
                <!--   -->


                <mat-select placeholder="Select Advertisement" name="Select Advertisement" formControlName="addId">

                  <mat-option *ngFor="let adv of advList" [value]="adv.id">{{ adv.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <br />
            <div fxLayoutGap="5px">
              <button mat-raised-button color="primary" [disabled]="FormGroup.invalid"
                (click)="getFormInAdsVerification()">Next</button>
              <button mat-raised-button color="primary" (click)="goBack()">Back</button>
              <button mat-raised-button color="warn" (click)="stepper.reset()">Reset</button>
            </div>
          </form>
        </div>
      </mat-step>

      <mat-step [stepControl]="formFacebookGroup" label="Facebook Campaign">
        <div style="padding-top: 3%;">
          <form [formGroup]="formFacebookGroup">
            <!-- <ng-template matStepLabel>Name</ng-template> -->
            <div class="example-form">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>AccountId</mat-label>
                <input type="tel" matInput placeholder="accountID" required formControlName="accountID"
                  [(ngModel)]="setaccountID" readonly>
                <mat-error *ngIf="formFacebookGroup.controls['accountID'].hasError('required')">
                  accountID is required!
                </mat-error>

              </mat-form-field>
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>CampaignId</mat-label>
                <input type="tel" matInput placeholder="campaignId" required formControlName="campaignId"
                  [(ngModel)]="setcampaignId" readonly>
                <mat-error *ngIf="formFacebookGroup.controls['campaignId'].hasError('required')">
                  campaignId is required!
                </mat-error>

              </mat-form-field>
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>AdId</mat-label>
                <input type="tel" matInput placeholder="adId" required formControlName="adId" [(ngModel)]="setadId"
                  readonly>
                <mat-error *ngIf="formFacebookGroup.controls['adId'].hasError('required')">
                  adId is required!
                </mat-error>

              </mat-form-field>
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>FormId</mat-label>
                <input type="tel" matInput placeholder="formId" required formControlName="formId"
                  [(ngModel)]="setformId">
                <mat-error *ngIf="formFacebookGroup.controls['formId'].hasError('required')">
                  formId is required!
                </mat-error>

              </mat-form-field>
            </div>
            <br />
            <div fxLayoutGap="5px">
              <button mat-raised-button color="primary" [disabled]="formFacebookGroup.invalid"
                (click)="getFaceBookCampaignData()">Next</button>
              <button mat-raised-button color="primary" (click)="goBack()">Back</button>
              <button mat-raised-button color="warn" (click)="stepper.reset()">Reset</button>
            </div>
          </form>
        </div>
      </mat-step>
      <!-- <mat-step>
    <ng-template matStepLabel>Review</ng-template>
    <h5>You are now done.</h5>
    <div fxLayoutGap="5px"> 
      
        <button mat-raised-button color="accent" type="submit" (click)="gotoleads()">Go to leads</button> &nbsp; &nbsp;
        <button mat-raised-button color="primary"  (click)="goBack()">Back</button>
        <button mat-raised-button color="warn" (click)="stepper.reset()">Reset</button>
    </div>
  </mat-step> -->
      <!-- <ng-template matStepperIcon="phone">
    <mat-icon>phone</mat-icon>
</ng-template> -->

    </mat-horizontal-stepper>
  </mat-card>
</div>