<div fxLayout="column" class="full-padding padding-top" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex >
        <!-- <mat-card  style="width:100%"> -->
        <mat-card style="width:100%" fxLayout="column" fxLayoutAlign=" center" fxFlex="32">

            <div fxLayout="row">
                <!-- <mat-icon class="blue-color">currency_rupee</mat-icon> -->
                <p fxLayoutAlign=" center" class="blue-color mat-h2">Dashboard</p>
            </div>
        
        </mat-card>
        </div>
        </div>